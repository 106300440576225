import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { Provider } from 'react-redux';
import App from './App';
import * as serviceWorker from './serviceWorker';
import Amplify from "aws-amplify";
import { BrowserRouter as Router } from "react-router-dom";
import { config } from "./config";
// import { configureStore } from './store';
import { persistor, store } from './store';
import Storage from '@aws-amplify/storage';
import { PersistGate } from 'redux-persist/lib/integration/react';


if(!config.isOfflineMCU){
    Amplify.configure({
        Auth: {
            mandatorySignIn: true,
            region: config.cognito.REGION,
            userPoolId: config.cognito.USER_POOL_ID,
            identityPoolId: config.cognito.IDENTITY_POOL_ID,
            userPoolWebClientId: config.cognito.APP_CLIENT_ID
        },
        Storage: {
            region: config.s3.REGION,
            bucket: config.s3.PROFILEBUCKET,
            identityPoolId: config.cognito.IDENTITY_POOL_ID
        },
        API: {
            endpoints: [
                {
                    name: "insta",
                    endpoint: config.apiGateway.URL,
                    region: config.apiGateway.REGION
                },
                {
                    name: "domain",
                    endpoint: config.apiDomainGateway.URL,
                    region: config.apiDomainGateway.REGION
                },
                {
                    name: "mcu",
                    endpoint: config.apiMcuGateway.URL,
                    region: config.apiMcuGateway.REGION
                },
                {
                    name: "user",
                    endpoint: config.invcUserGateway.URL,
                    region: config.invcUserGateway.REGION
                },
                {
                    name: "plan",
                    endpoint: config.apiPlanGateway.URL,
                    region: config.apiPlanGateway.REGION
                },
                {
                    name: 'coupon',
                    endpoint: config.apiCouponGateway.URL,
                    region: config.apiCouponGateway.REGION
                },
                {
                    name:'transaction',
                    endpoint: config.apiTransactionGateway.URL,
                    region: config.apiTransactionGateway.REGION
                },
                {
                    name: 'referral',
                    endpoint: config.apiReferralGateway.URL,
                    region: config.apiReferralGateway.REGION
                },
                {
                    name: 'recording',
                    endpoint: config.apiRecordingGateway.URL,
                    region: config.apiRecordingGateway.REGION
                },
                {
                    name: 'mcu_recording',
                    endpoint: config.apimcuRecordingGateway.URL,
                    region: config.apimcuRecordingGateway.REGION
                },
                {
                    name: 'content',
                    endpoint: config.apiContentGateway.URL,
                    region: config.apiContentGateway.REGION
                },
                {
                    name: 'schedule',
                    endpoint: config.apiScheduleGateway.URL,
                    region: config.apiScheduleGateway.REGION
                },
                {
                    name: 'address',
                    endpoint: config.apiAddressGateway.URL,
                    region: config.apiAddressGateway.REGION
                },
                {
                    name: 'multipart_upload',
                    endpoint: config.apiMultipartUploadGateway.URL,
                    region: config.apiMultipartUploadGateway.REGION
                },
                {
                    name: 'meetings',
                    endpoint: config.apiMeetingGateway.URL,
                    region: config.apiMeetingGateway.REGION
                },
                {
                   name: "mail",
                    endpoint: config.apiMailGateway.URL,
                    region: config.apiMailGateway.REGION
                },
                {
                    name: "broadcast",
                    endpoint: config.apiBroadcastGateway.URL,
                    region: config.apiBroadcastGateway.REGION
                },
                {
                    name: "test",
                    endpoint: config.apiTestGateway.URL,
                    region: config.apiTestGateway.REGION
                },
                {
                    name: "doc_share",
                    endpoint: config.apiDocumentShareGateway.URL,
                    region: config.apiDocumentShareGateway.REGION
                    
                },
                {
                    name: "chat",
                    endpoint: config.apiChatGateway.URL,
                    region: config.apiChatGateway.REGION
                },
                {
                    name: "admin",
                    endpoint: config.apiAdminGateway.URL,
                    region: config.apiAdminGateway.REGION
                },
                {
                    name: "inVCSchedule",
                    endpoint: config.invcScheduleAPI.URL,
                    region: config.invcScheduleAPI.REGION
                },
                {
                    name: "inVCEmail",
                    endpoint: config.invcEmailAPI.URL,
                    region: config.invcEmailAPI.REGION
                },
                {
                    name: "inVCLicense",
                    endpoint: config.invcLicenseAPI.URL,
                    region: config.invcLicenseAPI.REGION
                },
                {
                    name : "inVCChat",
                    endpoint: config.invcChatAPI.URL,
                    region: config.invcChatAPI.REGION
                },
                {
                    name : "invcManageUser",
                    endpoint: config.invcManageUser2021API.URL,
                    region: config.invcManageUser2021API.REGION
                },
                {
                    name: "inaffiliateUser",
                    endpoint: config.inaffiliateUserAPI.URL,
                    region: config.inaffiliateUserAPI.REGION
                },
                //ipindia
                {
                    name: "ipindia",
                    endpoint: config.ipIndiaGateway.URL,
                    region: config.ipIndiaGateway.REGION
                }
            ]
        }
    });
}

export function SetS3Config(bucket, level){
    Storage.configure({ 
           bucket: bucket,
           level: level,
           region: 'ap-southeast-1',
           identityPoolId: config.cognito.IDENTITY_POOL_ID 
        });
 }


// Initialize store
// const store = configureStore(window.__INITIAL_STATE__);
global.store = store;

ReactDOM.render(
    <Provider store={store}>
        <PersistGate persistor={persistor}>
            <Router>
                <App store={store}/>
            </Router>
        </PersistGate>
    </Provider>,
    document.getElementById("root")
  );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
