import { API, Storage} from "aws-amplify";
import {OffLineAPI} from '../../offline-auth/api';
import {config} from '../../config';

// API.configure({Auth:config.cognito, Storage:Storage.configure({
//     AWSS3: {
//         bucket: 'instavcprofileimages',
//         region: 'ap-southeast-1'
//     }
// })
// });

let _API = config.isOffline ? OffLineAPI : API;

// Export Constants
export const SET_PROFILE = 'SET_PROFILE';
export const SET_PROFILE_IMG = 'SET_PROFILE_IMG';


// Export Actions
export function getProfile() {
    return dispatch => {
        return _API.get("invcManageUser", "/profiledata")
            .then(res => {
                 console.log("res === ", res);
                return dispatch(setProfile(res.data));
            })
            .catch(err => {
                console.log("err in getProfile ", err );
                return dispatch(setProfile({}));
            });
    }
}

// Export Actions
export function saveProfile(body) {
     console.log("body ==== ", body);
    // return dispatch => {
        return _API.post("invcManageUser", "/profile", {body})
            .then(res => {
                 console.log("res === ", res);
                 getProfile();
                return res;
                // return dispatch(setProfile(res.data));
            })
            .catch(err => {
                console.log("err in getProfile ", err);
                return err;
                // return dispatch(setProfile({}));
            });
    // }
}

// Export Actions
export function setProfile(data) {
    return {
      type: SET_PROFILE,
      data
    };
}

// export function updateProfilePic(file, fileName) {
//     console.log("action file=====>",file);

//     return dispatch => {
//         const formData = new FormData();
//         formData.append('profileImage', file);
//         // formData.set('filename', fileName);
//         console.log("formdata======>", formData);
        
//        let headers ={
//         'Content-Type': 'multipart/form-data'
//        }

//         return _API.post("user", "/updateprofileimage", {headers:headers}, {body: formData})
//             .then(res => {
//                  console.log("res === ", res);
//                 // return res;
//                 return dispatch(setProfileImg(res.data.profileImage));
//             })
//             .catch(err => {
//                 console.log("err in upload profile ", err);
//                 // return err;
//                 return dispatch(setProfileImg(''));
//             });
//         }
// }

export function updateProfilePic(file, fileName) {
    console.log("action file=====>",file);
    console.log("fileName", fileName);
    let filename = Date.now()+ '-' + fileName;
        let basefile = file.toString('base64');
        console.log("basefile", basefile)
    var newBuffer = ''
    newBuffer = file.substring(basefile.indexOf('form-data') + 23, basefile.length)
    newBuffer = newBuffer.substring(newBuffer.indexOf('data'), newBuffer.length)
 // newBuffer = newBuffer.substring(0, newBuffer.indexOf('WebKitFormBoundary') - 7);
    let newimage = new Buffer(newBuffer.replace(/^data:image\/\w+;base64,/, ""), 'base64')
    console.log("newimage======", newimage)
    // Storage.config.s3.update({bucket: 'instavc-uploads'})

   return dispatch => {
    Storage.configure({bucket: 'instavcprofileimages', region: 'ap-south-1'})
    console.log("storage ==========", Storage);
       return Storage.put(filename,newimage, {
        contentType: 'image/*'
     })
       .then (result => {
           console.log("result in storage of profilpic===== ", result);
        //    Storage.get(result.key)
        //    .then(result => {
        //        console.log("result", result)

        //     })
        //     .catch(err => {
        //         console.log("err", err)
        //     });
        let body ={
            key : result.key
        }
        console.log("body", body);
       return _API.post("invcManageUser", "/updateprofileimage", {body}).then(res =>{
           console.log("res==============>", res);

           return dispatch(setProfileImg(res.data));
       }).catch(err =>{
           console.log("err", err);
       })
            
       })
       .catch(err => {
        //    console.log(err)
           return dispatch(setProfileImg(''));

       });
   }

    
}



export function setProfileImg(data) {
    return {
        type: SET_PROFILE_IMG,
        data
      };
}