import React, { Component, Suspense } from 'react';
import { Route } from 'react-router-dom';
import Loading from '../../components/common/Loading';
import '../../App.css';
import './mainlayout.css';
/*import '../AppRtl.css';*/
import Header from '../../components/common/header/header';
// import AddShoppingCart from '@material-ui/icons/AddShoppingCart';
import Draggable from 'react-draggable';
// import Fab from '@material-ui/core/Fab';
//import SessionTimeout from '../../components/common/SessionTimeout';
// import P2P from '../conference/libs/P2P';

class MainLayout extends Component {

	constructor(props) {
		super(props);
		this.state = {
			// setSession: false,
			onP2PCall: false
		}
		// this.p2p = new P2P();
		// this.remoteId = null;
	}
	componentDidMount = () => {
		// console.log('!this.props.excludeHeaderDomainName', this.props)
		// this.props.instaSignaler.on('closed-ws', (event) => {
		// 	// if(!this.props.VCCallStatus()){
		// 		// this.setState({ setSession: true });
		// 	// }
		// });

		// this.props.instaSignaler.on('oncall-ended', (event) => {
		// 	this.props.instaSignaler.setBusy(false);
		// 	let remote = document.getElementById('remote');
		// 	remote.style.transform = "scale(0, 0)";
			
		// 	this.p2p.exitCall();
		// });

		// this.props.instaSignaler.on('call-accepted', (event) => {
		// 	// console.log('Call Accepted', event);
		// 	this.remoteId = event.remoteId;
		// 	// if(event.publishVideo) {
		// 		this.setState({onP2PCall: true}, () => {
		// 			this.p2p.connectP2PServer(event.selfId, event.remoteId, event.publishVideo, (event) => {
		// 				if(event.disconnect) {
		// 					this.setState({onP2PCall: false});
		// 				}
		// 			});	
		// 		})
		// 	// }else {

		// 	// }
		// });
	}
	componentWillUnmount = () => {
		// this.props.instaSignaler.off('closed-ws', (event) => {});
		// this.props.instaSignaler.off('call-accepted', (event) => {});
	}

	leaveP2PCall = () => {
		// this.p2p.exitCall();
		// this.props.instaSignaler.sendEndCallRequest(this.remoteId);
	}
	// handleClose = () => {
    //     this.setState({ setSession: false});
	// }
	
	gotoBuy =() => {
		this.props.history.push('/buy-room');
	}
	render(){
		return(
			<div className="mainLayout">
				{!this.props.excludeHeaderDomainName && this.props.headerOff !== true && <Header {...this.props} />}
				<Draggable>
					<div  className={`floatingP2P ${(!this.state.onP2PCall) ? 'hideout': ''}`}>
						<video id="remote" playsInline />
						<video id="self" muted playsInline />
						<div className="circle circleMd endBg confBtns p2pEnd" onClick={this.leaveP2PCall}>
                            <img src="/images/icons/hang-white.png" alt="icon" />
						</div>
					</div>
				</Draggable>
				{/* <SessionTimeout instaSignaler={this.props.instaSignaler} uid={this.props.userPayload.sub} isOpen={this.state.setSession} closeCallback={this.handleClose} /> */}
				<div className={`mainContainer ${(this.props.excludeHeaderDomainName || this.props.headerOff) ? 'removeTop' : ''}`}>
                <Suspense fallback={<Loading />}>
					<Route exact path={this.props.path} component={this.props.component} />
                </Suspense>
				</div>
				{/* <div className="floatingJoinBtn">
					<Fab variant="extended" size="small" color="secondary" className="buyNowBtnResponsive" onClick={this.gotoBuy}>
						<AddShoppingCart className="leftIcon" />
						Buy Now
					</Fab>
				</div> */}
			</div>
		)
	}
}

export default MainLayout;