const breakoutRoom = (state = {}, action) =>
{
	switch (action.type)
	{
		case 'ADD_BREAKOUT_ROOM':
			return { id: action.payload.breakoutId };

		case 'SET_BREAKOUT_ROOM_DISPLAY_NAME':
			return { ...state, displayName: action.payload.displayName };
		case 'SET_BREAKOUT_ROOM_PICTURE':
			return { ...state, picture: action.payload.picture };
		case 'SET_BREAKOUT_ROOM_PROMOTION_IN_PROGRESS':
			return { ...state, promotionInProgress: action.payload.flag };

		default:
			return state;
	}
};

const breakoutRooms = (state = {}, action) =>
{
	switch (action.type)
	{
		case 'ADD_BREAKOUT_ROOM':
		{
			return { ...state, [action.payload.breakoutId]: breakoutRoom(undefined, action) };
		}

		case 'REMOVE_BREAKOUT_ROOM':
		{
			const { breakoutId } = action.payload;
			const newState = { ...state };

			delete newState[breakoutId];

			return newState;
		}

		case 'SET_BREAKOUT_ROOM_DISPLAY_NAME':
		case 'SET_BREAKOUT_ROOM_PICTURE':
		case 'SET_BREAKOUT_ROOM_PROMOTION_IN_PROGRESS':
		{
			const oldBreakoutRoom = state[action.payload.breakoutId];

			if (!oldBreakoutRoom)
			{
				// Tried to update non-existent lobbyPeer. Has probably been promoted, or left.
				return state;
			}

			return { ...state, [oldBreakoutRoom.id]: breakoutRoom(oldBreakoutRoom, action) };
		}

		default:
			return state;
	}
};

export default breakoutRooms;
