import React from 'react';
import { Dialog, DialogContent, DialogTitle, DialogActions, makeStyles, Typography } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Controls from "./controls";

const useStyles = makeStyles(theme => ({
    dialogWrapper: {
        padding: theme.spacing(0),
        margin: theme.spacing(1)
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    actionBlock: {
        display: 'flex',
        flex: "none",
        width: '100%',
        alignItems: 'center',
        justifyContent: 'flex-end'
    }
}))

function PopUp(props) {
    const { children, openPopup, maxWidth, setOpenPopup, title, onCancel, onSave, cancelText, saveText } = props;
    const classes = useStyles()
    return (
        <Dialog open={openPopup} maxWidth={maxWidth} classes={{ paper: classes.dialogWrapper }} >
            <DialogTitle>
                <div style={{ display: 'flex' }}>
                    <Typography variant="h6" component="div" style={{ flexGrow: 1, fontSize: '1.125rem', fontWeight: '400' }}>{title}</Typography>

                    <IconButton aria-label="close" className={classes.closeButton} onClick={() => setOpenPopup(false)}>
                        <CloseIcon />
                    </IconButton>

                </div>
            </DialogTitle>
            <DialogContent dividers>
                {children}
            </DialogContent>
            <DialogActions>
                <div className={classes.actionBlock}>
                    <Controls.Button text={cancelText} variant="outlined" color="primary"  size="medium" onClick={onCancel} />
                    <Controls.Button text={saveText} size="medium" onClick={onSave} />
                </div>
            </DialogActions>
        </Dialog>
    )
}

export default PopUp;

// maxWidth = lg, md, sm, xl, xs, false