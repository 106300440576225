export const esData = {
    "locale": {
        "home_instavc_build": "Construir relaciones significativas entre las organizaciones y los empleados",
        "home_instavc_conference_platform": "Las plataformas de la reuni\u00f3n con un\nUna experiencia de usuario sin igual",
        "home_instavc_start_conference": "INICIAR REUNI\u00d3N | UNIRSE A LA REUNI\u00d3N | REUNI\u00d3N INSTANT\u00c1NEA",
        "home_instavc_Hippa": "HIPAA ELEGIBLE Y PCI DSS, SOC, ISO\/EIC 27001, ISO\/EIC 27017, ISO\/EIC 27018, Y ISO 9001.",
        "home_instavc_copyrights": "© 2018 · instaVC · PeopleLink Unified Communications Pvt. Ltd.",
        "home_instavc_terms_agree": "Estoy de acuerdo con la",
        "home_instavc_terms": "T\u00e9rminos y condiciones",
        "instavc_login": "Entra.",
        "instavc_login_email": "Correo electr\u00f3nico",
        "instavc_login_password": "Contrase\u00f1a",
        "instavc_login_button": "Entra.",
        "instavc_login_forgotpassword": "\u00bfOlvid\u00f3 la contrase\u00f1a?",
        "instavc_login_have_account": "\u00bfNo tienes una cuenta?",
        "instavc_login_signup": "Inscr\u00edbete en",
        "instavc_signup": "Inscr\u00edbete.",
        "instavc_signup_firstname": "Nombre",
        "instavc_signup_lastname": "Apellido",
        "instavc_signup_email": "Correo electr\u00f3nico",
        "instavc_signup_password": "Contrase\u00f1a",
        "instavc_signup_cpassword": "Confirme la contrase\u00f1a",
        "instavc_signup_button": "Inscr\u00edbete.",
        "instavc_signup_already_have_account": "\u00bfYa tienes una cuenta?",
        "instavc_signup_login": "Entra.",
        "instavc_signup_fname_error": "El nombre debe ser s\u00f3lo el alfabeto.",
        "instavc_signup_lname_error": "El apellido debe ser s\u00f3lo el alfabeto.",
        "instavc_signup_email_error": "El correo electr\u00f3nico debe estar en un formato adecuado",
        "instavc_signup_password_error": "La contraseña debe tener ocho caracteres y una combinación de una mayúscula, una minúscula, un dígito y un carácter especial ",
        "instavc_signup_cpassword_error": "Confirmar la contrase\u00f1a debe coincidir con la contrase\u00f1a anterior",
        "instavc_forgotpassword_link": "Le enviaremos un enlace a su correo electr\u00f3nico registrado. S\u00f3lo tienes que hacer clic en el enlace y establecer tu nueva contrase\u00f1a",
        "instavc_forgotpassword_email": "Introduzca su direcci\u00f3n de correo electr\u00f3nico registrada",
        "instavc_forgotpassword": "Olvid\u00e9 la contrase\u00f1a",
        "instavc_forgotpassword_button": "Olvid\u00e9 la contrase\u00f1a",
        "instavc_confirmpass_heading": "Crea una nueva contrase\u00f1a rellenando el formulario.",
        "instavc_confirmpass_verificationcode": "Introduzca el c\u00f3digo de verificaci\u00f3n",
        "instavc_confirmpass_newpassword": "Introduzca la nueva contrase\u00f1a",
        "instavc_confirmpass_retypepassword": "Vuelva a escribir la nueva contrase\u00f1a",
        "instavc_confirmpass_button": "CREAR UNA NUEVA CONTRASE\u00d1A",
        "instavc_forgotpassword_signin": "Entra en el sistema",
        "instavc_forgotpassword_passwordcreated": "\u00a1Felicidades! Tu nueva contrase\u00f1a ha sido creada, ahora puedes entrar en instaVC.",
        "instavc_dashboard": "DASHBOARD",
        "instavc_dashboard_schedule": "PROGRAMAR LA REUNI\u00d3N",
        "instavc_dashboard_meetinglist": "REUNIONES COMPLETADAS",
        "instavc_dashboard_conferenceplatform": "Las Plataformas de la reuni\u00f3n con una experiencia de usuario sin igual",
        "instavc_dashboard_SC_room": "Iniciar una reuni\u00f3n",
        "instavc_dashboard_SC_roomlist": "Al seleccionar su reuni\u00f3n programada",
        "instavc_dashboard_startconference": "INICIAR LA REUNI\u00d3N",
        "instavc_dashboard_JC": "Unirse a la reuni\u00f3n",
        "instavc_dashboard_JC_ID": "a trav\u00e9s de la identificaci\u00f3n de la conferencia o",
        "instavc_dashboard_JC_button": "UNIRSE A LA CONFERENCIA",
        "instavc_dashboard_SC": "Iniciar una reuni\u00f3n",
        "instavc_dashboard_SC_p2p": "Seleccionando una habitaci\u00f3n de su lista",
        "instavc_dashboard_instaconference": "CONFERENCIA DE LA INSTA",
        "instavc_dashboard_buynow": "Comprar ahora",
        "instavc_dashboard_myprofile": "Mi perfil",
        "instavc_dashboard_settings": "Ajustes",
        "instavc_dashboard_myadmin": "Mi administrador",
        "instavc_dashboard_affiliate": "Mi afiliado",
        "instavc_dashboard_logout": "Cierre de sesi\u00f3n",
        "conference_myrooms": "Mis habitaciones",
        "conference_myrooms_host": "Anfitri\u00f3n",
        "conference_myrooms_roomhost": "Usted es el anfitri\u00f3n de esta habitaci\u00f3n.",
        "conference_myrooms_search": "Busca...",
        "conference_myrooms_hostuser": "Usuario anfitri\u00f3n",
        "conference_myrooms_gotopremium": "Comprar una licencia",
        "conference_myrooms_buynow": "Comprar ahora",
        "conference_myrooms_renewal": "Renovaci\u00f3n",
        "conference_setup": "Configuraci\u00f3n",
        "conference_myrooms_renewnow": "Renovar ahora",
        "conference_myrooms_join": "Participar",
        "conference_myrooms_setup": "Configuraci\u00f3n de la habitaci\u00f3n",
        "conference_myrooms_sethost_email": "P\u00f3ngase como anfitri\u00f3n, o introduzca la direcci\u00f3n de correo electr\u00f3nico de un usuario para ponerlo como anfitri\u00f3n.",
        "conference_myrooms_selfhost": "Ponte como anfitri\u00f3n",
        "conference_myrooms_otherhost": "O - Si quieres hacer a cualquier usuario como anfitri\u00f3n, introduce su direcci\u00f3n de correo electr\u00f3nico.",
        "conference_myrooms_email": "Direcci\u00f3n de correo electr\u00f3nico",
        "conference_myrooms_cancel": "Cancelar",
        "conference_myrooms_apply": "APLICAR",
        "view_schedule_myrooms": "Mis habitaciones",
        "view_schedule_search": "Busca...",
        "view_schedule_schedule": "Programaci\u00f3n",
        "view_schedule_Videorecording": "Grabaciones de video",
        "view_schedule_calender": "A continuaci\u00f3n se muestra el calendario para comprobar los horarios de esta sala",
        "view_recordings": "Grabaciones de video",
        "view_recordings_search": "Busca en",
        "view_recordings_month": "Este mes",
        "view_recordings_week": "Los \u00faltimos 7 d\u00edas",
        "view_recordings_today": "Hoy",
        "no_recordings": "No hay grabaciones todav\u00eda!",
        "no_recordings_message": "No hay videos aqu\u00ed, ahora mismo.",
        "no_recordings_startmessage": "Una vez que empieces a grabar puedes ver todos tus videos aqu\u00ed.",
        "stop_recording": "\u00bfQuieres detener esta grabaci\u00f3n?",
        "stop_recording_agree": "ACEPTAR",
        "stop_recording_disagree": "DESACUERDO",
        "conference_in_use": "La reuni\u00f3n est\u00e1 en uso",
        "conference_mode": "Modo de reuni\u00f3n",
        "large_conference_mode": "Modo de \"Reuni\u00f3n Grande\"",
        "conference_setup_configuration": "Configuraci\u00f3n",
        "conference_setup_generateid": "No se encontr\u00f3 ninguna identificaci\u00f3n de reuni\u00f3n, \u00a1genera una!",
        "conference_setup_regenerate": "Regenerar",
        "conference_setup_meetingid": "Reuni\u00f3n Id.",
        "conference_setup_camera": "C\u00e1mara FaceTime HD",
        "conference_setup_defaultmicrophone": "Por defecto - Micr\u00f3fono interno (incorporado)",
        "conference_setup_microphone": "Micr\u00f3fono interno (incorporado)",
        "conference_setup_volume": "Volumen",
        "conference_setup_videomode": "Modo de video",
        "conference_setup_forward": "Directo",
        "conference_setup_mix": "Mezcla",
        "conference_setup_invite": "Invitar a",
        "conference_setup_invitepeople": "Invitar a la gente",
        "conference_setup_invitefriends": "Invita a tus amigos y compa\u00f1eros de trabajo a colaborar.",
        "conference_setup_invite_directlink": "Enlace directo a su habitaci\u00f3n",
        "conference_setup_invite_sharelink": "Comparta este enlace con sus participantes para que se unan a su sesi\u00f3n",
        "conference_setup_invite_link": "https:\/\/connect.instavc.com\/conference-guest\/eddca0fc7adcdc61a24dabe02597412a36bb96dac7302f3cedb6aac50169d58614eb46b940b556a9\/f",
        "conference_setup_invitebyemail": "Invitaci\u00f3n por correo",
        "conference_setup_invite_enteremail": "Introduzca la direcci\u00f3n de correo electr\u00f3nico preferida para invitar a sus participantes",
        "conference_setup_invite_emailid": "Direcci\u00f3n de correo electr\u00f3nico",
        "conference_setup_invite_close": "CERRAR",
        "conference_setup_invite_button": "Invitar a",
        "conference_setup_advancesettings": "Ajustes avanzados",
        "conference_setup_changeresolution": "Elija la resoluci\u00f3n",
        "conference_setup_lowbandwidth": "Ancho de banda bajo",
        "conference_setup_poornetwork": "Red pobre",
        "conference_setup_vga": "VGA - (640x480)",
        "conference_setup_vga_graphics": "Para la resoluci\u00f3n de v\u00eddeo de la matriz de gr\u00e1ficos de v\u00eddeo (VGA)",
        "conference_setup_hd1080": "HD1080p - (1920x1080)",
        "conference_setup_fhd": "Para una resoluci\u00f3n de video de alta definici\u00f3n completa (FHD)",
        "conference_setup_4k": "4K - (3840 x 2160)",
        "conference_setup_4k_fhd": "Para una resoluci\u00f3n de video de alta definici\u00f3n completa (FHD)",
        "conference_setup_sif": "SIF - (320x240)",
        "conference_setup_sif_vr": "Para la resoluci\u00f3n de video SIF",
        "conference_setup_hd720": "HD720p - (1280x720)",
        "conference_setup_hd720_vr": "Para la resoluci\u00f3n de video de alta definici\u00f3n (HD)",
        "conference_setup_2k": "2K - (2048C1080)",
        "conference_setup_2k_fhd": "Para una resoluci\u00f3n de video de alta definici\u00f3n completa (FHD)",
        "conference_setup_broadcastoptions": "Opciones de transmisi\u00f3n",
        "conference_setup_enable": "Habilitar",
        "conference_setup_disable": "Desactivar",
        "conference_setup_reportquality": "Informar de las estad\u00edsticas de calidad para ayudar a mejorar las llamadas de voz y v\u00eddeo",
        "conference_setup_havetroubleshoot": "\u00bfLe gustar\u00eda tener una reuni\u00f3n de resoluci\u00f3n de problemas, entonces haga clic en el enlace aqu\u00ed",
        "conference_setup_conferencetroubleshoot": "CONFERENCIA SOLUCIONADOR DE PROBLEMAS",
        "conference_troubleshoot": "Soluci\u00f3n de problemas",
        "conference_troubleshoot_faq": "FAQ",
        "conference_troubleshoot_emailreport": "INFORME DE CORREO ELECTR\u00d3NICO",
        "conference_troubleshoot_gotoconference": "IR A LA REUNI\u00d3N",
        "conference_troubleshoot_os": "Sistema operativo",
        "conference_troubleshoot_version": "versi\u00f3n",
        "conference_troubleshoot_browser": "Navegador",
        "conference_troubleshoot_chromeversion": "Versi\u00f3n cromada",
        "conference_troubleshoot_privatebrowsing": "Navegaci\u00f3n privada",
        "conference_troubleshoot_displayresolution": "Resoluciones de la pantalla",
        "conference_troubleshoot_aspectratio": "Mostrar la relaci\u00f3n de aspecto",
        "conference_troubleshoot_hasspeakers": "\u00bfEl sistema tiene altavoces?",
        "conference_troubleshoot_foundspeaker": "Encontr\u00e9 Dispositivos de altavoz",
        "conference_troubleshoot_hasmicrophone": "\u00bfEl sistema tiene micr\u00f3fono?",
        "conference_troubleshoot_foundmicrophone": "Encontr\u00e9 Dispositivos de micr\u00f3fono",
        "conference_troubleshoot_haswebcam": "El sistema tiene c\u00e1mara web ?",
        "conference_troubleshoot_foundcameras": "Encontr\u00e9 dispositivos de c\u00e1mara",
        "conference_troubleshoot_webcampermission": "\u00bfEl sitio web tiene permisos de c\u00e1mara web?",
        "conference_troubleshoot_microphonepermission": "\u00bfEl sitio web tiene permisos de micr\u00f3fono?",
        "conference_troubleshoot_getUsermedia": "El navegador permite obtener los medios de comunicaci\u00f3n de usuario en esta p\u00e1gina ?",
        "conference_troubleshoot_changeoutput_audiodevice": "\u00bfPuedes cambiar los dispositivos de audio de salida?",
        "conference_troubleshoot_changecamera_resolution": "\u00bfPuedes cambiar la resoluci\u00f3n de la c\u00e1mara sin hacer la nueva solicitud de la reuni\u00f3n?",
        "conference_troubleshoot_supportwebrtc": "\u00bfEl navegador soporta WebRTC (ya sea 1.0 o 1.1)?",
        "conference_troubleshoot_supportortc": "El navegador soporta ORTC (WebRTC 1.1) ?",
        "conference_troubleshoot_turnserver": "\u00bfEl servidor de TURN es accesible?",
        "conference_troubleshoot_turn": "turno:139.59.64.172:443",
        "conference_troubleshoo_coturn": "turn:coturn.instavc.com:443",
        "conference_troubleshoot_replacetracks": "\u00bfPuedes reemplazar las pistas sin renegociar con los compa\u00f1eros?",
        "conference_troubleshoot_record_remoteaudio": "\u00bfPuede su navegador grabar audio remoto o procesar el flujo de audio remoto en la API de WebAudio",
        "conference_troubleshoot_browsersupports_websocketapi": "Los navegadores soportan la API de WebSockets",
        "conference_troubleshoot_blockwebsocket": "\u00bfSu sistema bloque\u00f3 el protocolo de WebSockets, o el servidor de WebSockets no es accesible? No",
        "conference_troubleshoot_supportwebaudioapi": "El navegador soporta la API de WebAudio ?",
        "conference_troubleshoot_supportsctp": "El navegador soporta los canales de datos del SCTP ?",
        "conference_troubleshoot_supportsrtp": "El navegador soporta canales de datos RTP ?",
        "conference_troubleshoot_support_screencapturing": "Esta p\u00e1gina soporta el API de captura de pantalla ?",
        "conference_troubleshoot_support_multimonitor": "\u00bfEl navegador soporta la selecci\u00f3n y captura de pantalla de cualquier monitor?",
        "conference_troubleshoot_mobiledevice": "\u00bfEs un dispositivo m\u00f3vil?",
        "conference_troubleshoot_supportscreencap_canvas": "\u00bfEl navegador soporta la captura de pantalla desde el lienzo?",
        "conference_troubleshoot_supportscreencap_video": "\u00bfEl navegador soporta la captura de pantalla desde el video?",
        "conference_troubleshoot_supportpromisses": "\u00bfEl navegador apoya las promesas?",
        "conference_troubleshoot_ipaddress": "Haga clic para mostrar las direcciones IP",
        "conference_troubleshoot_stunserver": "\u00bfEl servidor de STUN es accesible?",
        "conference_troubleshoot_cancel": "Cancelar",
        "instavc_conference_revoke_fromhost": "Revocaci\u00f3n del anfitri\u00f3n",
        "instavc_conference_startvideoconf_button": "INICIAR LA VIDEOCONFERENCIA",
        "instavc_conference_loading": "cargando su pantalla por favor espere",
        "instavc_conference_authenticating": "Autentificando en el servidor Instavc...",
        "instavc_conference_joinconference": "La reuni\u00f3n de adhesi\u00f3n",
        "instavc_conference_publishyourvideo": "Publicar tu video",
        "instavc_conference_publishyouraudio": "Publicar tu audio",
        "instavc_conference_settings": "Ajustes",
        "instavc_conference_setting": "Configuraci\u00f3n de la reuni\u00f3n",
        "instavc_conference_setting_source": "FUENTE",
        "instavc_conference_setting_camera": "C\u00e1mara",
        "instavc_conference_audioinputs": "Entradas de audio",
        "instavc_conference_setting_cancel": "Cancelar",
        "instavc_conference_setting_apply": "APLICAR",
        "instavc_conference_setting_resolution": "RESOLUCI\u00d3N",
        "instavc_conference_setting_qr": "QR(BETA)",
        "instavc_conference_whiteboard": "Tablero blanco",
        "instavc_conference_screenshare": "Compartir pantalla",
        "instavc_conference_presenter": "Presentador",
        "instavc_conference_stopshare": "Deja de compartir",
        "instavc_conf_install_Screenshare": "Instalar ScreenShare",
        "instavc_conference_videosync": "Video Sync",
        "instavc_conference_videourl": "URL del video",
        "instavc_conference_enter_videourl": "Ingresa la URL del video",
        "instavc_conference_videourl_note": "Nota: S\u00f3lo los participantes activos pueden ver este video, los que llegan tarde no pueden ver el video.",
        "instavc_conf_recordings": "Grabaci\u00f3n",
        "instavc_conference_sharecontent": "Comparte tu pantalla",
        "instavc_conference_sharecontent_screen": "connect.instavc.com quiere compartir el contenido de su pantalla. Elige lo que te gustar\u00eda compartir",
        "instavc_conference_shareentire_screen": "Toda la pantalla",
        "instavc_conference_sharescreen_applicationwindow": "Ventana de aplicaci\u00f3n",
        "instavc_conference_sharescreen_chrometab": "Pesta\u00f1a de cromo",
        "instavc_conference_sharescreen_cancel": "Cancelar",
        "instavc_conference_sharescreen_share": "Comparte",
        "instavc_conf_sharescreen": "\u00a1Est\u00e1s compartiendo tu pantalla!",
        "instavc_conf_share_otherparticipants": "Otros participantes pueden ver las solicitudes y documentaciones en su pantalla",
        "instavc_stopsharing": "Deja de compartir",
        "instavc_conference_chat": "Chat",
        "instavc_conference_additional_camera": "C\u00e1mara adicional",
        "instavc_conference_sharescreen_groupchat": "Chat en grupo",
        "instavc_conference_sharescreen_GC": "GC",
        "instavc_conference_entermessage": "\u00a1Di algo!",
        "instavc_conference_adddevice": "Agregar dispositivos",
        "instavc_conference_adddevice_addcamera": "Agrega la c\u00e1mara",
        "instavc_conference_adddevice_source": "FUENTE",
        "instavc_conference_adddevice_camera": "C\u00e1mara",
        "instavc_conference_adddevice_cameraname": "C\u00e1mara FaceTime HD",
        "instavc_conference_adddevice_close": "CERRAR",
        "instavc_conference_adddevice_publish": "PUBLICA",
        "instavc_conference_info": "info",
        "instavc_conference_questions": "Preguntas",
        "instavc_conference_askquestions": "Haz preguntas...",
        "instavc_conference_info_meetingid": "Identificaci\u00f3n de la reuni\u00f3n personal",
        "instavc_conference_fullscreen": "Pantalla completa",
        "conference_setup_invitepeoplel": "Invitar a la gente",
        "conference_setup_invite_linktojoin": "https:\/\/connect.instavc.com\/conference-guest\/93ac2135d88727ca29061b4b6bf9477bc8f285a8121ff580649512abad353fbc6751a134d56438f7\/f",
        "conference_setup_invite_byemail": "Invitaci\u00f3n por correo",
        "instavc_conference_participants": "participantes",
        "instavc_conference_participants_search": "Busca...",
        "instavc_conference_participant": "Participante",
        "instavc_conference_participants_muteall": "Silenciar todo",
        "instavc_conference_participants_unmuteall": "Desactivar todo",
        "instavc_conference": "Reuni\u00f3n",
        "instavc_conf_more": "M\u00e1s",
        "instavc_conference_picturemode": "Este video se reproduce en el modo Picture-in-Picture...",
        "instavc_conference_mediastreams_stats": "Estad\u00edsticas de los medios de comunicaci\u00f3n",
        "instavc_conference_mediastreams_audio": "Audio",
        "instavc_conference_mediastreams_audio_send": "Env\u00eda",
        "instavc_conference_mediastreams_audio_codec": "C\u00f3dec",
        "instavc_conference_mediastreams_audio_rates": "Tarifas (Kbps)",
        "instavc_conference_mediastreams_audio_lost": "Perdido",
        "instavc_conference_mediastreams_audio_fraction": "Fracci\u00f3n",
        "instavc_conference_mediastreams_video": "Video",
        "instavc_conference_mediastreams_video_send": "Env\u00eda",
        "instavc_conference_mediastreams_video_codec": "C\u00f3dec",
        "instavc_conference_mediastreams_video_rates": "Tarifas (Kbps)",
        "instavc_conference_mediastreams_video_lost": "Perdido",
        "instavc_conference_mediastreams_video_fraction": "Fracci\u00f3n",
        "instavc_conference_resolution": "RESOLUCI\u00d3N",
        "instavc_conference_framerate": "Velocidad de cuadro",
        "instavc_conference_nack": "Nack",
        "instavc_conference_pli": "PLI",
        "instavc_conference_bw": "Disponible BW",
        "instavc_conference_audiomuted": "Audio silenciado",
        "instavc_conference_microphonemuted": "Su micr\u00f3fono se ha silenciado",
        "join_conference_join_meetinginvitation": "Invitaciones a reuniones",
        "join_conference_search": "Busca...",
        "join_conference_meeting details": "nombre de la reuni\u00f3n\nInvitado por:\nnombre del invitado",
        "join_conference_join": "Participar",
        "join_conference_nomeetings": "No hay reuniones",
        "join_conference_noupcomingmeetings": "No tienes invitaci\u00f3n a la reuni\u00f3n",
        "insta_conference_mycontacts": "Mis contactos",
        "insta_conference_search": "Busca...",
        "insta_conference_typemessage": "Escriba un mensaje",
        "insta_conference_addcontact": "A\u00f1adir el contacto",
        "insta_conference_enteremail": "Ingresa el ID de correo",
        "insta_conference_add": "A\u00f1ade",
        "insta_conference_nouserfound": "No se ha encontrado ning\u00fan usuario con esta direcci\u00f3n de correo electr\u00f3nico",
        "instavc_conf_callnot_allowed": "No puedes llamar a otros usuarios cuando ya est\u00e1s en una llamada",
        "insta_conference_pendingapproval": "Pendiente de aprobaci\u00f3n",
        "insta_conference_outgoingvideocall": "Videollamada saliente",
        "insta_conference_calling": "Llamando...",
        "insta_conference_incomingvideocall": "Videollamada entrante",
        "insta_conference_calldecline": "Declinar",
        "insta_conference_callaccept": "Acepta",
        "insta_conference_instavcalert": "connect.instavc.com quiere permitir",
        "insta_conference_usemicrophone": "Usa tu micr\u00f3fono",
        "insta_conference_usecamera": "Usa tu c\u00e1mara",
        "insta_conference_blockdevices": "Bloqueo",
        "insta_conference_allowdevices": "Permita",
        "instavc_schedulemeeting_createmeeting": "Crear una nueva reuni\u00f3n \/ Editar",
        "instavc_schedulewebinar_createmeeting": "Crear un nuevo seminario web \/ Editar",
        "instavc_schedulemeeting_meetingname": "Dale un nombre a tu reuni\u00f3n",
        "instavc_schedulemeeting_selectnames_tomeeting": "Seleccione una sala para su reuni\u00f3n",
        "instavc_schedulemeeting_setduration": "Establecer la duraci\u00f3n",
        "instavc_schedulemeeting_15min": "15 minutos",
        "instavc_schedulemeeting_30min": "30 minutos",
        "instavc_schedulemeeting_60min": "60 minutos",
        "instavc_schedulemeeting_setcustom": "Establecer la costumbre",
        "instavc_schedulemeeting_customduration": "Duraci\u00f3n de la costumbre",
        "instavc_schedulemeeting_minutes": "Actas",
        "instavc_schedulemeeting_setconfpassword": "Establezca la contrase\u00f1a de la conferencia",
        "instavc_schedulemeeting_typepassword": "Escriba la contrase\u00f1a de su reuni\u00f3n",
        "instavc_schedulemeeting_conferencecolor": "Dale color a tu icono de la reuni\u00f3n",
        "instavc_schedulemeeting_invitepeople": "Invitar a la gente a esta reuni\u00f3n",
        "instavc_schedulemeeting_search": "Busca...",
        "instavc_schedulemeeting_SM": "SM",
        "instvc_SM_message": "El n\u00famero de usuarios con la licencia de usuario autorizado ha superado el l\u00edmite de la licencia. Si quieres invitar a m\u00e1s gente puedes",
        "instavc_SM_message_href": "Comprar una licencia",
        "instavc_schedulemeeting_notify": "Notifique a las personas seleccionadas sobre esta reuni\u00f3n",
        "instavc_schedulemeeting_addreminder": "Agregar recordatorios",
        "instavc_schedulemeeting_add": "A\u00f1ade",
        "instavc_schedulemeeting_addmeetingreminder": "Agregar recordatorio de la reuni\u00f3n",
        "instavc_schedulemeeting_adddatetime": "Elecci\u00f3n de la fecha, elecci\u00f3n de la hora",
        "instavc_schedulemeeting_cancel": "Cancelar",
        "instavc_schedulemeeting_apply": "APLICAR",
        "instavc_schedulemeeting_name": "Por favor, introduzca el nombre de la reuni\u00f3n",
        "instavc_schedulemeeting_selectroom": "Por favor, seleccione la habitaci\u00f3n",
        "instavc_schedulemeeting_selectduration": "Por favor, seleccione la duraci\u00f3n",
        "instavc_recuring_schedule": "Calendario recurrente",
        "instavc_schedulemeeting_nothinginreminders": "Nada en los recordatorios",
        "instavc_schedulemeeting_createbutton": "CREAR",
        "instavc_schedulemeeting_createdsuccessfully": "El programa fue creado con \u00e9xito",
        "instavc_meetinglist_calender": "CALENDARIO",
        "instavc_meetinglist_selecteddates": "Sus fechas seleccionadas",
        "instavc_meetinglist_schedulecreted": "Los horarios creados",
        "instavc_meetinglist_pastschedule": "Programa anterior",
        "instavc_meetinglist": "Lista de reuniones",
        "instavc_meetinglist_meetingdetils": "Nombre de la reuni\u00f3n, hora de la reuni\u00f3n, organizada por",
        "instavc_meetinglist_search": "Busca...",
        "instavc_meetinglist_deleterecuring": "Eliminar el programa recurrente",
        "instavc_meetinglist_alow_deleterecuring": "\u00bfLe gustar\u00eda borrar s\u00f3lo el horario actual, o todos los horarios en la recurrencia?",
        "instavc_meetinglist_schedule_series": "Todos los dem\u00e1s programas de la serie se mantendr\u00e1n:",
        "instavc_meetinglist_schedule_seriesdelete": "Todos los horarios de la serie ser\u00e1n borrados:",
        "instavc_meetinglist_allrecuringsch": "Todos los horarios recurrentes",
        "instavc_meetinglist_cancelchanges": "Cancelar los cambios",
        "instavc_meetinglist_noschedules": "No hay horarios",
        "instavc_ml_schedulemessage": "\u00a1A\u00fan no se ha creado un programa de reuniones!\n Crea tu programa haciendo clic en el bot\u00f3n de abajo.",
        "instavc_create_schedule": "Crear un programa",
        "instavc_meetinglist_currentschedule": "Programa actual",
        "instavc_meetinglist_join": "Participar",
        "instavc_meetinglist_buynow": "Comprar ahora",
        "instavc_dashboard_chooseplan": "Elija un plan",
        "instavc_package_smbplan": "Planes para PYMES",
        "instavc_package_smbplan_host": "(Licencia para el anfitri\u00f3n)",
        "instavc_package_smbplandescription": "Una habitaci\u00f3n s\u00f3lo puede tener un hu\u00e9sped a la vez. Un comprador puede establecer o cambiar la cuenta del anfitri\u00f3n, quien puede controlar la habitaci\u00f3n. Los accesos de los anfitriones a canb\ufffd se compartir\u00e1n durante la llamada. Todos los dem\u00e1s usuarios t interact\u00faan hasta que el anfitri\u00f3n se une a la reuni\u00f3n.",
        "instavc_package_basic": "B\u00e1sico",
        "instavc_package_hostuser_smb": "1 Anfitri\u00f3n y",
        "instavc_package_hostuser_smb_users": "Usuarios",
        "instavc_package_currency": "$",
        "instavc_package_permonth": "\/ mes",
        "instavc_package_bill_smb": "Facturado anualmente",
        "instavc_package_include_smb": "Este paquete incluye con",
        "instavc_package_specifictions_Whiteboard": "Tablero blanco",
        "instavc_package_specifictions_Screen": "Compartir pantalla",
        "instavc_package_specifictions_nChat": "Chat",
        "instavc_package_specifictions_Recording": "Grabaci\u00f3n",
        "instavc_package_specifictions_PresenterMode": "Modo Presentador",
        "instavc_package_buynowbutton": "Comprar ahora",
        "instavc_package_fullfeatures_smb": "CARACTER\u00cdSTICAS COMPLETAS",
        "instavc_package_billinginfo": "Informaci\u00f3n de facturaci\u00f3n",
        "instavc_package_billinginfo_name": "Nombre",
        "instavc_package_billinginfo_orginazation": "Orginazaci\u00f3n",
        "instavc_package_biilinginfo_gstin": "GSTIN",
        "instavc_package_billinginfo_save": "Guardar",
        "instavc_package_billinginfo_edit": "Edita",
        "instavc_package_billingdetails": "Detalles de la facturaci\u00f3n",
        "instavc_package_billingdetails_firstname": "Nombre",
        "instavc_package_billingdetails_lastname": "Apellido",
        "instavc_package_billingdetails_address": "Direcci\u00f3n",
        "instavc_package_billingdetails_city": "Ciudad \/ Pueblo",
        "instavc_package_billingdetails_zip": "Zip",
        "instavc_package_billingdetails_cancel": "Cancelar",
        "instavc_package_billingdetails_save": "Guardar",
        "instavc_package_shippinginfo": "Informaci\u00f3n de env\u00edo",
        "instavc_package_shippinginfo_edit": "Edita",
        "instavc_package_shippingdetails": "Detalles del env\u00edo",
        "instavc_package_shippingdetails_firstname": "Nombre",
        "instavc_package_shippingdetails_lastname": "Apellido",
        "instavc_package_shippingdetails_address": "Direcci\u00f3n",
        "instavc_package_shippingdetails_city": "Ciudad \/ Pueblo",
        "instavc_package_shippingdetails_zip": "Zip",
        "instavc_package_shippingdetails_cancel": "Cancelar",
        "instavc_package_shippingdetails_save": "Guardar",
        "instavc_package_cartsummary": "Resumen del carro",
        "instavc_package_hostbase": "Licencia basada en el anfitri\u00f3n (nombre de la licencia)",
        "instavc_package_smbplan_basic": "Plan SMB - B\u00e1sico",
        "instavc_package_plandetails": "$ 144.02\n\nfacturado por 1 a\u00f1o\n13 d\u00f3lares al mes",
        "instavc_package_term": "T\u00e9rmino",
        "instavc_package_1year": "1 a\u00f1o",
        "instavc_package_2years": "2 a\u00f1os",
        "instavc_package_3years": "3 a\u00f1os",
        "instavc_package_4years": "4 a\u00f1os",
        "instavc_package_5years": "5 a\u00f1os",
        "instavc_package_subtotal": "Subtotal",
        "instavc_package_taxes": "Impuestos y tasas (GST +18%)",
        "instavc_package_launchoffer": "LANZAMIENTO",
        "instavc_package_promo": "La promoci\u00f3n se aplic\u00f3 con \u00e9xito",
        "instavc_package_remove": "Retire",
        "instavc_package_havepromocode": "\u00bfTiene un c\u00f3digo de promoci\u00f3n? Aplique aqu\u00ed",
        "instavc_package_entercouponcode": "Por favor, introduzca el c\u00f3digo del cup\u00f3n v\u00e1lido",
        "instavc_package_total": "Total",
        "instavc_package_offerdisclaimer": "Ver el descargo de responsabilidad de la oferta",
        "instavc_package_paynow": "PAGUE AHORA",
        "instavc_package_payment_instavclicense": "Comunicaci\u00f3n unificada PeopleLink\nPago de la licencia InstaVC",
        "instavc_package_phone": "Tel\u00e9fono",
        "instavc_package_entervalid_contactnumber": "Por favor, introduzca el n\u00famero de contacto v\u00e1lido",
        "instavc_package_email": "Correo electr\u00f3nico",
        "instavc_package_enteremail": "Por favor, introduzca una identificaci\u00f3n v\u00e1lida. Ejemplo: you@example.com",
        "instavc_package_paybycard": "PAGAR CON TARJETA",
        "instavc_package_securedbyrazorpay": "Asegurado por Razorpay",
        "instavc_package_card": "CARTA",
        "instavc_package_enterotp": "Introduzca la OTP enviada en xxxxxxxxx\npara acceder a las Tarjetas de Ahorro",
        "instavc_package_resendotp": "Reenviar OTP",
        "instavc_package_skipsavedcards": "Saltar las tarjetas guardadas",
        "instavc_package_enter_otp": "Por favor, introduzca el otp",
        "instavc_package_skipthesavedcards": "Saltar las cartas guardadas",
        "instavc_package_cardnumber": "N\u00famero de tarjeta",
        "instavc_package_entervalid_cardnumber": "Por favor, introduzca un n\u00famero de tarjeta v\u00e1lido",
        "instavc_package_expiry": "Expiraci\u00f3n",
        "instavc_package_cardholdername": "Nombre del titular de la tarjeta",
        "instavc_package_cvv": "CVV",
        "instavc_package_3digitcode": "Es un c\u00f3digo de 3 d\u00edgitos impreso en el reverso de su tarjeta.",
        "instavc_package_pay": "PAGUE",
        "instavc_package_remembercard": "Tarjeta de recuerdo",
        "instavc_package_medium": "Medio",
        "instavc_package_hostuser_medium": "1 anfitri\u00f3n y 50 usuarios",
        "instavc_package_month_medium": "$ 15 al mes.",
        "instavc_package_bill_medium": "Facturado anualmente + GST 18%",
        "instavc_package_include_medium": "Este paquete incluye con",
        "instavc_package_specifictions_medium": "Pizarra blanca\nCompartir pantalla\nChat\nGrabaci\u00f3n\nModo Presentador",
        "instavc_package_buynow_medium": "Comprar ahora",
        "instavc_package_fullfeatures_medium": "CARACTER\u00cdSTICAS COMPLETAS",
        "instavc_package_advanced": "Avanzado",
        "instavc_package_hostuser_advanced": "1 Host y 100 usuarios",
        "instavc_package_month_advanced": "$ 16  al mes.",
        "instavc_package_bill_advanced": "Facturado anualmente + GST 18%",
        "instavc_package_include_advanced": "Este paquete incluye con",
        "instavc_package_specifictions_advanced": "Pizarra blanca\nCompartir pantalla\nChat\nGrabaci\u00f3n\nModo Presentador",
        "instavc_package_buynow_advanced": "Comprar ahora",
        "instavc_package_fullfeatures_advanced": "CARACTER\u00cdSTICAS COMPLETAS",
        "instavc_package_virtualmcu": "Licencia de MCU virtual",
        "instavc_package_virtualmcu_room": "(Licencia de habitaci\u00f3n)",
        "instavc_package_vmcudetails": "Una habitaci\u00f3n s\u00f3lo puede tener un hu\u00e9sped a la vez. Un comprador puede establecer o cambiar la cuenta del anfitri\u00f3n, quien puede controlar la habitaci\u00f3n. Los accesos de los anfitriones a canb\ufffd se compartir\u00e1n durante la llamada. Todos los dem\u00e1s usuarios t interact\u00faan hasta que el anfitri\u00f3n se une a la reuni\u00f3n.",
        "instavc_package_instavc": "InstaVC",
        "instavc_package_silver": "Sliver",
        "instavc_package_userrooms_silver_users": "Usuarios",
        "instavc_package_userrooms_silver_rooms": "Habitaciones",
        "instavc_package_month_silver": "$ 281 al mes",
        "instavc_package_bill_silver": "Facturado anualmente + GST 18%",
        "instavc_package_include_silver": "Este paquete incluye con",
        "instavc_package_specifictions_silver": "Pizarra blanca\nCompartir pantalla\nChat\nGrabaci\u00f3n\nModo Presentador",
        "instavc_package_buynow_silver": "Comprar ahora",
        "instavc_package_fullfeatures_silver": "CARACTER\u00cdSTICAS COMPLETAS",
        "instavc_package_gold": "Oro",
        "instavc_package_userrooms_gold": "200 usuarios - 30 habitaciones",
        "instavc_package_month_gold": "$ 467 al mes.",
        "instavc_package_bill_gold": "Facturado anualmente + GST 18%",
        "instavc_package_include_gold": "Este paquete incluye con",
        "instavc_package_specifictions_gold": "Pizarra blanca\nCompartir pantalla\nChat\nGrabaci\u00f3n\nModo Presentador",
        "instavc_package_buynow_gold": "Comprar ahora",
        "instavc_package_fullfeatures_gold": "CARACTER\u00cdSTICAS COMPLETAS",
        "instavc_package_platinum": "Platino",
        "instavc_package_userrooms_platinum": "500 usuarios - 50 habitaciones",
        "instavc_package_month_platinum": "$ 933 al mes",
        "instavc_package_bill_platinum": "Facturado anualmente + GST 18%",
        "instavc_package_include_platinum": "Este paquete incluye con",
        "instavc_package_specifictions_platinum": "Pizarra blanca\nCompartir pantalla\nChat\nGrabaci\u00f3n\nModo Presentador",
        "instavc_package_buynow_platinum": "Comprar ahora",
        "instavc_package_fullfeatures_platinum": "CARACTER\u00cdSTICAS COMPLETAS",
        "instavc_package_legacybaselicense": "Licencia basada en el legado",
        "instavc_package_licensebased_ports": "Licencia basada en el n\u00famero de puertos",
        "instavc_package_hardwareendpoint": "InstaVC con punto final de hardware",
        "instavc_package_webrtcconf_legacysupport": "La reuni\u00f3n de WebRTC con el apoyo de Legacy",
        "instavc_package_sip": "Soporte SIP \/ BFCP",
        "instavc_package_chh323": "Soporte de ChH323 \/ H.239",
        "instavc_package_contactus": "CONTACTO",
        "instavc_myprofile_dashboard": "DASHBOARD",
        "instavc_myprofile_settings": "Ajustes",
        "instavc_myprofile": "Mi perfil",
        "instavc_myprofile_personaldetail": "Datos personales",
        "instavc_myprofile_personaldetails_name": "Nombre",
        "instavc_myprofile_personaldetails_email": "Correo electr\u00f3nico",
        "instavc_myprofile_personaldetails_phoneno": "No hay tel\u00e9fono.",
        "instavc_myprofile_personaldetails_address": "Direcci\u00f3n",
        "instavc_myprofile_personaldetails_city": "Ciudad",
        "instavc_myprofile_edit": "Edita",
        "instavc_myprofile_personaldetails": "Datos personales",
        "instavc_myprofile_firstname": "Nombre",
        "instavc_myprofile_lastname": "Apellido",
        "instavc_myprofile_email": "Correo electr\u00f3nico",
        "instavc_myprofile_phoneno": "No hay tel\u00e9fono.",
        "instavc_myprofile_cancel": "Cancelar",
        "instavc_myprofile_save": "Guardar",
        "instavc_myprofile_professionaldetails": "Detalles profesionales",
        "instavc_myprofile_position": "Posici\u00f3n",
        "instavc_myprofile_professionaldetails_email": "Correo electr\u00f3nico",
        "instavc_myprofile_professionaldetails_phoneno": "No hay tel\u00e9fono.",
        "instavc_myprofile_professionaldetails_address": "Direcci\u00f3n",
        "instavc_myprofile_professionaldetails_branch": "Rama",
        "instavc_myprofile_professionaldetails_edit": "Edita",
        "instavc_myprofile_officialdetails": "Detalles oficiales",
        "instavc_myprofile_officialdetails_position": "Posici\u00f3n",
        "instavc_myprofile_officialdetails_email": "Correo electr\u00f3nico",
        "instavc_myprofile_officialdetails_phoneno": "No hay tel\u00e9fono.",
        "instavc_myprofile_officialdetails_branch": "Rama",
        "instavc_myprofile_officialdetails_address": "Direcci\u00f3n",
        "instavc_myprofile_officialdetails_cancel": "Cancelar",
        "instavc_myprofile_officialdetails_save": "Guardar",
        "instavc_myprofile_contactinfo": "INFORMACI\u00d3N DE CONTACTO",
        "instavc_myprofile_contactinformation": "Informaci\u00f3n de contacto",
        "instavc_myprofile_add": "A\u00f1ade",
        "instavc_myprofile_contactinformation_address": "Direcci\u00f3n",
        "instavc_myprofile_contactinformation_city": "Ciudad \/ Pueblo",
        "instavc_myprofile_contactinformation_zip": "Zip",
        "instavc_myprofile_contactinformation_landmark": "Hito",
        "instavc_myprofile_contactinformation_cancel": "Cancelar",
        "instavc_myprofile_contactinformation_save": "Guardar",
        "instavc_myprofile_address": "Direcci\u00f3n",
        "instavc_myprofile_landmark": "Hito",
        "instavc_myprofile_delete": "Alerta",
        "instavc_myprofile_deletealert": "\u00bfEst\u00e1s seguro de que quieres borrar esto?",
        "instavc_myprofile_disagree": "DESACUERDO",
        "instavc_myprofile_agree": "ACEPTAR",
        "instavc_myprofile_websitelinks": "Enlace a la p\u00e1gina web",
        "instavc_myprofile_website_add": "A\u00f1ade",
        "instavc_myprofile_website": "P\u00e1gina web",
        "instavc_myprofile_websiteexample": "https:\/\/www.google.com",
        "instavc_myprofile_website_cancel": "Cancelar",
        "instavc_myprofile_website_save": "Guardar",
        "instavc_myprofile_sociallinks": "Enlaces sociales",
        "instavc_myprofile_sociallinks_add": "A\u00f1ade",
        "instavc_myprofile_sociallink": "Enlaces sociales",
        "instavc_myprofile_sociallinks_example": "https:\/\/www.facebook.com\/xxx",
        "instavc_myprofile_sociallink_cancel": "Cancelar",
        "instavc_myprofile_sociallink_save": "Guardar",
        "instavc_myprofile_basicinfo": "Informaci\u00f3n b\u00e1sica",
        "instavc_myprofile_basicinfo_add": "A\u00f1ade",
        "instavc_myprofile_basicinfo_dob": "DOB",
        "instavc_myprofile_basicinfo_gender": "G\u00e9nero",
        "instavc_myprofile_basicinformation": "Informaci\u00f3n b\u00e1sica",
        "instavc_myprofile_basicinformation_dob": "Fecha de nacimiento",
        "instavc_myprofile_basicinformation_gender": "G\u00e9nero",
        "instavc_myprofile_basicinformation_cancel": "Cancelar",
        "instavc_myprofile_basicinformation_save": "Guardar",
        "instavc_myprofile_basicinformation_deletealert": "Alerta\n\u00bfEst\u00e1s seguro de que quieres borrar esto?",
        "instavc_myprofile_basicinformation_disagree": "DESACUERDO",
        "instavc_myprofile_basicinformation_agree": "ACEPTAR",
        "instavc_myprofile_uploadnewavatar": "SUBIR NUEVO AVATAR",
        "instavc_myprofile_cropimage": "Imagen del cultivo",
        "instavc_myprofile_image_cancel": "Cancelar",
        "instavc_myprofile_image_save": "Guardar",
        "instavc_myprofile_locale": "LOCALE",
        "instavc_myprofile_localesettings": "Ajustes de la localidad",
        "instavc_myprofile_preferredlanguage": "Idioma preferido :",
        "instavc_myprofile_language": "Ingl\u00e9s",
        "instavc_settings": "Ajustes",
        "instavc_settings_changepassword": "Cambiar la contrase\u00f1a",
        "instavc_settings_changepass": "Cambiar la contrase\u00f1a",
        "instavc_settings_currentpassword": "contraseña actual",
        "instavc_settings_newpassword": "Nueva contraseña",
        "conf_password": "confirmar Contraseña",
        "instavc_settings_changepassword_cancel": "Cancelar",
        "instavc_settings_changepassword_save": "Guardar",
        "instavc_settings_enter_currentpassword": "Por favor, introduzca la contrase\u00f1a actual",
        "instavc_settings_enter_newpassword": "Por favor, introduzca la nueva contrase\u00f1a",
        "enter_confpassword": "Ingrese la contraseña de confirmación",
        "newpassword_confpassword_should_same": "La nueva contraseña y la confirmación de contraseña deben ser iguales",
        "curnt_psw_and_new_psw_should_not_same": "La contraseña actual y la nueva contraseña no deben ser iguales",
        "instavc_settings_help": "Ayuda",
        "instavc_settings_affiliate": "Af\u00edliese",
        "instavc_settings_support": "Centro de apoyo",
        "instavc_settings_newticket": "Abrir un nuevo boleto",
        "instavc_settings_ticketstatus": "Compruebe el estado del billete",
        "instavc_settings_troubleshoot": "Soluci\u00f3n de problemas",
        "instavc_legacy_dailout": "Llama al exterior",
        "instavc_legacy_stats": "Estad\u00edsticas",
        "instavc_legacy_dail_in_detail": "Detalles de la llamada",
        "instavc_legacy_h323_endpoint": "H.323 Punto final",
        "instavc_legacy_sip_endpoint": "Punto final del SIP",
        "instavc_admin_users": "Usuarios",
        "instavc_admin_username": "Nombre de usuario",
        "instavc_admin_user_accountstatus": "Estado de la cuenta",
        "instavc_admin_user_confirmed": "Confirmado",
        "instavc_admin_user_unconfirmed": "Sin confirmar",
        "instavc_admin_user_resetall": "Reajustar todo",
        "instavc_admin_user_sync": "Sincronizaci\u00f3n",
        "instavc_admin_user_status": "Estado",
        "instavc_admin_user_phonenumber": "N\u00famero de tel\u00e9fono",
        "instavc_admin_user_created": "Creado",
        "instavc_admin_user_all": "Todos",
        "instavc_admin_groups": "Grupos",
        "instavc_admin_addmore": "A\u00f1adir m\u00e1s",
        "instavc_admin_group_peoplelink": "Queens, GOLIVE, PeopleLink",
        "instavc_admin_select_plan": "Seleccione el plan",
        "instavc_admin_lastmodified": "\u00daltima modificaci\u00f3n",
        "instavc_admin_email_verified": "Correo electr\u00f3nico verificado",
        "instavc_admin_verified": "Verificado",
        "instavc_admin_Unverified": "Sin verificar",
        "instavc_admin_licenses": "Licencias",
        "instavc_admin_addlicense": "Agregar licencias",
        "instavc_admin_defaultroom": "Sala por defecto",
        "instavc_admin_hostlicense": "Licencia para el anfitri\u00f3n",
        "instavc_admin_roomlicense": "Licencia de habitaci\u00f3n",
        "instavc_admin_virtualmcu": "Licencia virtual basada en MCU",
        "instavc_admin_enterprise": "Licencia de empresa",
        "instavc_mcuserver": "Servidor MCU",
        "instavc_mculink": "https:\/\/awsmcu.instavc.com",
        "instavc_admin_expirydate": "Fecha de caducidad",
        "instavc_admin_settings": "Configuraci\u00f3n de la administraci\u00f3n",
        "instavc_default_offer": "Oferta por defecto para la remisi\u00f3n",
        "instavc_enter_coupon_code": "Introduzca el c\u00f3digo del cup\u00f3n",
        "instavc_current_partshare": "Participaci\u00f3n actual de los socios",
        "instavc_share_value": "El valor de las acciones",
        "instavc_affliate_share": "Participaci\u00f3n actual de los afiliados",
        "instavc_enter_share": "Introduzca el valor de la acci\u00f3n (%)",
        "instavc_roomname": "Nombre de la habitaci\u00f3n",
        "instavc_room_ten": "Diez",
        "instavc_room_twenty": "Veinte",
        "instavc_room_thirty": "Treinta",
        "instavc_room_hostbased": "Con base en el anfitri\u00f3n",
        "instavc_virtualmcu": "MCU virtual",
        "instavc_rooms_enterprises": "Empresa",
        "instavc_roomid": "Identificaci\u00f3n de la habitaci\u00f3n",
        "instavc_roomtype": "Tipo de habitaci\u00f3n",
        "instavc_room_options": "Opciones de habitaci\u00f3n",
        "instavc_commercialtype": "Tipo comercial",
        "instavc_free": "Libre",
        "instavc_poc": "POC",
        "instavc_commercial": "Comercial",
        "instavc_createdAt": "Creado en",
        "instavc_useraccess": "Acceso de usuario",
        "instavc_peoplelinkroom": "Sala Peoplelink",
        "instavc_inputlimit": "L\u00edmite de entrada",
        "instavc_participant_limit": "L\u00edmite de participantes",
        "instavc_adduser": "A\u00f1adir usuario",
        "instavc_searchbyname": "Buscar por nombre",
        "instavc_manage_plans": "Administrar los planes\/productos",
        "instavc_new_plans": "Nuevo plan\/producto",
        "instavc_allplan_list": "Lista de todos los planes",
        "instavc_sortby": "Ordenar por",
        "instavc_active": "Activo",
        "instavc_inactive": "Inactivo",
        "instavc_create_plan": "Crear un nuevo plan",
        "instavc_plan_name": "Nombre del plan",
        "instavc_enteramount": "Introduzca la cantidad (Min: INR 100)",
        "instavc_select_product": "Seleccione el producto",
        "instavc_host_room": "1 Anfitri\u00f3n \/ Habitaci\u00f3n",
        "instavc_multiroom": "Multi Anfitri\u00f3n \/ Habitaci\u00f3n",
        "instavc_legacyrooms": "Sala del Legado",
        "instavc_number_hosts": "N\u00famero de hu\u00e9spedes",
        "instavc_number_participants": "N\u00famero de participantes",
        "instavc_number_room": "N\u00famero de habitaciones",
        "instavc_subscriptiom_period": "Per\u00edodo de suscripci\u00f3n",
        "instavc_sub_yearly": "Anualmente",
        "instavc_sub_monthly": "Mensual",
        "instavc_sub_weekly": "Semana",
        "instavc_setinterval": "Establecer el intervalo",
        "instavc_type": "Escriba",
        "instavc_subscription": "Suscripci\u00f3n",
        "instavc_onetime": "Una vez",
        "instavc_description": "Descripci\u00f3n",
        "instavc_planid": "PlanID",
        "instavc_orders": "Ordenes",
        "instavc_orderid": "Identificar la orden.",
        "instavc_none": "Ninguno",
        "instavc_customer": "Cliente",
        "instavc_build_anually": "Construir anualmente",
        "instavc_recurring_billing": "Facturaci\u00f3n recurrente",
        "instavc_next_due": "La pr\u00f3xima vez ser\u00e1 en",
        "instavc_invoice_details": "Detalle de las facturas",
        "instavc_recurring_payment": "Pago recurrente",
        "instavc_todays_sale": "La venta de hoy",
        "instavc_total_order": "Total de pedidos",
        "instavc_total_rooms": "Total de habitaciones",
        "instavc_current_conference": "Reuni\u00f3n actuales",
        "instavc_current_user": "Usuarios actuales",
        "instavc_live_broadcast": "Emisiones en vivo",
        "instavc_loading_chart": "Tabla de carga",
        "instavc_view": "Vea",
        "instavc_coupon_info": "Informaci\u00f3n de los cupones",
        "instavc_new_coupon": "Nuevo cup\u00f3n",
        "instavc_create_coupon": "Crear un nuevo cup\u00f3n",
        "instavc_coupon_code": "C\u00f3digo del cup\u00f3n",
        "instavc_company_code": "C\u00f3digo de la compa\u00f1\u00eda",
        "instavc_select_plan": "Seleccione los planes...",
        "instavc_discount": "Descuento",
        "instavc_activestatus": "activeStatus",
        "instavc_usage": "Uso",
        "instavc_activeusage": "activeusage",
        "instavc_singleuse": "Uso \u00fanico",
        "instavc_multiuse": "Multiuso",
        "instavc_first_page": "Primera p\u00e1gina",
        "instavc_previosu_page": "P\u00e1gina anterior",
        "instavc_next_page": "P\u00e1gina siguiente",
        "instavc_last_page": "\u00daltima p\u00e1gina",
        "instavc_plan": "Plan",
        "instavc_request_withdrawal": "Solicitud de retirada",
        "instavc_withdraw": "Retirada",
        "instavc_account": "Cuenta",
        "instavc_verification": "Verificaci\u00f3n",
        "instavc_withdraw_history": "Retirar la historia",
        "instavc_add_bank": "A\u00f1adir una cuenta bancaria para la retirada",
        "instavc_bank_description": "Protegemos su cuenta bancaria manteniendo su informaci\u00f3n financiera confidencial. Le enviaremos un correo electr\u00f3nico con el estado de su cuenta cada vez que recibamos una solicitud de retiro de esta cuenta. Para evitar fallas en el retiro y la devoluci\u00f3n del monto, el nombre de esta cuenta debe coincidir con el nombre de su cuenta bancaria. Puede llevar alg\u00fan tiempo completar la transferencia, dependiendo de las pol\u00edticas de su banco y de los horarios de vacaciones.",
        "instavc_bank_accountnumber": "N\u00famero de cuenta bancaria",
        "instavc_enter_accountnumber": "Introduzca su n\u00famero de cuenta",
        "instavc_account_name": "Nombre del titular de la cuenta",
        "instavc_per_bank_account": "Seg\u00fan la cuenta bancaria",
        "instavc_ifsc": "C\u00f3digo IFSC",
        "instavc_bankname": "Nombre del banco",
        "instavc_branchname": "Nombre de la sucursal del banco",
        "instavc_confirmdetails": "Confirma los detalles",
        "instavc_pandetails": "Por favor, proporcione los siguientes detalles seg\u00fan su PAN",
        "instavc_enter_pan": "Introduzca su nombre seg\u00fan la PAN",
        "instavc_fathername": "Nombre del padre",
        "instavc_enter_fathername": "Introduzca el nombre del padre",
        "instavc_pannumber": "N\u00famero PAN",
        "instavc_panexampe": "Ex: BNZPM2501G",
        "instavc_upload_pan_copy": "Subir copia de PAN",
        "instavc_save_details": "Guardar detalles",
        "instavc_sales": "Ventas",
        "instavc_transactionid": "Identificaci\u00f3n de la transacci\u00f3n",
        "instavc_date": "Fecha",
        "instavc_amount": "Cantidad",
        "instavc_setaccount_withdraw": "Configurar los detalles de la cuenta antes de un retiro",
        "instavc_first_withdraw": "Si es la primera vez que solicita un retiro, debe completar los datos de la cuenta y verificarla con los datos de la tarjeta PAN en las siguientes pesta\u00f1as. Una vez que haya terminado con la verificaci\u00f3n, puede proceder a solicitar el retiro de su saldo disponible.",
        "instavc_accountdetails": "Llenar los detalles de la cuenta",
        "instavc_available_balance": "Saldo disponible",
        "instavc_amount_withdraw": "Cantidad a retirar",
        "instavc_enteramount_withdraw": "Introduzca la cantidad que desea retirar",
        "instavc_select_payment_mode": "Seleccione el modo de pago",
        "instavc_paypal_payment": "Pago por Paypal",
        "instavc_bank_transaction": "Transacci\u00f3n bancaria",
        "instavc_paypalfee": "Cuota de Paypal",
        "instavc_totalamount_withdraw": "Cantidad total a retirar",
        "instavc_sendrequest": "Enviar solicitud",
        "instavc_no_request_withdraw": "No se ha presentado ninguna solicitud de retirada todav\u00eda.",
        "instavc_start_process_withdraw": "Puedes empezar a procesar la retirada desde abajo.",
        "instavc_request_withdraw_now": "Solicite la retirada ahora",
        "instavc_current_commission": "Comisi\u00f3n actual",
        "instavc_from": "De",
        "instavc_to": "a",
        "instavc_today": "Hoy",
        "instavc_last7days": "Los \u00faltimos 7 d\u00edas",
        "instavc_lastmonth": "Este mes",
        "instavc_paid_customers": "Clientes de pago",
        "instavc_share_referral_link": "Comparte tu enlace de referencia con tus amigos",
        "instavc_copylink": "Copiar enlace",
        "instavc_minimum_payout": "Pago m\u00ednimo",
        "instavc_referral_signup": "Inscripciones de referencia",
        "instavc_referral_clicks": "Clicks de referencia",
        "instavc_paid": "Pagado",
        "instavc_unpaid": "Sin pagar",
        "instavc_totalearned": "Total Ganado",
        "instavc_myreferral": "Mis referencias",
        "instavc_viewall": "Ver todo",
        "instavc_mysales": "Mis ventas",
        "instavc_sms_mfa": "SMS Estado del MFA",
        "instavc_facebook": "Facebook",
        "instavc_twitter": "Twitter",
        "instavc_linkedin": "LinkedIn",
        "instavc_referred_users": "Usuarios referidos",
        "instavc_created_date": "CreatedDate",
        "instavc_myearnings": "Mis ganancias",
        "instavc_earnings": "Ganancias",
        "instavc_commission_share": "Comisi\u00f3n Compartir",
        "instavc_commission_percentage": "Comisi\u00f3n Porcentaje",
        "instavc_coupon_offers": "Cupones\/Ofertas",
        "instavc_my_referral_account": "Mi cuenta de referencia",
        "instavc_req_withdraw": "Solicitud de retirada",
        "instavc_authenticating": "Autentificando al servidor de instaVC ...",
        "instavc_join_access_media": "Se uni\u00f3... Acceder a los dispositivos de los medios de comunicaci\u00f3n...",
        "instavc_publish_success": "Publicar con \u00e9xito",
        "instavc_recieves": "Recibe",
        "instavc_host_screenshare": "Necesitas convertirte en un anfitri\u00f3n para compartir pantalla \/ documento o",
        "instavc_request_sharescreen": "Solicitar acceso para compartir pantalla \/ documento",
        "instavc_note": "Nota",
        "instavc_filesize": "El tama\u00f1o del archivo no debe ser m\u00e1s de 10MB",
        "instavc_become_host": "Para convertirse en un anfitri\u00f3n, haga clic en los iconos del men\u00fa de desbordamiento de puntos del v\u00eddeo",
        "instavc_clicklink": "haga clic en el enlace Convi\u00e9rtase en un anfitri\u00f3n, en la ventana emergente e introduzca la contrase\u00f1a del anfitri\u00f3n y haga clic en el bot\u00f3n Convi\u00e9rtase en un anfitri\u00f3n",
        "instavc_doc_share": "Compartir documentos",
        "instavc_search_host": "Esperando al anfitri\u00f3n",
        "instavc_wait_for_host": "Por favor, esperen a que el anfitri\u00f3n comience esta reuni\u00f3n",
        "instavc_open_black_dialog": "Abrir el di\u00e1logo negro",
        "instavc_open_white_dialog": "Abrir el di\u00e1logo blanco",
        "instavc_dail_detail": "Marcar - En - Detalles",
        "instavc_request_access": "Solicitar acceso",
        "instavc_inordder_enable": "A fin de permitir",
        "instavc_host_permission": "necesitas el permiso de acceso del anfitri\u00f3n",
        "instavc_please_wait": "Por favor, espere a que",
        "instavc_host_to_permit": "(Anfitri\u00f3n) permiso para",
        "instavc_alternate_content": "Contenido alternativo",
        "instavc_meeting_room": "La Sala de Reuniones de la Universidad de California...",
        "instavc_host_left_conference": "\u00a1El anfitri\u00f3n dej\u00f3 la conferencia!",
        "instavc_conf_end": "La reuni\u00f3n terminar\u00e1 autom\u00e1ticamente en",
        "instavc_seconds": "Segundos",
        "instavc_waiting_msg": "Gracias por esperar. Empezaremos la reuni\u00f3n cuando el anfitri\u00f3n se una",
        "instavc_let_hostname": "\u00bfQuieres que el anfitri\u00f3n sepa que est\u00e1s esperando?",
        "instavc_exitconf": "Reuni\u00f3n de salida",
        "instavc_host_left": "El hu\u00e9sped izquierdo...",
        "instavc_end_host_msg": "El anfitri\u00f3n ha terminado esta reuni\u00f3n. Gracias por asistir.",
        "instavc_close_conf": "puedes volver a casa (o) cerrar la solicitud.",
        "instavc_gohome": "Ir a casa",
        "instavc_hand_raised": "Mano alzada",
        "instavc_ask": "Pregunte a",
        "instavc_protocal": "Protocolo",
        "instavc_bitrate": "Bitrate",
        "instavc_confirm_details": "Confirme sus datos",
        "instavc_enter_info_conf": "Por favor, introduzca su informaci\u00f3n antes de comenzar la reuni\u00f3n.",
        "instavc_have_acc": "Si ya tienes una cuenta...",
        "instavc_host_access": "Acceso a la sede",
        "instavc_host_pass": "Introduzca la contrase\u00f1a del anfitri\u00f3n",
        "instavc_becomehost": "Convi\u00e9rtase en anfitri\u00f3n",
        "instavc_permit_screenshare": "Permiso para compartir pantalla",
        "instavc_allow_access": "Permitir el acceso a la pizarra",
        "instavc_request_permission": "ha solicitado permisos adicionales para",
        "instavc_deny": "Denegar",
        "instavc_customeremail": "Correo electr\u00f3nico del cliente",
        "instavc_orderlink": "Enlace de la orden",
        "instavc_customerid": "Identificaci\u00f3n del cliente",
        "instavc_selectpackage": "Seleccione el paquete",
        "instavc_host_package": "Paquete basado en el anfitri\u00f3n",
        "instavc_virtualbase_mcu": "El MCU virtual basado en",
        "instavc_plans_product": "Planes\/Productos",
        "instavc_nodata": "No hay datos disponibles",
        "instavc_nodata_msg": "No hay datos disponibles para mostrarte ahora mismo.",
        "instavc_basicplan": "Plan b\u00e1sico",
        "instavc_host_participants": "1 Anfitri\u00f3n con 30 participantes",
        "instavc_link": "Enlace",
        "instavc_cancel_order": "Cancelar el pedido",
        "instavc_per": "por",
        "instavc_user_email": "Correo electr\u00f3nico del usuario",
        "instavc_owner": "Propietario",
        "instavc_user": "Usuario",
        "instavc_default_users": "Ofertas por defecto",
        "instavc_system_settings": "Ajustes del sistema",
        "instavc_expires": "Expira",
        "instavc_start_VC": "INICIAR LA VIDEOCONFERENCIA",
        "instavc_start_AC": "Iniciar la audioconferencia",
        "instavc_date_error": "La fecha y la hora de inicio deben ser mayores que la hora actual",
        "instavc_browser_update": "Se requiere una actualizaci\u00f3n del navegador",
        "instavc_browser_note": "Nos dimos cuenta de que est\u00e1 usando una versi\u00f3n m\u00e1s antigua de BROWSERNAME que ahora no tiene soporte.",
        "instavc_browser_msg": "Instavc.com requiere una versi\u00f3n actualizada del NOMBRE DE NAVEGADOR o cualquier otro navegador moderno.",
        "instavc_browser_upgrade": "Por favor, actualice a cualquiera de las \u00faltimas versiones de estos navegadores para una mejor experiencia.",
        "instavc_googlechrome": "Google Chrome",
        "instavc_gc_version74": "Versi\u00f3n 74",
        "instavc_mfirefox": "Mozilla Firefox",
        "instavc_mff_version": "Versi\u00f3n 66",
        "instavc_opera": "Opera",
        "instavc_opera_version": "Versi\u00f3n 60.0+",
        "instavc_microsoftedge": "Microsoft Edge",
        "instavc_ms_version": "Versi\u00f3n 44+",
        "instavc_safari": "Apple Safari",
        "instavc_safari_version": "Versi\u00f3n 12.1",
        "instavc_contact_msg": "\u00bfYa est\u00e1 actualizado, pero sigue teniendo problemas? Cont\u00e1ctenos en",
        "instavc_camera_busy": "\u00a1La c\u00e1mara parece estar ocupada!",
        "instavc_close_camera": "Por favor, cierra toda la aplicaci\u00f3n e int\u00e9ntalo de nuevo.",
        "instavc_resolution": "\u00a1Resoluci\u00f3n no apoyada!",
        "instavc_camera_notsupport": "Tu c\u00e1mara doesnb\ufffd soporta esta resoluci\u00f3n.",
        "instavc_camera_reconfig": "Reconfigure su configuraci\u00f3n con una resoluci\u00f3n m\u00e1s baja e int\u00e9ntelo de nuevo.",
        "instavc_change_resolution": "Cambiar la resoluci\u00f3n",
        "instavc_confirmation": "Confirmaci\u00f3n",
        "instavc_yes": "S\u00ed",
        "instavc_delete_room": "\u00bfSeguro que quieres borrar la habitaci\u00f3n?",
        "instavc_delete_license": "\u00bfSeguro que quieres borrar la licencia?",
        "instavc_help": "Estamos aqu\u00ed para ayudarte",
        "instavc_browse_questions": "Navegue por las preguntas m\u00e1s frecuentes.",
        "instavc_os": "Sistema operativo",
        "instavc_macos": "La versi\u00f3n de Mac OS X:",
        "instavc_chrome_version": "Versi\u00f3n cromada:",
        "instavc_private_browsing": "\u00bfExamen privado?",
        "instavc_faq_no": "No",
        "instavc_display_resolution": "instavc_display_resolution",
        "instavc_aspect_ratio": "Mostrar la relaci\u00f3n de aspecto",
        "instavc_sys_hasspeakers": "\u00bfEl sistema tiene altavoces?",
        "instavc_found_speakers": "Encontrado Dispositivos de altavoz : 2",
        "instavc_speakers1": "Altavoz 1",
        "instavc_speakers2": "Altavoz 2",
        "instavc_sys_have_microphone": "\u00bfEl sistema tiene micr\u00f3fono?",
        "instavc_found_microphone": "Encontrados Dispositivos de micr\u00f3fono: 2",
        "instavc_microphone1": "Micr\u00f3fono 1",
        "instavc_microphone2": "Micr\u00f3fono 2",
        "instavc_has_webcam": "\u00bfEl sistema tiene c\u00e1mara web?",
        "instavc_found_speakers1": "Encontrado Dispositivos de altavoz : 1",
        "instavc_camera1": "C\u00e1mara 1",
        "instavc_webcam_permission": "\u00bfEl sitio web tiene permisos de c\u00e1mara web?",
        "instavc_microphone_permission": "\u00bfEl sitio web tiene permisos de micr\u00f3fono?",
        "instavc_browser_allows": "El navegador permite obtener los medios de comunicaci\u00f3n de usuario en esta p\u00e1gina?",
        "instavc_change_output_device": "\u00bfPuedes cambiar los dispositivos de audio de salida?",
        "instavc_change_camera_resolutions": "\u00bfPuedes cambiar la resoluci\u00f3n de la c\u00e1mara sin hacer una nueva solicitud de getUserMedia?",
        "instavc_browser_support_webrtc": "\u00bfEl navegador soporta WebRTC (ya sea 1.0 o 1.1)?",
        "instavc_browser_support_ortc": "\u00bfEl navegador soporta ORTC (WebRTC 1.1)?",
        "instavc_initial_setup": "\u00a1Por favor, espere mientras se procesa su configuraci\u00f3n inicial!",
        "instavc_close_browser": "No cierre su navegador ni utilice el bot\u00f3n de regreso en este momento.",
        "instavc_whoops": "Lo siento !",
        "instavc_nonet_msg": "No se encontr\u00f3 ninguna conexi\u00f3n a Internet. Por favor, compruebe su conexi\u00f3n o int\u00e9ntelo de nuevo.",
        "instavc_nosearch": "No se encontraron resultados de la b\u00fasqueda",
        "instavc_noserchmsg": "No encontramos ning\u00fan art\u00edculo que coincida con su b\u00fasqueda.",
        "instavc_nosearch_filter": "Intenta ajustar tu b\u00fasqueda o filtro para encontrar lo que est\u00e1s buscando.",
        "instavc_gotohoem_page": "Ir a la p\u00e1gina de inicio",
        "instavc_pageloading": "Carga de la p\u00e1gina... Por favor, espere!",
        "instavc_process": "Procesando...",
        "instavc_session_timeout": "Tiempo de espera de la sesi\u00f3n",
        "instavc_session_inactivity": "Su sesi\u00f3n se est\u00e1 acabando debido a la inactividad.",
        "instavc_session_msg": "Por favor, elija permanecer registrado.",
        "instavc_session_reconnect": "Vuelva a conectar",
        "instavc_site_notreached": "\u00a1No se puede acceder a este sitio!",
        "instavc_site_webaddress": "Aseg\u00farate de que tienes la direcci\u00f3n web correcta",
        "instavc_check_internet": "Revise su red",
        "instavc_search_url": "Busca la URL en otro navegador",
        "instavc_goto": "Ve a",
        "instavc_request_for_access": "Solicitud de acceso",
        "instavc_start_collabrate": "para empezar a colaborar en la pizarra",
        "instavc_large_conference_mode": "Su modo de \"Gran Reuni\u00f3n\", no puedes hablar o ver a otros hasta que el anfitri\u00f3n te d\u00e9 el permiso",
        "instavc_allow_participants": "Su modo de \"Gran Reuni\u00f3n\", tienes que permitir manualmente que los participantes hablen",
        "instavc_buy_premium": "Comprar Premium para desbloquear estas caracter\u00edsticas",
        "instavc_alert_tryagain": "Algo sali\u00f3 mal. Por favor, int\u00e9ntelo de nuevo.",
        "instavc_enter_name": "Introduzca su nombre...",
        "instavc_confirm": "Confirme",
        "instavc_ok": "Ok",
        "instavc_askquestion": "Haz una pregunta...",
        "instavc_recieve": "Reciba",
        "instavc_delay": "Retraso",
        "instavc_audio_opus": "Audio\/opus",
        "instavc_video_vp8": "video\/vp8",
        "instavc_browser_not_support_incall": "\u00a1Tu navegador no soporta el cambio de resoluci\u00f3n durante la llamada!",
        "instavc_failed_toPublish": "No public\u00f3 el video",
        "instavc_publish_fail": "Publicar fallido",
        "instavc_video_cant_publish": "El video no puede ser publicado, debido al l\u00edmite de alcance de los usuarios",
        "instavc_failed_joinConference": "No se uni\u00f3 a la reuni\u00f3n",
        "instavc_mute_audio": "No se puede silenciar o anular el silencio si el audio remoto est\u00e1 silenciado.",
        "instavc_remote_audio_muted": "Audio remoto silenciado!",
        "instavc_enter_conferenceid": "Ingrese su ID de la reuni\u00f3n",
        "instavc_share_screen": "Compartir pantalla",
        "instavc_sharing_your_screen": "Compartir tu pantalla...",
        "instavc_stop": "Det\u00e9ngase",
        "instavc_back": "Volver",
        "instavc_choose_plan": "Elija su plan de la siguiente lista",
        "instavc_have_promotionalcode": "\u00bfTiene un C\u00f3digo de Promoci\u00f3n \/ C\u00f3digo de Socio?",
        "instavc_contact_pricing": "Contacte con nosotros para conocer los precios",
        "instavc_payment_failed": "El pago fall\u00f3",
        "instavc_payment_error": "Desafortunadamente, se produjo un error al manejar su pago.",
        "instavc_contact_support": "Por favor, contacte con nuestro equipo de apoyo si necesita ayuda.",
        "instavc_tryagain": "Int\u00e9ntalo de nuevo.",
        "instavc_payment_success": "El pago ha sido exitoso",
        "instavc_thanks_for_payment": "\u00a1Gracias por su pago!",
        "instavc_payment_processed": "Su pago ha sido procesado con \u00e9xito. Un recibo de pago automatizado",
        "instavc_mail_sent": "ser\u00e1 enviada a su direcci\u00f3n de correo electr\u00f3nico registrada en breve",
        "instavc_goto_homepage": "Volver a la p\u00e1gina de inicio",
        "instavc_want_toend_call": "\u00bfQuieres terminar la llamada?",
        "instavc_exit_window": "Cerrando esta ventana saldr\u00e1 de su llamada. \u00bfEst\u00e1s seguro de que quieres terminar esta llamada?",
        "instavc_create_room": "Crear una habitaci\u00f3n",
        "instavc_userlimit": "L\u00edmite de usuario",
        "instavc_edit_room": "Sala de edici\u00f3n",
        "instavc_update": "Actualizaci\u00f3n",
        "instavc_manage_license": "Administrar las licencias",
        "instavc_package": "Paquete",
        "instavc_no_of_rooms": "No. de habitaciones",
        "instavc_no_of_users": "N\u00famero de usuarios",
        "instavc_purchasedOn": "Comprado en",
        "instavc_expiredon": "Expir\u00f3 el",
        "instavc_or": "O",
        "instavc_otherhost_email": "Si quieres que cualquier usuario sea el anfitri\u00f3n, introduce su direcci\u00f3n de correo electr\u00f3nico.",
        "instavc_set_host_password": "Establecer la contrase\u00f1a del anfitri\u00f3n",
        "instavc_host_password": "Contrase\u00f1a del anfitri\u00f3n",
        "instavc_access_room": "\u00bfQui\u00e9n m\u00e1s puede acceder a esta sala?",
        "instavc_select_user_host": "Seleccione cualquier usuario de la lista para convertirlo en anfitri\u00f3n.",
        "instavc_prologin": "InstaVC Pro Login",
        "instavc_start": "Comienza",
        "instavc_enter_website": "Por favor, introduzca el nombre del sitio web",
        "instavc_enter_valid_email": "Por favor, introduzca el correo electr\u00f3nico v\u00e1lido",
        "instavc_enter_valid_phone": "Por favor, introduzca el n\u00famero de tel\u00e9fono v\u00e1lido",
        "instavc_field_required": "Este campo es obligatorio",
        "instavc_invitedby": "Invitado por",
        "instavc_storage": "Almacenamiento",
        "affiliate_approve": "Aprobado",
        "affiliate_pending": "Pendiente",
        "affiliate_rejected": "Rechazado",
        "affiliate_user_status": "Estado del usuario",
        "commission_other": "Otras comisiones",
        "add_commission": "A\u00f1adir las comisiones",
        "delete_commission": "\u00bfQuieres eliminar la comisi\u00f3n",
        "update_commission": "Comisi\u00f3n de actualizaci\u00f3n",
        "name": "Nombre",
        "offer_value": "Ofrecer valor",
        "cancel": "Cancelar",
        "add": "A\u00f1ade",
        "update": "Actualizaci\u00f3n",
        "affiliates": "Afiliados",
        "status": "Estado",
        "status_change_message": "\u00bfLe gustar\u00eda cambiar el estatus?",
        "approve": "Aprobar",
        "reject": "Rechazar",
        "commissions": "Comisiones",
        "withdraw_data": "Retirar datos",
        "withdraw_amount": "Cantidad a retirar",
        "payment_method": "M\u00e9todo de pago",
        "payment_fee": "Cuota de pago",
        "total_amount_withdraw": "Cantidad total a retirar",
        "total_earnings": "Ganancias totales",
        "pending_amount": "Cantidad pendiente",
        "paid_amount": "Cantidad pagada",
        "unpaid_amount": "Cantidad no pagada",
        "requested_withdraw": "Cantidad de retiro solicitada",
        "english": "Ingl\u00e9s",
        "french": "Franc\u00e9s",
        "room": "Sala",
        "create": "CREAR",
        "repeat": "Repita",
        "daily": "Diario",
        "repeat_every": "Repita cada",
        "repeat_on": "Repita en",
        "repeat_by": "Repetir por",
        "ends": "Finaliza",
        "after": "Despu\u00e9s",
        "occurences": "ocurrencias",
        "on": "en",
        "summary": "Resumen",
        "repeats": "Repite",
        "withdraws": "Retiradas",
        "every": "Cada",
        "days": "d\u00edas",
        "weeks": "semanas",
        "months": "Mes",
        "onday": "en el d\u00eda",
        "years": "a\u00f1os",
        "times": "veces",
        "until": "hasta",
        "past_meeting_list": "Lista de reuniones completadas",
        "organised_by": "Organizado por",
        "delete": "Eliminar",
        "join": "Participar",
        "to": "a",
        "enter_password": "Introduzca la contrase\u00f1a",
        "submit": "Env\u00edar",
        "enter_passwordmessage": "Por favor, introduzca la contrase\u00f1a",
        "delete_message": "\u00bfQuieres borrar",
        "meeting_permanent": "reuni\u00f3n permanente?",
        "day": "D\u00eda",
        "password_message": "la contrase\u00f1a es incorrecta",
        "timezone": "Zona horaria",
        "by": "Por",
        "weekdays": "[\"DOM\", \"LUN\", \"MAR\", \"MIE\", \"JUE\", \"VIE\", \"SAB\"]",
        "allweek": "[\"Domingo\", \"Lunes\", \"Martes\", \"Mi\u00e9rcoles\", \"Jueves\", \"Viernes\", \"S\u00e1bado\"]",
        "day_month": "d\u00eda del mes",
        "day_week": "d\u00eda de la semana",
        "week": "Semana",
        "month": "Mes",
        "year": "A\u00f1o",
        "enterpassword": "Por favor, introduzca su nueva contrase\u00f1a y confirme la contrase\u00f1a",
        "passwordmessage": "La contrase\u00f1a y la confirmaci\u00f3n de la contrase\u00f1a deben ser iguales.",
        "passwordupdate": "Contraseña actualizada correctamente, inicie sesión con su nueva contraseña",
        "numeric": "[\"primero\", \"segundo\", \"tercero\", \"cuarto\", \"quinto\"]",
        "verify_email": "Verifique su correo electr\u00f3nico",
        "confirm_code": "C\u00f3digo de confirmaci\u00f3n",
        "verify_msg": "Por favor revise su correo para el código.",
        "verify": "Verifique",
        "not_recieved": "\u00bfNo se ha recibido?",
        "resend_otp": "Reenviar OTP",
        "start_browsing": "Comenzar a navegar",
        "become_affiliate": "Af\u00edliese",
        "affiliate": "Afiliado",
        "verify_mobile": "Verifique su m\u00f3vil",
        "instavc_allow_DocumentAccess": "Documento de Permiso-Compartir",
        "camera_not_access": "La c\u00e1mara no es accesible debido a una de las siguientes razones:",
        "camera_not_access_reason1 ": "Es posible que la configuraci\u00f3n de la resoluci\u00f3n no sea compatible con esta pantalla. Vuelva a configurar la configuraci\u00f3n con una resoluci\u00f3n m\u00e1s baja e int\u00e9ntelo de nuevo.",
        "camera_not_access_reason2": "Su c\u00e1mara est\u00e1 siendo utilizada por diferentes aplicaciones, Por favor, cierre todas las aplicaciones e intente de nuevo haciendo clic en el enlace.",
        "asgnQue_Assign_Question ": "Asignar el cuestionario",
        "asgnQue_assigned_question": "Cuestionario asignado",
        "quslsit_assig_que_list\t": "Lista del cuestionario asignado",
        "quslsit_question_lists\t": "Listas del Cuestionario",
        "quslsit_search\t": "Busca...",
        "quslsit_add_new_question ": "A\u00f1adir nuevo cuestionario",
        "quslsit_no_question_assign ": "\u00a1No se ha asignado ning\u00fan cuestionario todav\u00eda!",
        "quslsit_assign-new_ques": "Asigne un nuevo cuestionario para hacer clic en el bot\u00f3n de arriba Asignar cuestionario",
        "mdl_quesnr_questionnaire": "Cuestionario",
        "mdl_quesnr_select_question": "Seleccione el cuestionario",
        "mdl_quesnr_duration": "Duraci\u00f3n",
        "mdl_quesnr_time_limit": "L\u00edmite de tiempo",
        "mdl_quesnr_show_results": "Mostrar resultados",
        "mdl_quesnr_cancel": "Cancelar",
        "mdl_quesnr_save": "Guardar",
        "viewAsg_question_details": "Detalles del cuestionario",
        "viewAsg_content_group": "Grupo de contenido",
        "viewAsg_questions": "Preguntas",
        "viewAsg_question_list": "Lista del cuestionario",
        "viewAsg_list_assigned_ques": "Lista de preguntas asignadas:",
        "viewAsg_mins": "mins",
        "viewAsg_marks": "Marcas",
        "asgQues_5_Marks": "( 5 puntoss )",
        "asgQues_answers": "Respuestas",
        "asgQues_no_questions": "\u00a1No hay preguntas!",
        "grdSet_GPA_calculation": "C\u00e1lculo del PAM a partir del porcentaje",
        "grdSet_GPA_cal_msg": "El porcentaje es del 100%, que no es m\u00e1s que 100 puntos. As\u00ed que 25 puntos en el porcentaje es equivalente a 1 punto en el sistema GPA. La f\u00f3rmula de porcentaje para el GPA es simplemente dividir tu porcentaje por 100 y multiplicarlo por 4. Si tu porcentaje es 75% entonces tu calificaci\u00f3n ser\u00eda (75\/100)*4 = 3.",
        "grdSet_grade_point": "Si la puntuaci\u00f3n es",
        "mngGrd_grades": "Grados",
        "mngGrd_cancel": "Cancelar",
        "mngGrd_save": "Guardar",
        "mngGrd_modify": "Modificar",
        "mngGrd_save_grades": "Salvar los grados",
        "mngGrd_modify_grades": "Modificar los grados",
        "mngGrd_grade_info": "Informaci\u00f3n sobre el grado",
        "admnAns_answer_list": "Lista de respuestas",
        "admnAns_answer_list_for_question": "A continuaci\u00f3n encontrar\u00e1 la lista de respuestas al cuestionario completo incluido en los documentos para este grupo.",
        "admnAns_answered": "Respondido",
        "admnAns_correct_answer": "Respuesta correcta",
        "prtcpt_certification": "Certificaciones:",
        "prtcpt_other_content_group": "En otros grupos de contenido",
        "prtcpt_sales": "Ventas",
        "prtcpt_marketing": "Marketing",
        "prtcpt_human_resource": "Recursos Humanos",
        "mngPrtcpt_participants": "participantes",
        "mngPrtcpt_add_participants": "A\u00f1adir participantes",
        "mngPrtcpt_participants_list": "Listas de participantes",
        "mngPrtcpt_import_participants": "Importar Participantes",
        "mngPrtcpt_download_sample": "Descargar muestra",
        "mngPrtcpt_no_participants_added": "\u00a1No se ha a\u00f1adido ning\u00fan participante todav\u00eda!",
        "mngPrtcpt_add_new_participant": "Agregar un nuevo participante, para agregarlo haz clic en el bot\u00f3n de arriba Agregar Participante",
        "addPrtcpt_select_participants": "Seleccionar los participantes...",
        "addPrtcpt_model_add_participants": "A\u00f1adir participantes",
        "prtcptGrde_participant_grade": "Detalles del grado del participante",
        "prtcptGrde_grade_participants_list": "Lista de participantes",
        "mngQuesnr_questionnaire_list": "Lista del cuestionario",
        "mngQuesnr_create_questionnaire": "Crear el cuestionario",
        "mngQuesnr_search": "Busca...",
        "mngQues_question_details ": "Detalles del cuestionario",
        "mngQues_add_questions": "A\u00f1adir preguntas",
        "mngQues_question_list": "Lista del cuestionario",
        "mngQues_questions": "Preguntas",
        "mngQues_list_assign_question": "Lista de preguntas asignadas:",
        "mngQues_no_question_created": "\u00a1No se ha creado ninguna pregunta todav\u00eda!",
        "mngQues_create_new_question": "Crear una nueva pregunta, para crearla haga clic en el bot\u00f3n de arriba Crear pregunta",
        "mdlGrp_questionnaire": "Cuestionario",
        "mdlGrp_questionnaire_name": "Nombre del cuestionario",
        "mdlGrp_description": "Descripci\u00f3n",
        "mdlGrp_cancel": "Cancelar",
        "mdlGrp_update": "Actualizaci\u00f3n",
        "mdlGrp_create": "CREAR",
        "mdlCrtQues_update_question": "Pregunta de actualizaci\u00f3n",
        "mdlCrtQues_create_questionnaire": "Crear el cuestionario",
        "mdlCrtQues_enter_question_here": "Escriba su pregunta aqu\u00ed",
        "mdlCrtQues_marks": "Marcas",
        "mdlCrtQues_question_type": "Tipo de pregunta",
        "mdlCrtQues_sel_question_type": "Seleccione el tipo de pregunta",
        "mdlCrtQues_single_sel_multiple_choice": "Selecci\u00f3n simple Selecci\u00f3n m\u00faltiple",
        "mdlCrtQues_multiselect_multiple_choice": "Multiselecci\u00f3n de elecci\u00f3n m\u00faltiple",
        "mdlCrtQues_ture_false": "Verdadero\/Falso",
        "mdlCrtQues_enter_answer_choice": "Introduzca las opciones de respuesta, y marque qu\u00e9 respuesta es la correcta",
        "mdlCrtQues_add_another_choice": "+ A\u00f1adir otra opci\u00f3n",
        "mdlCrtQues_cancel": "Cancelar",
        "viewQues_questionnaire_details": "Detalles del cuestionario",
        "viewQues_add_questions": "A\u00f1adir preguntas",
        "viewQues_question_list": "Lista del cuestionario",
        "viewQues_question": "Preguntas",
        "viewQues_list_assign_questions": "Lista de preguntas asignadas:",
        "viewQues_no_question_created": "\u00a1No se ha creado ninguna pregunta todav\u00eda!",
        "viewQues_create_new_question": "Crear una nueva pregunta, para crearla haga clic en el bot\u00f3n de arriba Crear pregunta",
        "crteContent_create_new_content": "Crear un nuevo contenido",
        "crteContent_update_content": "Actualizar el contenido",
        "crteContent_update": "Actualizaci\u00f3n",
        "crteContent_save_content": "Guardar el contenido",
        "crteContent_save": "Guardar",
        "crteContent_content_group": "Grupo de contenido",
        "crteContent_content_list": "Listas de contenidos",
        "crteContent_edit_content": "Editar el contenido",
        "crteContent_create_content": "Crear el contenido",
        "crteContent_create_your_content_like": "Crea tu contenido, agregando medios como, im\u00e1genes, archivos de video, archivos de audio y documentos.",
        "crteContent_upload_document": "Subir documentos",
        "crteContent_upload_media": "Subir los medios de comunicaci\u00f3n",
        "crteContent_document": "Documentos",
        "crteContent_media_files": "Archivos de medios de comunicaci\u00f3n",
        "viewCon_content_view": "Vista del contenido",
        "viewCon_content_group": "Grupo de contenido",
        "viewCon_content_list": "Listas de contenidos",
        "viewCon_View_content": "Ver contenido",
        "viewCon_enable": "Habilitar:",
        "viewCon_document": "Documentos",
        "viewCon_media_files": "Archivos de medios de comunicaci\u00f3n",
        "viewCon_content": "Contenido",
        "conRow_enable": "Habilitar:",
        "conRow_by": "Por:",
        "conRow_content_group": "Grupo de contenido",
        "grpRow_enable": "Habilitar:",
        "grpRow_by": "Por:",
        "hozConNav_content": "Contenido",
        "hozConNav_participants": "participantes",
        "hozConNav_grade": "Grados",
        "hozConNav_assign_questionnaire": "Asignar el cuestionario",
        "mngCon_create_new_content": "Crear un nuevo contenido, para crearlo haga clic en el bot\u00f3n de arriba \"Create Content\".",
        "mngCon_all": "Todos",
        "mngCon_content_list": "Listas de contenidos",
        "mngCon_no_content_created": "\u00a1No se ha creado ning\u00fan contenido todav\u00eda!",
        "mdlAccRight_share_access": "Compartir el acceso con otros instructores",
        "mdlAccRight_type_id_add": "Escriba el ID del correo electr\u00f3nico y a\u00f1ada",
        "mdlAccRight_no_instructor_added": "\u00a1No se ha a\u00f1adido ning\u00fan instructor todav\u00eda!.",
        "mdlAccRight_add_people_from_addressbok": "A\u00f1ade gente de tu libreta de direcciones, o escribiendo la direcci\u00f3n de correo electr\u00f3nico.",
        "mdlAccRight_addressbook": "Libreta de direcciones",
        "mdlAccRight_search": "Busca...",
        "mdlAccRight_contacts": "Contactos",
        "mdlAccRight_add_contacts": "A\u00f1adir contactos al Grupo de Contenido, desde los contactos de su libreta de direcciones",
        "mdlAccRight_no_contacts_added": "\u00a1No se ha a\u00f1adido ning\u00fan contacto todav\u00eda!.",
        "mdlAccRight_cancel": "Cancelar",
        "mdlConfDel_confirmation": "Confirmaci\u00f3n",
        "mdlConfDel_are_you_sure_delete": "\u00bfEst\u00e1s seguro de que quieres borrar esto",
        "mdlConfDel_cancel": "Cancelar",
        "mdlConfDel_delete": "Eliminar",
        "mdlCrtConGrp_alert_enter_group_title": "Por favor, introduzca el t\u00edtulo del grupo",
        "mdlCrtConGrp_alert_enter_group_description": "Por favor, introduzca la descripci\u00f3n del grupo",
        "mdlCrtConGrp_content_group": "Grupo de contenido",
        "mdlCrtConGrp_group_name": "Nombre del grupo",
        "mdlCrtConGrp_description": "Descripci\u00f3n",
        "mdlCrtConGrp_cancel": "Cancelar",
        "mdlCrtConGrp_save": "Guardar",
        "mdlUpldoc_alert_enter_title_doc": "Por favor, introduzca el t\u00edtulo del documento",
        "mdlUpldoc_upload_document": "Subir documentos",
        "mdlUpldoc_document_title": "T\u00edtulo del documento",
        "mdlUpldoc_upload_doc_from_system": "Sube el documento de tu sistema, soporta .pdf, excel, documento word.",
        "mdlUpldoc_upload_doc": "Subir el documento",
        "mdlUpldoc_added_doc": "Documentos a\u00f1adidos",
        "mdlUpldoc_cancel": "Cancelar",
        "mdlUpldoc_save": "Guardar",
        "mdlUplMedia_alert_enter_title_media": "Por favor, introduzca el t\u00edtulo de los medios de comunicaci\u00f3n",
        "mdlUplMedia_alert_enter_title_url": "Por favor, introduzca el t\u00edtulo y la URL",
        "mdlUplMedia_alert_enter_youtube_vimeo_url": "Por favor ingrese válida y URL (Youtube )",
        "mdlUplMedia_upload_media": "Subir los medios de comunicaci\u00f3n",
        "mdlUplMedia_upload_url": "Subir URL",
        "mdlUplMedia_Upload_media_system": "Sube los medios de tu sistema, soporta .jpg, .png, .jpeg, .mp3, .mp4",
        "mdlUplMedia_add_media_url": "A\u00f1adir la direcci\u00f3n URL de los medios de comunicaci\u00f3n para adjuntar un medio de comunicaci\u00f3n",
        "mdlUplMedia_upload_media_url": "Subir la URL de los medios de comunicaci\u00f3n",
        "mdlUplMedia_upload_youtube_url": "Nota: Subir las URL de YouTube",
        "mdlUplMedia_Add_media_file": "Archivos de medios de comunicaci\u00f3n a\u00f1adidos",
        "mdlUplMedia_cancel": "Cancelar",
        "mdlUplMedia_save": "Guardar",
        "mngContent_content": "Contenido",
        "mngContent_participants": "Paricipants",
        "mngContent_grade_settings": "Ajustes del grado",
        "mngContent_assign_questonnaire": "Asignar el cuestionario",
        "content_all_content_group": "Todos los grupos de contenido",
        "content_create_new_group": "Crear un nuevo Grupo, para crearlo haga clic en el bot\u00f3n de arriba Crear Grupo",
        "content_search": "Busca...",
        "content_no_group_created": "\u00a1No se ha creado ning\u00fan grupo todav\u00eda!",
        "conList_questionnaire": "Cuestionario",
        "conList_view": "Vea",
        "conList_all_group": "Todos los grupos",
        "conList_content_list": "Listas de contenidos",
        "conList_no_content_created": "\u00a1No se ha creado ning\u00fan contenido todav\u00eda!",
        "grpList_all_content_group": "Todos los grupos de contenido",
        "grpList_no_group_created": "\u00a1No se ha creado ning\u00fan grupo todav\u00eda!",
        "questnrList_questionnaire": "Cuestionario",
        "questnrList_questionnaire_list": "Lista del cuestionario",
        "questnrList_no_question_assign": "\u00a1No se ha asignado ning\u00fan cuestionario todav\u00eda!",
        "questnrList_marksz": "Marcas",
        "questnrList_mins": "mins",
        "queStepper_can't_skip": "No puedes saltarte un paso que no sea opcional.",
        "queStepper_marks": "Marcas",
        "queStepper_back": "Volver",
        "queStepper_next": "Siguiente",
        "queStepper_finish": "Finalizar",
        "queStepper_submit_next": "Enviar y Siguiente",
        "showCont_content_list": "Listas de contenidos",
        "showCont_document": "Documentos",
        "showCont_media_file": "Archivos de medios de comunicaci\u00f3n",
        "showCont_previous": "Anterior",
        "showCont_next": "Siguiente",
        "showCont_have_completed_topic": "He completado los temas de este contenido",
        "showMedia_back": "Volver",
        "showMedia_page": "P\u00e1gina",
        "showMedia_of": "de",
        "showMedia_next": "Siguiente",
        "showMedia_previous": "Anterior",
        "showQues_questionnaire_list": "Lista del cuestionario",
        "showQues_question": "Preguntas",
        "showQues_marks": "Marcas",
        "showQues_min_left": "min. a la izquierda",
        "showQues_completed_test": "Usted ha completado la prueba en este cuestionario.",
        "showQues_go_back_content_group": "Volver al grupo de contenido",
        "showQues_list_assign_question": "Lista de preguntas asignadas:",
        "viewUserScore_view_score": "Ver puntuaci\u00f3n",
        "viewUserScore_question_list": "Lista del cuestionario",
        "viewUserScore_question": "Preguntas",
        "viewUserScore_total_marks": "Marcas totales:",
        "viewUserScore_marks": "Marcas",
        "viewUserScore_scored_marks": "Marcado:",
        "viewUserScore_GPA_score": "Tu puntuaci\u00f3n en el GPA:",
        "viewUserScore_answer_list": "Lista de respuestas",
        "viewUserScore_below_list_of_answer": "A continuaci\u00f3n encontrar\u00e1 la lista de respuestas al cuestionario completo incluido en los documentos para este grupo.",
        "viewUserScore_answered": "Respondido",
        "viewUserScore_correct_answer": "Respuesta correcta",
        "confCancle_confirmation": "Confirmaci\u00f3n",
        "confCancle_are_you_sure": "\u00bfEst\u00e1 seguro?",
        "confCancle_cancel": "Cancelar",
        "confCancle_delete_user": "Borrar Usuario",
        "confSche_are_you_sure_delete": "\u00bfEst\u00e1s seguro de que quieres borrar este horario?",
        "confSche_no": "No",
        "pastMtnItem_rn": "RN",
        "pastMtnItem_room_name": "Nombre de la habitaci\u00f3n",
        "pastMtnItem_webinar_list": "Lista de webinars",
        "pastMtnItem_list_webinar": "A continuaci\u00f3n se encuentra la lista de seminarios web.",
        "pastMtnItem_today": "Hoy",
        "pastMtnItem_webinar_title_falls": "El t\u00edtulo del webinar cae aqu\u00ed",
        "pastMtnItem_view": "Vea",
        "pastMtnItem_upcoming": "Pr\u00f3ximamente",
        "schedules_upload_custom_banner": "Suba un banner personalizado para su evento de Webinar.",
        "scheView_edit_content": "Editar el contenido",
        "scheView_scheduled_by": "Programado por:",
        "scheView_delete": "Eliminar",
        "scheView_download_calander": "Descargar el calandria",
        "scheView_invite_participants": "Invitar a los participantes",
        "scheView_start_conference": "Iniciar la reuni\u00f3n",
        "scheView_event_details": "Detalles del evento",
        "scheView_room_name": "Nombre de la habitaci\u00f3n",
        "scheView_duration": "Duraci\u00f3n",
        "scheView_minutes": "Actas",
        "scheView_conference_password": "Contrase\u00f1a de la reuni\u00f3n",
        "scheView_settings": "Ajustes",
        "scheView_webinar_event": "Evento del Webinar",
        "scheView_list_webinar": "Inscriba este evento en instavc.com\/webinar, y acepte las inscripciones.",
        "scheView_guest_login_anonymous": "El hu\u00e9sped puede entrar como An\u00f3nimo",
        "scheView_allow_invities": "Permitir s\u00f3lo a los invitados unirse a la reuni\u00f3n",
        "scheView_enable_webinar_chat": "Habilitar el chat del seminario web",
        "scheView_enable_hand_rise": "Habilitar Levantamiento de Mano en el webinar",
        "scheView_list_of_people_invited": "A continuaci\u00f3n se encuentra la lista de personas invitadas",
        "scheView_alert_err_to_join": "Error al unirse",
        "webnrDtls_room_name": "Nombre de la habitaci\u00f3n",
        "webnrDtls_upcoming_webinar_room": "A continuaci\u00f3n se muestra el pr\u00f3ximo seminario web para esta sala.",
        "webnrDtls_webinar_details": "Detalles del seminario web",
        "webnrDtls_webinar_list": "Lista de webinars",
        "webnrDtls_webinar_title": "T\u00edtulo del seminario web",
        "webnrDtls_webinar_title_falls": "El t\u00edtulo del webinar cae aqu\u00ed",
        "webnrDtls_get_more_joining": "Consiga m\u00e1s al unirse a este seminario web.",
        "webnrDtls_join_webinar": "\u00danete al Webinar",
        "webnrList_room_name": "Nombre de la habitaci\u00f3n",
        "webnrList_webinar_list": "Lista de webinars",
        "webnrList_below_list_of_webinar": "A continuaci\u00f3n se encuentra la lista de seminarios web.",
        "webnrList_today": "Hoy",
        "webnrList_webinar_title_falls": "El t\u00edtulo del webinar cae aqu\u00ed",
        "webnrList_view": "Vea",
        "webnrList_upcoming": "Pr\u00f3ximamente",
        "addBook_address_book": "Libreta de direcciones",
        "addBook_contact": "Contactos",
        "addBook_add_contact_to_meeting": "A\u00f1ade contactos a la reuni\u00f3n desde los contactos de tu libreta de direcciones",
        "meetingList_past_meetings": "Reuniones completadas",
        "meetingList_past_meetings_list": "Lista de reuniones completadas",
        "meetingList_participants": "participantes",
        "meetingList_room_name": "Nombre de la habitaci\u00f3n:",
        "meetingList_view_content": "Ver contenido",
        "meetingList_recording": "Grabaciones",
        "meetingList_view": "Vea",
        "scheInvt_err_invalid_id": "Identificador de correo electr\u00f3nico inv\u00e1lido",
        "scheInvt_invite_participants": "Invitar a los participantes",
        "scheInvt_address_book": "Libreta de direcciones",
        "scheInvt_invite_people_to_meeting": "Invite a personas a esta reuni\u00f3n. Agregue personas de su libreta de direcciones o ingrese la direcci\u00f3n de correo electr\u00f3nico a continuaci\u00f3n y haga clic en \"Agregar\".",
        "scheInvt_start": "Comienza @",
        "scheInvt_below_list_of_answer": "A continuaci\u00f3n encontrar\u00e1 la lista de respuestas al cuestionario completo incluido en los documentos para este grupo.",
        "scheInvt_room_name": "Nombre de la habitaci\u00f3n:",
        "scheInvt_host": "Anfitri\u00f3n:",
        "scheInvt_minutes": "Actas",
        "scheInvt_add": "A\u00f1ade",
        "scheInvt_no_participants_added": "No se han a\u00f1adido participantes",
        "scheInvt_add_ppl_to_meetings": "Agregue personas a esta reuni\u00f3n como asistentes desde su libreta de direcciones, o agr\u00e9guelas arriba y haga clic en \"Agregar\".",
        "scheInvt_cancel": "Cancelar",
        "scheInvt_send_invitation": "enviar invitaci\u00f3n",
        "scheInvt_send_passcodes": "62\/5000\nEnv\u00ede los c\u00f3digos de acceso en un correo electr\u00f3nico aparte de la invitaci\u00f3n",
        "scheMeet_room_reserved_from": "Habitaci\u00f3n reservada desde",
        "scheMeet_meeting_name": "con el nombre de la reuni\u00f3n",
        "invitBody_invite_only_upto": "S\u00f3lo puedes invitar a un m\u00e1ximo de 10 personas",
        "invitBody_invite_ppl_meeting_email_add": "Invita a la gente a esta reuni\u00f3n, a\u00f1ade gente de tu Agenda o a\u00f1\u00e1dela por correo electr\u00f3nico.",
        "invitBody_copy": "Copia",
        "invitBody_enter_emailid": "Ingresa el ID del correo electr\u00f3nico",
        "invitBody_add": "A\u00f1ade",
        "invitBody_close": "CERRAR",
        "invitBody_send_invitation": "enviar invitaci\u00f3n",
        "docShare_doc_sharing": "Intercambio de documentos",
        "docShare_upload_doc_system_pdf": "Sube el documento de tu sistema, soporta .pdf, excel, documento word.",
        "docShare_upload_doc": "Subir el documento",
        "docShare_no_doc": "No hay documentos",
        "docShare_no_doc_in_list": "No hay documentos a\u00f1adidos a la lista todav\u00eda.",
        "docShare_add_doc": "Documentos a\u00f1adidos",
        "docShare_alert_title_doc": "Por favor, introduzca el t\u00edtulo del documento",
        "docShare_alert_something_went_wrong": "Algo sali\u00f3 mal, por favor, int\u00e9ntalo de nuevo.",
        "docShare_alert_try_again": "Por favor, int\u00e9ntelo de nuevo.",
        "permitDocShr_permit_doc_share_access": "Permitir el acceso al intercambio de documentos",
        "permitDocShr_req_permission_doc_share": "ha solicitado permiso para acceder al intercambio de documentos.",
        "permitDocShr_deny": "Denegar",
        "permitDocShr_allow": "Permita",
        "reqDocShr_req_doc_share_access": "Solicitar el acceso al intercambio de documentos",
        "reqDocShr_access_permission_host": "Para permitir el intercambio de documentos, se necesita el permiso de acceso del anfitri\u00f3n.",
        "reqDocShr_plz_wait": "Por favor, espere a que",
        "reqDocShr_permission_start_doc_share": "(Anfitri\u00f3n) para iniciar el intercambio de documentos.",
        "reqDocShr_cancel": "Cancelar",
        "reqDocShr_doc_share": "\u00a1Compartir documentos!",
        "showDocViewback": "Volver",
        "showDocViewdoc_title_name": "Nombre del t\u00edtulo del documento",
        "showDocViewnext": "Siguiente",
        "showDocViewprevious": "Anterior",
        "showDocViewpage_of": "p\u00e1gina de",
        "borcstScreen_alert_unsupported": "Se solicita una orden de juego sin apoyo. Los formatos soportados son webrtc y hls. Usa algo como playOrder=webrtc hls",
        "borcstScreen_alert_no_stream_spec": "No se especifica el flujo. Por favor, a\u00f1ade ?id={STREAM_ID} a la URL",
        "borcstScreen_Plz_enable_js": "Para ver este video por favor habilite JavaScript, y considere la posibilidad de actualizar a un navegador web que",
        "borcstScreen_support_HTML5": "soporta video HTML5",
        "borcstScreen_steam_play_when_live": "El v\u00eddeo se reproducir\u00e1 cuando est\u00e9 en vivo",
        "broadcast_alert_unsupported": "Formato no soportado. Los formatos soportados son webrtc et hls. Utilisez quelque eligi\u00f3 comme playOrder = webrtc hls",
        "broadcast_alert_no_stream_spec": "No se especifica el flujo. Por favor, a\u00f1ade ?id={STREAM_ID} a la URL",
        "broadcast_enter_your_name": "Por favor, introduzca su nombre",
        "broadcast_enter_your_email": "Por favor, introduzca su correo electr\u00f3nico",
        "broadcast_user_details": "Detalles del usuario",
        "broadcast_submit_next": "Env\u00edar",
        "broadcast_Plz_enable_js": "Para ver este video por favor habilite JavaScript, y considere la posibilidad de actualizar a un navegador web que",
        "broadcast_support_HTML5": "admite video HTML5",
        "broadcast_steam_play_when_live": "El v\u00eddeo se reproducir\u00e1 cuando est\u00e9 en vivo",
        "brdcstConf_whiteboard": "Tablero blanco",
        "brdcstConf_screen_share": "Compartir pantalla",
        "brdcstConf_participants": "participantes",
        "brdcstConf_broadcast": "Emisi\u00f3n",
        "brdcstConf_cancel": "Cancelar",
        "brdcstConf_right": "derecha",
        "brdcstPart_broadcast_list": "Lista de emisoras",
        "calender_scheduled_calendar": "Calendario de programaci\u00f3n",
        "calender_select_all": "Seleccione todo...",
        "calender_calender": "Calendario",
        "calender_daily": "D\u00eda",
        "calender_weekly": "Semanal",
        "calender_month": "Mes",
        "calender_today": "Hoy",
        "contact_alert_already_in_call": "No puedes llamar a otro usuario, cuando ya est\u00e1s en llamada",
        "contact_alert_user_offline": "Usuario desconectado",
        "meetReminder_scheduled_confirm": "Confirmaci\u00f3n de la programaci\u00f3n",
        "meetReminder_meeting_has_been_scheduled": "La siguiente reuni\u00f3n ha sido programada en este momento",
        "meetReminder_start_meeting": "Comienza esta reuni\u00f3n...",
        "meetReminder_join_meeting": "\u00danete a esta reuni\u00f3n...",
        "meetReminder_quick_start_another_meeting": "Inicio instant\u00e1neo de otra reuni\u00f3n",
        "myMeetItem_start": "Comienza",
        "myMeetItem_for": "para",
        "myMeetItem_minutes": "Actas",
        "myMeetItem_host": "Anfitri\u00f3n:",
        "myMeet_my_meetings": "Mis reuniones",
        "myMeet_search_my_meetings": "Busca en mis reuniones",
        "noScheMeet_no_meeting_scheduled\t": "o Reuniones programadas",
        "noScheMeet_no_upcoming_meetings\t": "No tienes reuni\u00f3n programada todav\u00eda. Puede crear un nuevo programa o iniciar r\u00e1pidamente su reuni\u00f3n ahora.",
        "noScheMeet_create_schedule": "Crear un programa",
        "noScheMeet_quick_start": "Inicio instant\u00e1neo",
        "noScheMeet_or": "O",
        "roomSet_alert_select_license": "Por favor, seleccione la licencia",
        "roomSet_manage_licenses": "Administrar las licencias",
        "roomSet_package": "Paquete",
        "roomSet_no_of_rooms": "N\u00famero de habitaciones",
        "roomSet_no_of_user": "N\u00famero de usuarios",
        "roomSet_purchased_on": "Comprado en",
        "roomSet_expired_on": "Expir\u00f3 el",
        "roomSet_create_room": "Crear una habitaci\u00f3n",
        "dashboard_alert_cannot_start": "No puedes empezar la reuni\u00f3n, cuando est\u00e1s en otra llamada",
        "dashboard_alert_cannot_join": "No puedes unirte a la reuni\u00f3n, cuando est\u00e1s en otra llamada",
        "dashboard_quick_start_conf": "REUNI\u00d3N INSTANT\u00c1NEA",
        "dashboard_loading": "Cargando...",
        "dashboard_maintenance_msg": "Por favor, tenga en cuenta que estaremos experimentando una ca\u00edda del servidor el 22 de abril de 2020 desde las 8.30 PM IST hasta las 9.00 PM IST debido a un mantenimiento rutinario.",
        "addCam_add_device": "A\u00f1adir el dispositivo",
        "addSipDev_add_sip_details": "A\u00f1adir detalles del SIP",
        "addSipDev_sip_address": "Direcci\u00f3n SIP:",
        "addSipDev_connect": "Conecta",
        "addSipDev_enter_sip_address": "Introduzca la direcci\u00f3n SIP",
        "confNotf_notification": "Notificaciones",
        "confNotf_user_name": "Nombre de usuario",
        "confNotf_want_to_join": "quiere unirse a esta sala de reuniones.",
        "confNotf_allow": "Permita",
        "confNotf_deny": "Denegar",
        "confNotf_ask_question": "hizo una pregunta.",
        "confNotf_send_msg_lobby": "envi\u00f3 un mensaje desde el vest\u00edbulo.",
        "lobby_participants_loddy": "Los participantes en el vest\u00edbulo",
        "lobby_pramote_all": "Pramotear todo",
        "lobby_user_name": "Nombre de usuario",
        "removeUserDlg_manage_user_selecting": "Gestione este usuario seleccionando cualquiera de estas opciones:",
        "removeUserDlg_user_name": "Nombre de usuario",
        "removeUserDlg_block_participant": "Participante del bloque",
        "removeUserDlg_send_to_lobby": "Enviar al Lobby",
        "removeUserDlg_remote_participant": "Eliminar el participante",
        "removeUserDlg_close": "CERRAR",
        "PartList_participants_in_lobby": "Los participantes en el vest\u00edbulo",
        "PartList_alert_something_went_wrong": "Algo sali\u00f3 mal. Por favor, int\u00e9ntelo de nuevo.",
        "PartList_in_webinar": "En Webinar",
        "PartList_promote_all": "Promover todos",
        "waitingRoom_waiting_room": "Sala de espera",
        "waitingRoom_plz_wait_host_allow": "Por favor, espere hasta que el anfitri\u00f3n le permita unirse a la reuni\u00f3n.",
        "waitingRoom_chat": "Chat",
        "waitingRoom_mins": "mins",
        "waitingRoom_no_msg_yet": "No hay mensajes, todav\u00eda!",
        "waitingRoom_no_msg_from_host": "No hay mensajes del anfitri\u00f3n.",
        "waitingRoom_say_something": "\u00a1Di algo!",
        "userRemoved_you_removed_by_host": "El anfitri\u00f3n lo ha eliminado de la lista de participantes",
        "invitSlide_invite": "Invitar a",
        "invitSlide_add_email": "Agregar correos electr\u00f3nicos",
        "invitSlide_add": "A\u00f1ade",
        "invitSlide_broadcast_list": "Enlace de transmisi\u00f3n",
        "invitSlide_invite_people_to_meeting": "Invitar a la gente a esta reuni\u00f3n, escribir y a\u00f1adir correos electr\u00f3nicos a la lista para enviar la invitaci\u00f3n.",
        "invitSlide_err_invalid_id": "Identificador de correo electr\u00f3nico inv\u00e1lido",
        "particiChat_say_something": "\u00a1Di algo!",
        "particiChat_no_chat_msg": "No hay mensajes de chat todav\u00eda!",
        "particiChat_start_conversation": "Inicie la conversaci\u00f3n para ver los mensajes aqu\u00ed.",
        "coBrows_host_share-video": "\u00a1Por favor, esperen! El anfitri\u00f3n est\u00e1 a punto de compartir un video",
        "lobbyPartici_no_participants": "No hay participantes",
        "lobbyPartici_no_participants_lobby": "No hay ning\u00fan participante en el vest\u00edbulo.",
        "simulcast_alert_decline_access": "El anfitri\u00f3n rechaz\u00f3 el acceso",
        "simulcast_invalid_url": "URL inv\u00e1lido",
        "simulcast_no_host_available": "No parece que haya ning\u00fan presentador disponible en este momento. Por favor, convi\u00e9rtase en presentador para compartir la pantalla.",
        "conference_access_to_participants": "Acceso a los participantes",
        "conference_screen_share": "Compartir pantalla",
        "conference_chat": "Chat",
        "conference_lock_all": "Bloquear todo",
        "conference_lock_conf": "Reuni\u00f3n de la cerradura",
        "conference_become_host": "Convi\u00e9rtase en el anfitri\u00f3n",
        "conference_you_are_host": "Usted es el anfitri\u00f3n",
        "conference_share": "Comparte",
        "conference_document_share": "Compartir documentos",
        "conference_content_share": "Compartir el contenido",
        "conference_more": "M\u00e1s...",
        "conference_alert_no_host_aval_screen": "No parece que haya ning\u00fan presentador disponible en este momento. Por favor, convi\u00e9rtase en presentador para compartir la pantalla.",
        "conference_alert_no_host_aval_doc": "No parece que haya ning\u00fan anfitri\u00f3n disponible en este momento. Por favor, convi\u00e9rtase en anfitri\u00f3n para compartir el documento.",
        "conference_alert_err_sip_device": "Error al conectar el dispositivo de sorbo",
        "conference_alert_host_decline_access": "El anfitri\u00f3n rechaz\u00f3 el acceso",
        "conference_alert_invalid_url": "URL inv\u00e1lido",
        "conference_unmute_mic_start_talk": "Desactiva el micr\u00f3fono para empezar a hablar",
        "confTools_new_whiteboard": "Su nueva pizarra viene aqu\u00ed",
        "confTools_video_sync": "Tu video de sincronizaci\u00f3n",
        "confTools_microphone": "Micr\u00f3fono",
        "confTools_type_text_here": "Escriba su texto...",
        "confTools_whiteboard": "Tablero blanco",
        "confLibs_alert_broadcast_screen_failed": "La pantalla de emisi\u00f3n fall\u00f3",
        "confLibs_broadcast_failed": "La transmisi\u00f3n fall\u00f3",
        "chatContainer_say_something": "\u00a1Di algo!",
        "chatContainer_no_chat_msg": "No hay mensajes de chat todav\u00eda!",
        "chatContainer_start_conversation": "Inicie la conversaci\u00f3n para ver los mensajes aqu\u00ed.",
        "fmyRoom_ree": "Libre",
        "meetRec_video_rec_meeting_name": "Grabaciones de video del nombre de la reuni\u00f3n",
        "meetRec_page_view": "Vista de p\u00e1gina:",
        "recListView_title_name_rec": "T\u00edtulo Nombre de la grabaci\u00f3n",
        "recListView_name_user": "Nombre del usuario",
        "instavc_allow_Notes": "Notas de permiso",
        "brwUpg_req_fun_not_avail": "La funcionalidad requerida no est\u00e1 disponible en su navegador",
        "brwUpg_meeting_req_fun_sup": "Este servicio de reuniones requiere una funcionalidad no soportada por su navegador.",
        "brwUpg_plz_upgrade": "Por favor, actualice, cambie a un navegador diferente o compruebe su configuraci\u00f3n. Navegadores compatibles:",
        "header_content": "Contenido",
        "header_loading": "Cargando...",
        "crtePln_plz_enter_name": "Por favor, introduzca el nombre del plan",
        "crtePln_plz_amount_Plan": "Por favor, introduzca la cantidad del plan",
        "crtePln_plz_enter_des": "Por favor, introduzca la descripci\u00f3n",
        "crtePln_Plz_sel_product": "Por favor, seleccione el producto",
        "crtePln_plz_sel_no_host": "Por favor, seleccione el n\u00famero de hu\u00e9spedes",
        "crtePln_plz_enter_no_participants": "Por favor, introduzca el n\u00famero de participantes",
        "crtePln_plz_enter_roomno": "Por favor, introduzca el n\u00famero de habitaciones",
        "crtePln_select_period": "Por favor, seleccione el per\u00edodo",
        "crtePln_sel_interval": "Por favor, seleccione el intervalo",
        "crtePln_update_succ": "Actualizado con \u00e9xito",
        "crtePln_err_creating_plan": "Error en la creaci\u00f3n del plan",
        "brdcRaise_alert_question_50_char": "Su pregunta debe tener un m\u00ednimo de 50 caracteres de charla",
        "cofCom_no_part_lobby": "No hay ning\u00fan participante en el vest\u00edbulo.",
        "cofCom_no_participants": "No hay participantes",
        "cofCom_say_something": "\u00a1Di algo!",
        "confmUser_plz_provide_name_email": "Por favor, proporcione su nombre y correo electr\u00f3nico",
        "confmUser_notes": "Notas",
        "confSetup_telepresence": "Telepresencia",
        "confSetup_alert_not_generate_meetingID": "No es capaz de generar identificaci\u00f3n de reuni\u00f3n, intenta despu\u00e9s de alg\u00fan tiempo",
        "confSetup_auth_part_can_join": "S\u00f3lo los participantes autorizados pueden unirse!.",
        "confSetup_invalid_link": "Enlace inv\u00e1lido",
        "confSetup_meeting_notyet_started": "Esta reuni\u00f3n a\u00fan no ha comenzado. Por favor, \u00fanete m\u00e1s tarde!. Si usted es un anfitri\u00f3n, por favor, acceda a instaVC e inicie la reuni\u00f3n",
        "confSetup_meeting_locked_byhost": "Esta reuni\u00f3n est\u00e1 bloqueada por el anfitri\u00f3n",
        "confSetup_meeting_notyet_started_wait_host": "Esta reuni\u00f3n a\u00fan no ha comenzado. \u00a1Por favor, esperen a que el anfitri\u00f3n empiece la reuni\u00f3n!",
        "newChat_panel_chat": "Panel de chat",
        "newChat_webinar_chat": "Chat del Webinar",
        "instaVc_server_busy": "\u00a1El servidor de Looks est\u00e1 ocupado! Intentando conectar otro servidor...",
        "instaVc_server_busy_reload_tojoin": "\u00a1El servidor de Looks est\u00e1 ocupado! Recarga para unirse a la reuni\u00f3n",
        "instaVc_broadcast_scr_failed": "La pantalla de emisi\u00f3n fall\u00f3",
        "instaVc_broadcast_failed": "La transmisi\u00f3n fall\u00f3",
        "instaVc_waiting_for_host": "Esperando el video del anfitri\u00f3n",
        "modlCreate_plz_enter_title": "Por favor, introduzca el t\u00edtulo",
        "modlCreate_questionnaire": "Cuestionario",
        "modlCreate_questionnaire_name": "Nombre del cuestionario",
        "modlCreate_desc": "Descripci\u00f3n",
        "modlCreate_cancle": "Cancelar",
        "modlCreate_poll": "Encuesta",
        "mdlCretPoll_plz_enter_quest": "Por favor, introduzca la pregunta",
        "mdlCretPoll_option_should_not_same": "Las opciones no deber\u00edan ser las mismas",
        "mdlCretPoll_plz_enter_option": "Por favor, introduzca las opciones",
        "mdlCretPoll_plz_select_type_option": "Por favor, seleccione el tipo de las opciones",
        "mdlCretPoll_alert_server_error": "Error interno del servidor, por favor int\u00e9ntelo de nuevo",
        "mdlCretPoll_update_ques": "Pregunta de actualizaci\u00f3n",
        "mdlCretPoll_create_ques": "Crear la pregunta",
        "mdlCretPoll_Question": "Pregunta",
        "mdlCretPoll_enter_que_here": "Escriba su pregunta aqu\u00ed",
        "mdlCretPoll_single_sel_multi_choice": "Selecci\u00f3n simple Selecci\u00f3n m\u00faltiple",
        "mdlCretPoll_enter_ans_choice": "Introduzca las opciones de respuesta, y marque qu\u00e9 respuesta es la correcta",
        "mdlCretPoll_end": "fin",
        "mdlCretPoll_cancle": "Cancelar",
        "viewQues_viewQuest_delete_successfully": "Borrado con \u00e9xito",
        "viewQues_viewQuest_server_error": "Error interno del servidor, por favor int\u00e9ntelo de nuevo",
        "viewQues_viewQuest_plz_try_again": "Por favor, int\u00e9ntelo de nuevo.",
        "mngContent_mngContent_content": "Contenido",
        "mngContent_mngContent_participants": "participantes",
        "mngContent_mngContent_grade": "Grados",
        "mngContent_mngContent_assign_quest": "Asignar el cuestionario",
        "mngContent_mngContent_fav_prog_lang": "\u00bfCu\u00e1l es tu lenguaje de programaci\u00f3n favorito?",
        "mngContent_mngContent_grade_settings": "Ajustes del grado",
        "mngContent_mngContent_pollings": "Encuestas",
        "questStepper_you_cant_skip": "No puedes saltarte un paso que no sea opcional.",
        "questStepper_back": "Volver",
        "questStepper_skip": "Saltar",
        "questStepper_next": "Siguiente",
        "showQue_obtained_marks": "Obtenido Marcas:",
        "viewUser_your_grade": "Tu grado:",
        "package_one_host": "1 Anfitri\u00f3n y",
        "package_participants": "participantes",
        "package_recordings": "Grabaci\u00f3n",
        "package_concurrentUser_room2": "20 Usuarios concurrentes b\ufffd 2 Salas",
        "package_concurrentUser_room5": "30 usuarios concurrentes -5 habitaciones",
        "package_concurrentUser_room20": "100 Usuarios concurrentes b\ufffd 20 Salas",
        "package_concurrentUser_room30": "200 usuarios concurrentes - 30 habitaciones",
        "package_concurrentUser_room50": "500 Usuarios concurrentes b\ufffd 50 Habitaciones",
        "pastMeeting_chat_history_deleted": "el historial de chat se ha borrado con \u00e9xito",
        "pastMeeting_plz_try_again": "Por favor, int\u00e9ntelo de nuevo.",
        "pastMeeting_past_meeting_dtls": "Detalles de reuniones completadas",
        "pastMeeting_past_meeting": "Reuniones completadas",
        "pastMeeting_room_name": "Nombre de la habitaci\u00f3n:",
        "pastMeeting_duration": "Duraci\u00f3n:",
        "pastMeeting_download_parti_atten": "Descargar Participantes y Asistentes",
        "pastMeeting_download_webinar": "Descargar Webinar",
        "pastMeeting_participants": "participantes",
        "pastMeeting_attendees": "Asistentes",
        "pastMeeting_chat_history": "Historial del chat",
        "pastMeeting_notes": "Notas",
        "pastMeeting_recordings": "Grabaciones",
        "pastMeeting_webinar_user": "Usuarios del Webinar",
        "pastMeeting_webinar_chat": "Chat del Webinar",
        "pastMeeting_no_participants_in_meeting": "\u00a1No hay participantes en esta reuni\u00f3n!",
        "pastMeeting_no_parti_attended_meeting": "No hubo participantes que asistieran a esta reuni\u00f3n.",
        "pastMeeting_guest_list": "Lista de invitados",
        "pastMeeting_no_guest_in_meeting": "\u00a1No hay invitados en esta reuni\u00f3n!",
        "pastMeeting_no_guests_were_invited ": "No hubo ning\u00fan invitado que haya asistido a esta reuni\u00f3n.",
        "pastMeeting_meeting_chat_history": "Historia del chat de la reuni\u00f3n",
        "pastMeeting_delete_all": "Borrar todo",
        "pastMeeting_no_chat_msg_inmeeting": "\u00a1No hay mensajes de chat en esta reuni\u00f3n!",
        "pastMeeting_no_conver_during_meeting": "No hubo conversaciones en el chat durante la reuni\u00f3n.",
        "pastMeeting_no_user_inwebinar": "\u00a1No hay usuarios en este seminario web!",
        "pastMeeting_no_user_attand_webinar": "No hubo usuarios que asistieran a este seminario web.",
        "pastMeeting_webinar_chat_history": "Historia del chat del seminario web",
        "pastMeeting_no_chat_msg_inwebinar": "\u00a1No hay mensajes de chat en este seminario web!",
        "pastMeeting_no_conver_during_webinar": "No hubo conversaciones en el chat durante el seminario web.",
        "pastMeeting_del_chat_history": "Eliminar el historial de chat",
        "pastMeeting_delete_all_chat_history": "\u00bfEst\u00e1s seguro de que quieres borrar todo el historial de chat de forma permanente?",
        "pastMeeting_cancle": "Cancelar",
        "pastMeeting_delete": "Eliminar",
        "schedul_webinar_event": "Evento del Webinar",
        "schedul_webinar_description": "Descripci\u00f3n del seminario web",
        "schedul_upload_custom_banner": "Suba un banner personalizado para su evento de Webinar.",
        "schedul_setting": "Ajustes",
        "schedulView_time": "Tiempo",
        "schedulView_edit": "Edita",
        "schedulView_enable_training_mode": "Activar el modo de entrenamiento",
        "schedulView_audio_conference": "Reuni\u00f3n de audio",
        "schedulView_enable_mix_video": "Habilitar Mezclar Video por defecto",
        "questions": "Preguntas",
        "Save": "Guardar",
        "answer_list": "Lista de respuestas",
        "answer": "Respuestas",
        "answered": "Respondido",
        "correct_answer": "Respuesta correcta",
        "participants": "participantes",
        "please_try_again": "Por favor, int\u00e9ntelo de nuevo.",
        "search": "Busca...",
        "question": "Pregunta",
        "questionnaire": "Cuestionario",
        "content": "Contenido",
        "address_book": "Libreta de direcciones",
        "view": "Vea",
        "rn": "RN",
        "quslsit_assig_que_list": "Lista del cuestionario asignado",
        "quslsit_question_lists": "Listas del Cuestionario",
        "quslsit_search": "Busca...",
        "quslsit_assign_new_ques": "Asigne un nuevo cuestionario para hacer clic en el bot\u00f3n de arriba Asignar cuestionario",
        "select_questionnaire": "Seleccione el cuestionario",
        "duration": "Duraci\u00f3n",
        "time_limit": "L\u00edmite de tiempo",
        "show_results": "Mostrar resultados",
        "content_group": "Grupo de contenido",
        "list_assigned_ques": "Lista de preguntas asignadas:",
        "mins": "mins",
        "marks": "Marcas",
        "questionnaire_list": "Lista del cuestionario",
        "edit_content": "Editar el contenido",
        "queStepper_cant_skip": "No puedes saltarte un paso que no sea opcional.",
        "queStepper_skip": "Saltar",
        "alert_server_error": "Error interno del servidor, por favor int\u00e9ntelo de nuevo",
        "delete_successfully": "Borrado con \u00e9xito",
        "pastMeeting_rn": "RN",
        "plz_enter_marks": "Indique el n\u00famero de puntos asignados a esta pregunta.",
        "plz_sel_crtans": "Por favor, seleccione la respuesta correcta",
        "truefalse_choice": "Verdadera \/ falsa elecci\u00f3n",
        "questionnarie": "Questionnarie",
        "content_title": "T\u00edtulo del contenido",
        "media_title": "T\u00edtulo de medios",
        "add_url_add": "A\u00f1adir direcci\u00f3n URL...",
        "val_shuld_grtr_from_val": "a valor debe ser mayor que de Valor",
        "val_shuld_grtr_grade": "del valor debe ser mayor que el grado",
        "to_val": "para valorar",
        "enable_hand_raise": "Habilitar el levantamiento de manos\/preguntas en un seminario web",
        "enable_waiting_room": "Habilitar la sala de espera",
        "allow_only_invite": "Permitir s\u00f3lo a los invitados a unirse a la reuni\u00f3n (protegido por contrase\u00f1a)",
        "guest_login_anonymous": "Los invitados pueden iniciar sesi\u00f3n de forma an\u00f3nima",
        "give_name_webinar": "Dale un nombre a tu seminario web",
        "Webinar": "Seminario web",
        "polls_list": "Lista de encuestas",
        "no_poll_assign": "A\u00fan no se han asignado encuestas.",
        "poll_view": "Vista de la encuesta",
        "filter_block": "bloque de filtro",
        "file_size": "Tama\u00f1o del archivo",
        "select_room": "Por favor, seleccione una habitaci\u00f3n",
        "provide_event_name": "Por favor, proporcione el nombre del evento",
        "end_time_greater_start_time": "\u00a1El Tiempo Final debe ser mayor que el tiempo de inicio!",
        "invite_people_meeting": "Invitar a la gente a esta reuni\u00f3n",
        "download_ical": "Descargar iCal",
        "download": "Descargar",
        "delete_meeting": "Eliminar esta reuni\u00f3n",
        "new_event": "Nuevo evento",
        "end_repeat": "final de la repetici\u00f3n",
        "invite_users_timezone": "invitar a los usuarios con la siguiente zona horaria",
        "update_event": "Evento de actualizaci\u00f3n",
        "create_event": "Crear un evento",
        "wrong_password": "Contrase\u00f1a incorrecta",
        "college": "Colegio",
        "university": "Universidad",
        "country": "Pa\u00eds",
        "time_period": "Periodo de tiempo:",
        "currently_working_here": "Actualmente trabaja aqu\u00ed",
        "from": "De:",
        "graduated": "Graduado",
        "enter_description": "Escriba su descripci\u00f3n aqu\u00ed...",
        "concentration": "Concentraci\u00f3n",
        "school": "Escuela",
        "poll_question": "Pregunta de la encuesta",
        "create_poll_ques": "Crear la pregunta de la encuesta",
        "options": "opciones",
        "no_ques_assigned_yet": "\u00a1No se ha asignado ninguna pregunta todav\u00eda!",
        "create_new_ques_click_create_ques": "Crear una nueva pregunta, para hacer clic en el bot\u00f3n de arriba Crear pregunta",
        "enter_room_name": "Por favor, introduzca el nombre de la habitaci\u00f3n",
        "billed_annually": "* Facturado anualmente",
        "subscribe_now": "Suscr\u00edbase ahora",
        "group": "Grupo",
        "sample_webinar": "Webinar de muestra",
        "webinar_affiliate": "Webinar sobre los afiliados",
        "webinar_one": "Webinar Uno",
        "workspace": "Espacio de trabajo",
        "company": "Compa\u00f1\u00eda",
        "add_another": "A\u00f1ade otro",
        "assignment": "Tareas",
        "assignments": "Tares",
        "create_new_poll": "Crear una nueva pregunta de encuesta para asignarla",
        "plz_title_url": "Plaese introduce el t\u00edtulo y el URL",
        "votes": "Votos",
        "enter_content_title": "Por favor, introduzca el t\u00edtulo del contenido",
        "failed_result": "resultado fallido",
        "removed_successfully": "Removido con \u00e9xito",
        "enter_duration": "Por favor, introduzca la duraci\u00f3n",
        "select_questionnarie": "Por favor, seleccione el cuestionario",
        "no_assignments": "No hay asignaciones",
        "create_new_assignment": "Crear una nueva tarea, para crearla haga clic en el bot\u00f3n de arriba Crear tarea",
        "create_assignment": "Crea una tarea",
        "assignments_list": "Listado de tareas",
        "assignment_description": "Descripci\u00f3n de la tarea",
        "assignment_name": "Nombre de la tarea",
        "update_assignment": "Actualizaci\u00f3n de la asignaci\u00f3n",
        "add_new_assignment": "agregar una nueva tarea",
        "assignment_list": "Listado de tareas",
        "assign_assignments": "Asignaci\u00f3n de tareas",
        "assigned_assignments_list": "Lista de tareas asignadas",
        "assignment_answer": "Respuesta a la tarea",
        "no_assig_submitted": "No se ha presentado ninguna asignaci\u00f3n todav\u00eda.",
        "no_assig_submitted_participants": "No se han presentado a\u00fan asignaciones de ning\u00fan participante.",
        "view_assignment": "Ver la tarea",
        "assigned_participants": "Participantes asignados",
        "select_assignment": "Seleccione la asignaci\u00f3n",
        "fill_your_assignment": "Llene su asignaci\u00f3n",
        "submit_assignment": "Enviar una tarea",
        "assignment_details": "Detalles de la tarea",
        "assignment_submit_success": "Tarea enviada correctamente",
        "assignment_saved_success": "Tarea guardada con éxito",
        "how_to_get_started": "C\u00f3mo empezar.",
        "create_new_room": "Crear una nueva sala",
        "create_room_add_participants": "Crear una nueva sala y a\u00f1adir participantes a la sala.",
        "repeats_every": "Repite cada",
        "now": "Ahora",
        "Plz_enter_title_meeting": "Por favor, introduzca el t\u00edtulo de la reuni\u00f3n",
        "no_start_time": "No se ha encontrado la hora de inicio",
        "no_end_time": "No se ha encontrado el tiempo final",
        "item_id_not_found": "Identificaci\u00f3n del art\u00edculo No se encontr\u00f3",
        "msg_title_not_found": "T\u00edtulo del mensaje No encontrado",
        "plz_join_conf_clicking_link": "Por favor, \u00fanase a la reuni\u00f3n haciendo clic en el siguiente enlace",
        "hi_there": "Hola,",
        "room_permanently": "\u00bfHabitaci\u00f3n permanente?",
        "sure_want_to_delete_participants": "\u00bfEst\u00e1 seguro de que quiere eliminar a este participante?",
        "delete_participant": "Eliminar el participante",
        "add_email": "Agrega el correo electr\u00f3nico",
        "add_participants_to_the_room": "A\u00f1ada los participantes a esta sala escribiendo su direcci\u00f3n de correo electr\u00f3nico en el cuadro de entrada de abajo.",
        "no_of_users": "No de usuarios",
        "edit_the_name": "Puedes editar el nombre y el n\u00famero de usuario de esta sala.",
        "delete_room": "Eliminar la sala",
        "are_you_sure_delete": "\u00bfEst\u00e1s seguro de que quieres borrar",
        "delete_this_room": "Borrar esta habitaci\u00f3n",
        "delete_room_will_parmanently": "68\/5000\nAl hacer clic en Eliminar, esta parte se eliminar\u00e1 permanentemente",
        "enable_storage": "Habilitar el almacenamiento",
        "enable_recordings": "Habilitar las grabaciones",
        "enable_SIP": "Habilitar SIP",
        "addons": "M\u00f3dulos adicionales",
        "expires_on": "Expira el",
        "purchased_on": "Comprado en",
        "more_storage": "M\u00e1s Almacenamiento",
        "total_storage": "Almacenamiento total",
        "storage_occupied": "Almacenamiento ocupado",
        "added": "A\u00f1adido",
        "open_graphs": "Gr\u00e1ficos abiertos",
        "participant_controls": "Controles de los participantes",
        "dashboard_controls ": "Controles del tablero de mandos",
        "start_meeting": "Iniciar una reuni\u00f3n",
        "join_a_meeting": "\u00danete a una reuni\u00f3n",
        "meeting_settings": "Configuraci\u00f3n de la reuni\u00f3n",
        "videos_to_get_started": "Videos para empezar",
        "if_you_are_host": "Si usted es un anfitri\u00f3n, haga clic en el bot\u00f3n de abajo para iniciar la reuni\u00f3n",
        "guest_link": "Enlace de invitados",
        "pay_now": "PAGUE AHORA",
        "promo_code_apply_here": "\u00bfTiene un c\u00f3digo de promoci\u00f3n? Aplique aqu\u00ed",
        "per_month": "por mes",
        "billed_for": "facturado por",
        "gst_applicable": "De conformidad con las normas del Gobierno, se aplicar\u00e1 un 18% adicional de SGT a los clientes de la India.",
        "select_country_code": "Seleccione el c\u00f3digo de pa\u00eds",
        "country_code": "C\u00f3digo de pa\u00eds",
        "enter_email_id": "Ingresa el ID del correo electr\u00f3nico...",
        "downLoad_chat": "Chat DownLoad",
        "allow_guest_control_their": "Permitir que el hu\u00e9sped controle su video\/audio",
        "below_list_people_invited": "A continuaci\u00f3n se encuentra la lista de personas invitadas",
        "verification_code": "C\u00f3digo de verificaci\u00f3n",
        "open_link_enter_verify_code": "Abra el siguiente enlace URL, e introduzca su c\u00f3digo de verificaci\u00f3n para la integraci\u00f3n",
        "social_media_integration": "Integraci\u00f3n de los medios sociales",
        "integrate_social_media_platform": "Puedes integrar las plataformas de medios sociales, al hacer clic en un bot\u00f3n, obtendr\u00e1s un c\u00f3digo de verificaci\u00f3n, \u00fasalo para la integraci\u00f3n.",
        "plz_enter_val_email": "Por favor, introduzca un correo electr\u00f3nico v\u00e1lido",
        "record": "Grabar",
        "in_conference": "En la reuni\u00f3n",
        "password_if_any": "Contrase\u00f1a (si la hay)",
        "stop_send": "Stop &amp; Enviar",
        "broadcast_not_enabled_meeting": "La transmisi\u00f3n no est\u00e1 habilitada para esta reuni\u00f3n",
        "facebook_url": "URL de Facebook",
        "enter_facebook_url": "Ingresa la URL de RTMB de Facebook",
        "add_facebook_url": "A\u00f1adir la URL de RTMP de Facebook",
        "youtube_url": "URL de Youtube",
        "enter_youtube_url": "Ingresa en Youtube RTMB URL",
        "add_youtube_url": "A\u00f1adir a Youtube RTMP URL",
        "register": "Registrarse",
        "time_of_program": "Tiempo del programa",
        "date_of_program": "Fecha del programa",
        "name_program_interested": "Nombre del programa interesado",
        "email_id": "Identificador de correo electr\u00f3nico",
        "dob": "Fecha de nacimiento",
        "applicant_address": "Direcci\u00f3n del solicitante",
        "qualification": "Calificaci\u00f3n",
        "designation": "Designaci\u00f3n",
        "org_name_address": "Nombre y direcci\u00f3n de la organizaci\u00f3n",
        "fullname_participant": "Nombre completo del participante",
        "registration_form": "Formulario de inscripci\u00f3n",
        "thanks_submitting_data": "Gracias por enviar sus datos",
        "manage_content": "Administrar el contenido",
        "API_settings": "Configuraci\u00f3n de la API",
        "schedule_API": "Programar el API",
        "image_upload_success": "imagen cargada con \u00e9xito",
        "less_than_10MB": "por favor, suba un archivo de menos de 10MB",
        "at_time_of_event": "En el momento del evento",
        "min_5": "5 minutos antes",
        "min_10": "10 minutos antes",
        "min_15": "15 minutos antes",
        "min_30": "30 minutos antes",
        "hrs_1": "1 hora antes",
        "hrs_2": "2 horas antes",
        "day_1": "1 d\u00eda antes",
        "day_2": "2 d\u00edas antes",
        "jan": "Jan",
        "feb": "Feb",
        "mar": "Mar",
        "apr": "Apr",
        "may": "Mayo",
        "jun": "Jun",
        "jul": "Jul",
        "aug": "Ago",
        "sep": "Sep",
        "oct": "Oct",
        "nov": "Nov",
        "dec": "Dec",
        "mon": "Mon",
        "tue": "Mar",
        "wed": "Mi\u00e9",
        "thu": "Jue",
        "fri": "Vie",
        "sat": "Sab",
        "sun": "Dom",
        "all_day": "todo el d\u00eda",
        "starts_ddmmyyyy": "comienza (dd\/mm\/aaaa)",
        "ends_ddmmyyyy": "final (dd\/mm\/yyyy)",
        "ddmmyyyy": "(dd\/mm\/aaaa)",
        "by_dayweek": "Por d\u00eda\/semana",
        "never": "Nunca",
        "on_date": "En la fecha",
        "alert": "Alerta",
        "multi_factor_auth": "Habilitar la autenticaci\u00f3n de m\u00faltiples factores (MFA)",
        "advanced_settings": "Configuraci\u00f3n avanzada",
        "verify_Otp": "Verifique a Otp",
        "otp_sent_mobile": "Introduce la OTP enviada a tu n\u00famero de m\u00f3vil",
        "update_mobile": "Actualizaci\u00f3n del m\u00f3vil",
        "mob_verfi_pending": "La verificaci\u00f3n de su m\u00f3vil est\u00e1 pendiente.",
        "mobile_number": "N\u00famero de m\u00f3vil",
        "enter_Otp": "Entra en Otp",
        "enter_mob_no": "por favor, introduzca el n\u00famero de m\u00f3vil",
        "sure_want_to_delete_rec": "\u00bfEst\u00e1s seguro de que quieres borrar la grabaci\u00f3n",
        "delete_recording": "Borrar la grabaci\u00f3n",
        "invalid_credentials": "Credenciales inv\u00e1lidas",
        "join_conf_by_clicking_link": "Por favor, \u00fanase a la reuni\u00f3n haciendo clic en el siguiente enlace",
        "valid_room_name": "Por favor, introduzca un nombre de habitaci\u00f3n v\u00e1lido",
        "internal_server_error": "Error interno del servidor, por favor int\u00e9ntelo de nuevo",
        "room_limit_exceed": "L\u00edmite de habitaci\u00f3n excedido",
        "cant_start_meeting_already_goingon": "No puedes empezar una reuni\u00f3n, ya que ya hay una reuni\u00f3n en marcha.",
        "video_recordings": "Grabaciones de video",
        "hindi": "Hindi",
        "gujarati": "Gujarati",
        "kannada": "Kannada",
        "marathi": "Marathi",
        "tamil": "Tamil",
        "telugu": "Telugu",
        "urdu": "Urdu",
        "spanish": "Espa\u00f1ol",
        "malyalam": "Malyalam",
        "italian": "Italiano",
        "bangla": "Bangla",
        "portuguese": "Portugu\u00e9s",
        "create_new_content": "Crear el contenido",
        "create_new_group": "Crear un grupo",
        "noScheMeet_no_meeting_scheduled": "No hay reuniones programadas",
        "noScheMeet_no_upcoming_meetings": "A\u00fan no hay una reuni\u00f3n programada. Puede crear un nuevo horario o comenzar su conferencia instant\u00e1nea ahora.",
        "whiteboard": "Tablero blanco",
        "video_syn": "Video Sync",
        "notepad": "Bloc de notas",
        "enable_handraise": "Habilitar Levantamiento de Mano en un webinar",
        "F": "Configuraci\u00f3n de la reuni\u00f3n",
        "social_integration": "Integraci\u00f3n social",
        "advanced": "Avanzado",
        "add_youtube": "A\u00f1adir a Youtube",
        "plugins": "Plugins",
        "subtitles": "Subt\u00edtulos:",
        "view_local": "Ver Local",
        "hide_local": "Ocultar local",
        "switch_conf_mode": "Cambiar al modo de conferencia",
        "switch_pres_mode": "Cambie al modo de presentador",
        "conference_locked": "Conferencia bloqueada",
        "conference_unlocked": "Conferencia desbloqueada",
        "sharing_tools": "Compartir herramientas",
        "recording": "Grabaci\u00f3n",
        "plz_wait_host_shr_video": "Por favor espere ! El organizador est\u00e1 a punto de compartir un video.",
        "supported_sources_include": "Las fuentes apoyadas incluyen",
        "team_plugins": "Plugins TEAMS",
        "open": "Abrir",
        "click": "Haga clic",
        "more_app": "M\u00e1s aplicaciones",
        "upload_custom_app": "Subir la aplicaci\u00f3n personalizada",
        "download_team_plugin": "Descarga el complemento TEAMS",
        "install_mng_app_microsoft_team": "C\u00f3mo instalar y administrar la aplicaci\u00f3n en los equipos de Microsoft",
        "microsoft_step1": "Paso 1: Abra Microsoft TEAMS",
        "microsoft_step1_para1": "Para comenzar, primero debe descargar el complemento TEAMS en su sistema, disponible en la parte superior derecha de esta p\u00e1gina. Luego abra Microsoft TEAMS y elija",
        "microsoft_step1_para2": "opciones de navegaci\u00f3n para ver las aplicaciones de TEAMS.",
        "microsoft_step2": "Paso 2: accede a m\u00e1s aplicaciones",
        "microsoft_step2_para1": "Aqu\u00ed puedes encontrar algunas aplicaciones junto con un bot\u00f3n llamado M\u00e1s Aplicaciones. Haz clic",
        "microsoft_step2_para2": "para comprobar m\u00e1s a fondo la opci\u00f3n de carga personalizada y otras opciones de la tienda.",
        "microsoft_step3": "Paso 3: Subir la aplicaci\u00f3n",
        "microsoft_step3_para1": "Una vez que abras la tienda, podr\u00e1s encontrar un",
        "microsoft_step3_para2": "en la navegaci\u00f3n de la izquierda. Haz clic en ella y sube tu aplicaci\u00f3n de plugin descargada aqu\u00ed.",
        "microsoft_step4": "Paso 4: Seleccionar la aplicaci\u00f3n InstaVC de almacenamiento",
        "microsoft_step4_para1": "Cuando se abra la ventana, seleccione el complemento InstaVC de su sistema que se ha descargado (paso 1) y haga clic en",
        "microsoft_step5": "Paso 5: carga de la aplicaci\u00f3n Microsoft TEAMS",
        "microsoft_step5_para1": "Despu\u00e9s de descargar el archivo, se carga en la lista de aplicaciones de Microsoft TEAMS. Luego puede hacer clic en la aplicaci\u00f3n para verla y agregarla a los equipos.",
        "microsoft_step6": "Paso 6: Abrir la aplicaci\u00f3n InstaVC",
        "microsoft_step6_para1": "Una vez que haga clic en la aplicaci\u00f3n InstaVC, podr\u00e1 ver los detalles completos de la aplicaci\u00f3n del plugin junto con un bot\u00f3n para",
        "microsoft_step6_para2": "para agregar esta aplicaci\u00f3n de Microsoft TEAMS",
        "microsoft_step7": "Paso 7: InstaVC agregado a Microsoft TEAMS",
        "microsoft_step7_para1": "Su aplicaci\u00f3n ahora se agregar\u00e1 a Microsoft TEAMS en el men\u00fa de navegaci\u00f3n de la izquierda para facilitar su uso y",
        "ddyymm": "DD\/MM\/AAAA",
        "fill_details_for_webinar": "Por favor, rellene sus datos de registro para el seminario web en",
        "microsoft_teams": "Microsoft TEAMS",
        "microsoft_outlook": "Microsoft Outlook",
        "select_the_plan": "Por favor, seleccione el plan",
        "enter_company_code": "Por favor, introduzca la sociedad",
        "enter_code": "Por favor, introduzca el c\u00f3digo",
        "enter_valid_coupon_code": "Por favor, introduzca el c\u00f3digo del cup\u00f3n v\u00e1lido",
        "coupon_alreadt_taken": "Este n\u00famero de cup\u00f3n ya se ha utilizado",
        "error_updating_plan": "Error al actualizar su plan",
        "unit": "unidad",
        "referrals": "Referentes",
        "sel_coupon": "Por favor, seleccione el cup\u00f3n",
        "share_val_0_99": "El valor compartido debe estar entre 0 y 99",
        "enter_share_val": "Por favor, introduzca el valor de la acci\u00f3n",
        "valid_commission_per": "Por favor, introduzca el porcentaje de comisi\u00f3n v\u00e1lido",
        "enter_commission_per": "Por favor, introduzca el porcentaje de la Comisi\u00f3n",
        "enter_commission_name": "Por favor, introduzca el nombre de la Comisi\u00f3n",
        "sync_successfully": "Los usuarios se sincronizan con \u00e9xito",
        "room_deleted_suc": "Habitaci\u00f3n eliminada con \u00e9xito",
        "lic_deleted_suc": "Licencia eliminada",
        "female": "Mujer",
        "male": "Hombre",
        "insta_conference": "Insta Conferencia",
        "quick_meeting_by": "Reuni\u00f3n instant\u00e1nea por",
        "in_lobby": "En la sala de espera",
        "switch_to_broadcast": "Ir a la transmisi\u00f3n",
        "Broadcasters_5": "Emisoras (5)",
        "Participants_5": "Participantes (5)",
        "search_participants": "Busca a los participantes",
        "host_controls": "Controles del organizador",
        "deleteroom": "Eliminar la sala",
        "pm": "PM",
        "am": "AM",
        "email_exist_inroom": "El correo electr\u00f3nico ya existe en esta sala",
        "type_a_message": "Escriba un mensaje...",
        "please_enter_user_limit": "Ingrese el n\u00famero m\u00e1ximo de usuarios",
        "user_limit_exceeded": "Exceder el l\u00edmite del n\u00famero de usuarios posibles",
        "sel_country_code": "Por favor, seleccione el c\u00f3digo de pa\u00eds",
        "available_storage": "Almacenamiento disponible",
        "enter_phone_number": "Introduzca el n\u00famero de tel\u00e9fono",
        "startdate_less_enddate": "por favor, seleccione una fecha de inicio menor a la fecha de finalizaci\u00f3n",
        "havent_add_data": "No has a\u00f1adido ning\u00fan dato todav\u00eda, por favor, a\u00f1ade datos.",
        "professional_skills": "Habilidades profesionales",
        "enter_prof_skills": "Ingresa en Habilidades profesionales",
        "Present_Invitations": "Invitaciones actuales",
        "sending": "Env\u00edo en curso ...",
        "sent": "Enviado a",
        "invite_10ppl_only": "S\u00f3lo puedes invitar a un m\u00e1ximo de 10 personas",
        "join_meeting": "Unirse a la reuni\u00f3n",
        "enter_doc_title": "Introduzca el t\u00edtulo del documento",
        "meeting_name": "Nombre de la reuni\u00f3n",
        "select_the_weeks": "Por favor, seleccione las semanas",
        "grade": "Nota",
        "coference_ID": "ID de conferencia ...",
        "dont_find_room_meetingid": "77\/5000\nNo podemos encontrar ninguna sala asociada con este ID de reuni\u00f3n",
        "wireless_share": "Compartir inal\u00e1mbrico",
        "video_layout": "Dise\u00f1o de video",
        "speaker_screen": "Pantalla de altavoz",
        "presentation_screen": "Pantalla de presentaci\u00f3n",
        "multiple_display": "Pantalla m\u00faltiple",
        "hellow": "Buenos dias",
        "enter_passcode": "Introduce la clave de acceso",
        "another_meeting_stillon": "Otra reuni\u00f3n sigue en pie, no puedes empezar esta reuni\u00f3n ahora mismo.",
        "SIP_device": "Dispositivo SIP",
        "instavc_whiteBoard": "InstaVC WhiteBoard",
        "speaker": "Orador",
        "instavc_notepad": "Bloc de notas InstaVC",
        "promote_in": "Promover en",
        "guest": "Invitado",
        "breakout_rooms": "Salas de descanso",
        "stop_broadcast": "Detener la emisi\u00f3n",
        "noise_suppress": "Supresi\u00f3n del ruido",
        "echo_cancel": "Cancelaci\u00f3n del Eco:",
        "enter_name_here": "Introduzca el nombre aqu\u00ed",
        "Viewer": "Visor",
        "SIP_user": "Usuario SIP",
        "remove_participants": "Eliminar los participantes",
        "disable_audio_video": "31\/5000\nApagar audio y video",
        "disable_audio": "Desactivar el audio",
        "enable_audio": "Activar el audio",
        "disable_video": "Deshabilitar el video",
        "enable_video": "Habilitar el video",
        "audio_video": "Audio y Video",
        "audio_only": "S\u00f3lo audio",
        "video_only": "S\u00f3lo video",
        "outlook_addins": "Complementos de Outlook",
        "install_microsoft": "C\u00f3mo instalar y administrar complementos en Microsoft Outlook",
        "outlook_step1": "Paso 1: Copiar el enlace y abrir Outlook",
        "first_copy_link": "Primero copie el enlace",
        "add_plugin_oulook": "de aqu\u00ed para a\u00f1adir el plugin a tu perspectiva.",
        "outlook_step2": "Paso 2: Obtener complementos",
        "outlook_step2_para": "Ahora abra Outlook y en la pesta\u00f1a Inicio encontrar\u00e1 la opci\u00f3n \"Obtener complementos\". Haga clic en \u00e9l para ver los complementos y otras opciones personalizadas.",
        "outlook_step3": "Paso 3: Mis complementos",
        "outlook_step3_para": "Ahora puede ver la p\u00e1gina de complementos con la navegaci\u00f3n izquierda. Vaya a Mis complementos para administrar sus complementos.",
        "outlook_step4": "Paso 4: agregar complementos personalizados",
        "outlook_step4_para": "Aqu\u00ed encontrar\u00e1 un men\u00fa desplegable \"Agregar un complemento personalizado\" en \"Mis complementos\". Seleccione Agregar desde URL en la lista desplegable.",
        "outlook_step5": "Paso 5: agregar un complemento privado desde la URL",
        "outlook_step5_para": "Ahora puede ver una ventana que le pide que agregue un complemento privado desde la URL. Ahora pegue el enlace que copi\u00f3 (paso 1) en el campo de entrada y haga clic en Aceptar, esto instala el complemento en Outlook.",
        "outlook_step6": "Paso 6: InstaVC agregado en mis complementos de Outlook",
        "outlook_step6_para": "Despu\u00e9s de agregarlo, puede verificar el complemento agregado en su lista \"Mis complementos\". Desde all\u00ed, puede administrar sus complementos con m\u00e1s detalle.",
        "outlook_step7": "Paso 7: Cerrar Outlook y reabrir",
        "outlook_step7_para": "Despu\u00e9s de la instalaci\u00f3n, aseg\u00farese de cerrar Oultlook una vez y volver a abrirlo para ver la extensi\u00f3n.",
        "outlook_step8": "Paso 8: Ir a los nuevos art\u00edculos",
        "outlook_step8_para": "Despu\u00e9s de abrir Outlook, ahora puede ir a \"Nuevas reuniones\" para ver la incorporaci\u00f3n de InstaVC en Outlook.",
        "outlook_step9": "Paso 9: Abra una reuni\u00f3n de InstaVC",
        "outlook_step9_para": "Una vez que abra las reuniones, se le abrir\u00e1 una ventana de correo de composici\u00f3n junto con el complemento InstaVC Meeting habilitado en la barra superior, en la pesta\u00f1a de reuniones.",
        "outlook_step10": "Paso 10: Crear el programa",
        "outlook_step10_para": "En la p\u00e1gina de redacci\u00f3n de correo, haga clic en Reuniones de Instavc para crear una nueva programaci\u00f3n de reuniones. Si es la primera vez que inicia sesi\u00f3n en una reuni\u00f3n de Instavc, iniciar\u00e1 sesi\u00f3n con el inicio de sesi\u00f3n de la reuni\u00f3n de Instavc en el lado derecho. Para los usuarios que ya iniciaron sesi\u00f3n, ser\u00e1n dirigidos a la lista de salas y podr\u00e1n programar una reuni\u00f3n.",
        "outlook_step11": "Paso 11: Abra una reuni\u00f3n de InstaVC",
        "outlook_step11_para": "Puede programar el calendario de reuniones en el lado izquierdo y seleccionar una sala en el lado derecho para programar una reuni\u00f3n en una sala en particular. Una vez que haya creado un calendario, puede ver el bot\u00f3n \"Iniciar reuni\u00f3n\". Desde all\u00ed, puede hacer clic e iniciar la reuni\u00f3n.",
        "outlook_step12": "Paso 12: Ver el programa en el calendario",
        "outlook_step12_para": "Una vez que haya programado una reuni\u00f3n, la pr\u00f3xima vez que abra el calendario, tambi\u00e9n podr\u00e1 consultar sus horarios en el calendario.",
        "outlook_step13": "Paso 13: reuni\u00f3n programada",
        "outlook_step13_para": "Una vez que haya programado una reuni\u00f3n, podr\u00e1 ver el correo redactado, as\u00ed como el bot\u00f3n \"Iniciar reuni\u00f3n\" en el lado derecho.",
        "sel_declaration": "Por favor, seleccione la declaraci\u00f3n",
        "invalid_company_code": "Sociedad Inv\u00e1lida",
        "affiliate_acc": "Su cuenta de afiliado est\u00e1 pendiente de aprobaci\u00f3n.",
        "aff_acc_approved": "Su cuenta de afiliado est\u00e1 aprobada.",
        "aff_acc_rej": "Su cuenta de afiliado es rechazada.",
        "aff_TC": "T\u00e9rminos y condiciones para los afiliados",
        "ref_par_agreement": "ACUERDO DE SOCIO DE REFERENCIA",
        "aff_partner_agreement": "Este Acuerdo de Socios, que consiste en esta Portada y los T\u00e9rminos Comerciales adjuntos (colectivamente, este \"Acuerdo\"), se hace y se celebra por y entre PeopleLink Unified Communications Pvt Ltd (en adelante, PeopleLink) y el \"Socio\" o \"Usted\". \"Socio\" o \"Usted\" significa la persona o compa\u00f1\u00eda (incluyendo el nombre de la compa\u00f1\u00eda y otra informaci\u00f3n) detallada en el formulario enviado a. El Acuerdo ser\u00e1 vinculante una vez que Usted complete y env\u00ede el Formulario del Programa de Socios de Referencia (\"Formulario\") para indicar su aceptaci\u00f3n de este Acuerdo. A veces se hace referencia a PeopleLink y al Socio como una \"Parte\", y juntos como las \"Partes\".",
        "background": "Antecedentes",
        "aff_market_advertise": "PeopleLink ofrece servicios y soluciones de comunicaci\u00f3n unificada InstaVC. El socio desea promover, comercializar y anunciar los productos y soluciones de PeopleLink a los clientes potenciales de PeopleLink (\"Referencias\") a trav\u00e9s de su(s) sitio(s) web y otros canales de comercializaci\u00f3n, de conformidad con el presente Acuerdo.",
        "agreement": "Acuerdo",
        "aff_parties_agree": "Las Partes acuerdan lo siguiente:",
        "heading1": "COMPROMISOS DEL PROGRAMA",
        "heading2": "REFERENTES CUALIFICADOS Y COMISIONES.",
        "heading3": "PLAZO Y TERMINACI\u00d3N.",
        "heading4": "GENERAL",
        "the_program": "El Programa.",
        "to_participate_program_partner": "Para participar en el Programa, el Socio del Programa debe completar la solicitud en l\u00ednea para la participaci\u00f3n en el Programa que se encuentra en PeopleLink o en sus sitios web asociados (\"Sitio\") e ingresar en este Acuerdo. PeopleLink puede aceptar o rechazar cualquier solicitud a su entera discreci\u00f3n.",
        "aff_str11": "Acuerdos legales.",
        "aff_str12": "Actividades de promoci\u00f3n y remisi\u00f3n:",
        "aff_str13": "Actividades prohibidas.",
        "aff_str14": "Uso permisible de las marcas PeopleLink",
        "aff_str15": "Pasivos.",
        "aff_str16": "Relaciones con los clientes.",
        "aff_str11_para": "Como parte de su participaci\u00f3n en el Programa y al actuar como Socio de PeopleLink, el Socio por la presente acepta y consiente los t\u00e9rminos de este Acuerdo y del Programa, las Directrices y cualquier otra solicitud y reglas establecidas por PeopleLink de vez en cuando, a su razonable discreci\u00f3n, en relaci\u00f3n con la participaci\u00f3n continua del Socio en el Programa y la promoci\u00f3n de los productos, soluciones y servicios de PeopleLink a los referidos. En todas sus actividades bajo este Acuerdo, y espec\u00edficamente en aquellas actividades relacionadas con la promoci\u00f3n de PeopleLink por parte del Socio, \u00e9ste cooperar\u00e1 con PeopleLink y actuar\u00e1 de buena fe. Al entrar en este Acuerdo, el Socio reconoce y acepta adem\u00e1s los t\u00e9rminos y reglas establecidos en los T\u00e9rminos de Servicio de PeopleLink",
        "aff_str12_para": "El Socio se compromete a participar en la promoci\u00f3n continua y activa de los Productos y Soluciones PeopleLink en varios canales de comercializaci\u00f3n utilizando las Marcas Licenciadas y los Materiales de Comercializaci\u00f3n, y a hacerlo en cumplimiento de los t\u00e9rminos de este Acuerdo.",
        "aff_str13_para": "El socio acuerda no asociar los materiales de marketing con contenido que sea ilegal en cualquier forma, o que sea de alguna manera da\u00f1ino, amenazante, difamatorio, obsceno, ofensivo, acosador, sexualmente expl\u00edcito, violento, discriminatorio, o de alguna manera objetable a la sola discreci\u00f3n de PeopleLink. El socio acuerda no enviar mensajes electr\u00f3nicos no solicitados a m\u00faltiples destinatarios no relacionados (\"Spamming\") en la promoci\u00f3n de los servicios y soluciones de PeopleLink, o de otra manera participar en cualquier otra forma de comunicaciones electr\u00f3nicas masivas prohibidas por la ley en relaci\u00f3n con las actividades contempladas en este Acuerdo.",
        "aff_str14_para1": "El Socio acuerda expresamente cumplir con todos los t\u00e9rminos aqu\u00ed expuestos en el uso de las Marcas Licenciadas y en la creaci\u00f3n de Materiales de Marketing.",
        "aff_str14_para2": "A trav\u00e9s de las Directrices y de otro modo, PeopleLink proporcionar\u00e1 especificaciones y otras instrucciones de vez en cuando en cuanto al uso permitido por el Socio de las Marcas Licenciadas en la creaci\u00f3n de Materiales de Marketing y la promoci\u00f3n del Servicio PeopleLink. El Socio tambi\u00e9n acepta cumplir con todas esas especificaciones e instrucciones.",
        "aff_str14_para3": "El Socio se asegurar\u00e1 de que todas las marcas licenciadas que aparezcan en sus materiales de marketing se encuentren en la forma aprobada por PeopleLink en las Directrices o de otra manera, no modificar\u00e1 ninguna marca de PeopleLink ni modificar\u00e1 sustancialmente otros materiales de marketing en contra de las instrucciones razonables proporcionadas por PeopleLink, y adem\u00e1s cumplir\u00e1 con las instrucciones razonables de PeopleLink en cuanto a la forma, el contenido y la visualizaci\u00f3n de los materiales de marketing. A la terminaci\u00f3n de este Acuerdo por cualquier raz\u00f3n, o a petici\u00f3n escrita de PeopleLink, la licencia otorgada en el presente documento expirar\u00e1 y el Socio cesar\u00e1 inmediatamente todas sus actividades bajo este Acuerdo.",
        "aff_str15_para": "El Socio ser\u00e1 el \u00fanico responsable de sus operaciones al actuar en virtud del presente Acuerdo, incluyendo, sin limitaci\u00f3n, la legalidad de las operaciones y materiales del Socio, creados y utilizados en relaci\u00f3n con el presente Acuerdo. Excepto por una demanda que alegue que una marca de PeopleLink viola los derechos de marca de un tercero, PeopleLink no es responsable por el desarrollo, operaci\u00f3n o contenido de los materiales de mercadeo del socio y el socio acuerda defender, indemnizar y mantener a PeopleLink libre de responsabilidad contra cualquier demanda, acci\u00f3n, causa de acci\u00f3n, da\u00f1os o gastos (incluyendo honorarios de abogados) relacionados con el desarrollo, operaci\u00f3n, contenido y mantenimiento de los materiales de mercadeo del socio.",
        "aff_str16_para": "Durante y despu\u00e9s del Plazo, PeopleLink ser\u00e1 el propietario exclusivo de todas las relaciones creadas a trav\u00e9s de Socio entre PeopleLink y los Referentes con respecto al Servicio de PeopleLink, incluyendo toda la informaci\u00f3n que identifique a los Referentes que contraten con PeopleLink para el uso del Servicio de PeopleLink. Los T\u00e9rminos, la Pol\u00edtica de Privacidad y las reglas y procedimientos de PeopleLink para el Servicio de PeopleLink se aplicar\u00e1n a estos Referidos y podr\u00e1n ser cambiados por PeopleLink sin previo aviso al Socio, y el Socio acuerda transmitir a los Referidos la naturaleza de sus relaciones con PeopleLink bajo los T\u00e9rminos.",
        "aff_str20_para1": "remitidos por el socio a PeopleLink y que completen el procedimiento de inscripci\u00f3n de acuerdo con el procedimiento descrito en la secci\u00f3n 3 b) infra;",
        "aff_str20_para2": "de los cuales PeopleLink no tiene ning\u00fan registro en relaci\u00f3n con el Servicio de PeopleLink, o que no est\u00e1n, en el momento de ser remitidos a PeopleLink por el Socio, en ninguna relaci\u00f3n contractual o negociaci\u00f3n en curso con PeopleLink en relaci\u00f3n con el Servicio de PeopleLink;",
        "aff_str20_para3": "que acepten los T\u00e9rminos y que adquieran, a discreci\u00f3n del Remitente y sin recibir ning\u00fan incentivo monetario o de otro tipo por parte del Socio, al menos suscripciones anuales a cualquiera de los siguientes paquetes de planes de servicio de PeopleLink que aparecen en los sitios web de PeopleLink o de otro modo (iv) que no sean rechazados por PeopleLink, y que realicen al menos un pago (prepago anual) o tres pagos (plazo anual, facturaci\u00f3n mensual) para recibir el Servicio de PeopleLink.",
        "aff_str21": "Procedimiento de remisi\u00f3n.",
        "aff_str22": "Comisiones. Responsabilidades.",
        "aff_str221": "Honorarios de referencia.",
        "aff_str222": "Las ventas en curso de una remisi\u00f3n",
        "aff_str223": "Cargos asociados.",
        "aff_str23": "Informes de Ventas\/Comisiones.",
        "aff_str21_para": "Cada Referencia ser\u00e1 remitida a PeopleLink por el Socio a trav\u00e9s de una url en l\u00ednea proporcionada por PeopleLink al Socio, PeopleLink ser\u00e1 responsable del proceso de ventas a todas las Referencias, sujeto a la continua cooperaci\u00f3n de buena fe de las Partes en la promoci\u00f3n del proceso de ventas a la Referencia.",
        "aff_str22_para": "PeopleLink cobrar\u00e1 todos los honorarios de los referidos del servicio PeopleLink directamente de los referidos.",
        "aff_str22_para1": "Una vez que un referido se convierta en un referido calificado, PeopleLink pagar\u00e1 a los socios que est\u00e9n atrasados en el pago de las tarifas de referido basadas en la lista de tarifas que se encuentra en",
        "aff_str22_para12": "(excluyendo cualquier descuento) a pagar por la Referencia Calificada. Tales honorarios de remisi\u00f3n ser\u00e1n pagaderos y se abonar\u00e1n al Socio en un plazo aproximado de (<a\u00f1adir) noventa (90) d\u00edas a partir de la fecha de inicio del servicio pagado, una vez cumplidas las obligaciones de pago.",
        "aff_str22_para2": "- El socio recibir\u00e1 honorarios de remisi\u00f3n por las ventas adicionales de una remisi\u00f3n calificada que se produzcan dentro de los primeros 90 d\u00edas a partir de la fecha en que se present\u00f3 inicialmente la remisi\u00f3n.",
        "aff_str22_para3": " El Socio ser\u00e1 responsable del pago de todos los impuestos, derechos, cargos gubernamentales y otros cargos similares que se impongan a los Honorarios de Referencia, y el Socio indemnizar\u00e1, defender\u00e1 y mantendr\u00e1 indemne a PeopleLink de y contra cualquier reclamaci\u00f3n que surja o se relacione con todos los cargos que emanen del pago de los Honorarios de Referencia por parte de PeopleLink.",
        "aff_str23_para": "PeopleLink proporcionar\u00e1 al Socio en su informe del tablero de mandos en l\u00ednea un resumen de las actividades de ventas del Socio y sus comisiones por las remisiones calificadas derivadas de las remisiones. PeopleLink no revelar\u00e1 los nombres u otra informaci\u00f3n personal sobre los Referidos y los Referidos Calificados.",
        "aff_str31": "T\u00e9rmino inicial.",
        "aff_str32": "Plazo de renovaci\u00f3n.",
        "aff_str33": "Terminaci\u00f3n temprana. Sin causa.",
        "aff_str34": "Por la causa.",
        "aff_str35": "Efecto de la terminaci\u00f3n.",
        "aff_str31_para": "El presente Acuerdo entrar\u00e1 en vigor a partir de la fecha de entrada en vigor y continuar\u00e1 durante doce (12) meses a partir de entonces (\"Plazo inicial\"), a menos que PeopleLink rechace la solicitud de los Socios para participar en el Programa",
        "aff_str32_para": "Tras la expiraci\u00f3n de la Vigencia Inicial, el presente Acuerdo se renovar\u00e1 autom\u00e1ticamente por per\u00edodos consecutivos adicionales de doce (12) meses (cada uno, \"Plazo de Renovaci\u00f3n\"), a menos que una Parte notifique por escrito la terminaci\u00f3n a la otra Parte con al menos treinta (30) d\u00edas de antelaci\u00f3n a la finalizaci\u00f3n de la Vigencia Inicial o de cualquier Plazo de Renovaci\u00f3n.",
        "aff_str33_para": "PeopleLink tendr\u00e1 el derecho de terminar este Acuerdo en cualquier momento por cualquier raz\u00f3n o sin ella, dando cinco (5) d\u00edas de aviso previo por escrito al Socio.",
        "aff_str34_para": "Cualquiera de las Partes podr\u00e1 rescindir el presente Acuerdo en cualquier momento, con efecto inmediato tras la notificaci\u00f3n por escrito a la otra Parte que haya incumplido materialmente el presente Acuerdo, siempre que antes de rescindir el presente Acuerdo la Parte que lo rescinda proporcione una notificaci\u00f3n por escrito de dicho incumplimiento material y treinta (30) d\u00edas de oportunidad para que la Parte incumplidora subsane dicho incumplimiento.",
        "aff_str35_para": "A partir de la fecha de terminaci\u00f3n del presente Acuerdo, los derechos del Socio en virtud del presente Acuerdo terminar\u00e1n, y el Socio no tendr\u00e1 derecho a recibir ning\u00fan Honorario de Referencia o cualquier otro pago en virtud del presente Acuerdo que no sean las comisiones o los pagos ganados o acumulados antes de la terminaci\u00f3n del presente Acuerdo.",
        "aff_str41": "Asignaci\u00f3n.",
        "aff_str42": "Derechos de propiedad intelectual.",
        "aff_str43": "No hay renuncia.",
        "aff_str44": "Garant\u00eda limitada.",
        "aff_str45": "Descargo de responsabilidad de la garant\u00eda.",
        "aff_str46": "Limitaci\u00f3n de la responsabilidad.",
        "aff_str47": "Contratistas independientes.",
        "aff_str48": "Indemnizaci\u00f3n.",
        "aff_str49": "Fuerza Mayor.",
        "aff_str410": "Acuerdo completo; divisibilidad.",
        "aff_str411": "Contrapartes; Avisos.",
        "aff_str412": "Derecho aplicable; jurisdicci\u00f3n; resoluci\u00f3n de controversias.",
        "aff_str41_para": "PeopleLink podr\u00e1 ceder este Acuerdo en cualquier momento. El socio no puede asignar o transferir este Acuerdo sin el consentimiento previo y por escrito de PeopleLink, dicho consentimiento no debe ser retenido sin raz\u00f3n.",
        "aff_str42_para": "Todos los derechos de propiedad intelectual (como, por ejemplo, pero sin limitarse a, marcas comerciales, nombres comerciales, logotipos, patentes, derechos de autor, nombres de dominio y derechos derivados) de las marcas de PeopleLink, el servicio PeopleLink y el contenido y la tecnolog\u00eda relacionados en todo el mundo (derechos de propiedad intelectual de PeopleLink) son y seguir\u00e1n siendo propiedad exclusiva de PeopleLink y sus empresas subsidiarias. El derecho del Socio a utilizar las Marcas Licenciadas es a discreci\u00f3n de PeopleLink y est\u00e1 sujeto al cumplimiento por parte del Socio de los t\u00e9rminos de este Acuerdo, las Directrices y todas las leyes y regulaciones aplicables. El socio se compromete a (a) no utilizar ning\u00fan derecho de propiedad intelectual de PeopleLink de ninguna manera que pueda ser razonablemente probable que viole este Acuerdo; (b) no hacer nada que impugne o perjudique ning\u00fan derecho de propiedad intelectual de PeopleLink; (c) no crear ni obtener ning\u00fan derecho de propiedad intelectual (como por ejemplo, pero no limitado a marcas comerciales, nombres comerciales, logotipos, patentes, derechos de autor, nombres de dominio y derechos derivados) que sean sustancialmente similares a cualquier derecho de propiedad intelectual de PeopleLink; (d) notificar r\u00e1pidamente a PeopleLink de cualquier uso no autorizado de cualquier derecho de propiedad intelectual de PeopleLink del que el socio tenga conocimiento real; y (e) utilizar siempre las marcas licenciadas y cualquier otra marca de PeopleLink en cumplimiento de las Directrices. PeopleLink puede realizar revisiones peri\u00f3dicas de cualquier material de marketing presentado por el socio, y tendr\u00e1 la autoridad y discreci\u00f3n exclusiva para ordenar la eliminaci\u00f3n y\/o modificaci\u00f3n de cualquier material de marketing presentado por el socio.",
        "aff_str43_para": "El hecho de que una de las Partes no haga cumplir estrictamente a la otra Parte cualquier disposici\u00f3n del presente Acuerdo no constituir\u00e1 una renuncia al derecho de la primera Parte a hacer cumplir posteriormente dicha disposici\u00f3n o cualquier otra disposici\u00f3n del presente Acuerdo.",
        "aff_str44_para": "Ambas partes garantizan que en todo momento durante el plazo de vigencia cumplir\u00e1n con todas las leyes, regulaciones, c\u00f3digos de pr\u00e1ctica aplicables, as\u00ed como con este acuerdo, los t\u00e9rminos, la pol\u00edtica de privacidad y las directrices. Durante el T\u00e9rmino y despu\u00e9s de su terminaci\u00f3n por cualquier raz\u00f3n, el Socio se compromete expresamente a no hacer nada que pueda esperarse razonablemente que da\u00f1e el negocio, los intereses o la reputaci\u00f3n de PeopleLink y no har\u00e1, publicar\u00e1 o permitir\u00e1 que se haga o publique ning\u00fan comentario despectivo sobre PeopleLink, sus representantes o el Servicio de PeopleLink.",
        "aff_str45_para": "Aparte de la garant\u00eda expresa de PeopleLink bajo la subsecci\u00f3n anterior (e), PeopleLink no ofrece ninguna otra garant\u00eda, expresa o impl\u00edcita, de ning\u00fan tipo y PeopleLink renuncia expresamente a cualquier garant\u00eda y condici\u00f3n, incluyendo pero no limit\u00e1ndose a cualquier garant\u00eda impl\u00edcita de comerciabilidad, adecuaci\u00f3n para un prop\u00f3sito particular, disponibilidad, seguridad, t\u00edtulo, y\/o no infracci\u00f3n del objeto de este Acuerdo.",
        "aff_str46_para": "Ni PeopleLink ni ning\u00fan funcionario, empleado, director o cualquier otro representante de PeopleLink ser\u00e1 responsable ante el Socio o ante cualquier tercero, bajo o en relaci\u00f3n con este Acuerdo o su terminaci\u00f3n, en contrato, pre-contrato, agravio o de otra manera por (i) cualquier p\u00e9rdida econ\u00f3mica (incluyendo p\u00e9rdida de ingresos, ganancias, contratos, negocios o ahorros anticipados) o (ii) cualquier p\u00e9rdida de buena voluntad o reputaci\u00f3n. Tales p\u00e9rdidas incluyen, sin limitaci\u00f3n, cualquier p\u00e9rdida o da\u00f1o especial, indirecto, incidental, estatutario, punitivo o consecuente, as\u00ed como cualquier p\u00e9rdida o da\u00f1o causado por la interrupci\u00f3n de las operaciones. A pesar de cualquier otra circunstancia o entendimiento que rodee cualquier relaci\u00f3n entre las partes, la responsabilidad total de PeopleLink hacia el socio bajo este Acuerdo no exceder\u00e1 de INR (s\u00f3lo mil rupias indias) por cualquier y todo reclamo por da\u00f1os de cualquier tipo hecho por el socio bajo este Acuerdo, y al entrar en este Acuerdo el socio reconoce las limitaciones aqu\u00ed en la responsabilidad de PeopleLink.",
        "aff_str47_para": "Las Partes act\u00faan en su propio nombre como contratistas independientes. Nada en el presente Acuerdo crear\u00e1 ninguna empresa conjunta, agencia, franquicia, representante de ventas, empleo o cualquier otra relaci\u00f3n entre las Partes m\u00e1s all\u00e1 de las relaciones establecidas en el presente Acuerdo, y el Socio queda expresamente excluido de actuar en nombre de PeopleLink. La exhibici\u00f3n por parte del Socio de las Marcas con Licencia bajo este Acuerdo, otro contenido presentado por el Socio, o el contacto entre el Socio y terceros no tergiversar\u00e1 las relaciones descritas en el presente.",
        "aff_str48_para": "El Socio indemnizar\u00e1, defender\u00e1 y mantendr\u00e1 a PeopleLink y sus subsidiarias, afiliados, oficiales y empleados (las \"Partes Indemnizadas de PeopleLink\") libres de cualquier costo, responsabilidad, p\u00e9rdidas y gastos (incluyendo pero no limitado a los honorarios razonables de abogados) que resulten de cualquier demanda, pleito, acci\u00f3n, demanda o procedimiento presentado por cualquier tercero contra las Partes Indemnizadas de PeopleLink que surjan de cualquiera de los siguientes: (i) un incumplimiento del Acuerdo por parte del Socio; (ii) la negligencia, negligencia grave o mala conducta intencional del Socio o sus empleados, agentes o contratistas; o (iii) un incumplimiento por parte del Socio o sus empleados, agentes, contratistas o invitados de las leyes y regulaciones mencionadas anteriormente.",
        "aff_str49_para": "Una Parte no estar\u00e1 obligada a cumplir ninguna de las obligaciones que le incumben en virtud del presente documento si se ve impedida de hacerlo por una situaci\u00f3n de fuerza mayor. Entre los \"casos de fuerza mayor\" se incluyen acontecimientos que escapan al control razonable de las Partes, como casos fortuitos, actos de gobierno, actos de la naturaleza, huelgas o disturbios, as\u00ed como el desempe\u00f1o incorrecto de los proveedores de PeopleLinks o los defectos en los objetos, materiales o software de terceros. Si una situaci\u00f3n de fuerza mayor dura m\u00e1s de treinta (30) d\u00edas, cualquiera de las Partes podr\u00e1 rescindir el presente acuerdo mediante notificaci\u00f3n por escrito a la otra Parte.",
        "aff_str410_para": "El presente Acuerdo representa la totalidad del acuerdo entre las Partes en relaci\u00f3n con el objeto del mismo y las respectivas obligaciones y compromisos de las Partes en \u00e9l. Ning\u00fan otro documento o acuerdo oral o escrito entre las Partes refleja en modo alguno los acuerdos establecidos en el presente Acuerdo, y el presente Acuerdo no puede ser modificado excepto en un escrito ejecutado por ambas partes. . Siempre que sea posible, cada disposici\u00f3n de este Acuerdo se interpretar\u00e1 de tal manera que sea efectiva y v\u00e1lida bajo la ley aplicable, pero si alguna disposici\u00f3n de este Acuerdo fuera prohibida o invalidada por la ley aplicable, dicha disposici\u00f3n ser\u00e1 ineficaz s\u00f3lo en la medida de dicha prohibici\u00f3n o invalidez, sin invalidar el resto de dicha disposici\u00f3n o las disposiciones restantes de este Acuerdo.",
        "aff_str411_para": "El presente Acuerdo podr\u00e1 firmarse en contrapartida y dichas contrapartidas ser\u00e1n v\u00e1lidas y vinculantes para las partes en el mismo, con el mismo efecto que si se hubieran intercambiado las firmas originales.",
        "aff_str412_para": "El presente Acuerdo se regir\u00e1 por las leyes de la jurisdicci\u00f3n de Hyderabad, Telangana (India), sin dar efecto a ning\u00fan principio de conflicto de leyes. La jurisdicci\u00f3n corresponder\u00e1 exclusivamente a los Tribunales de Distrito de Hyderabad, Telangana, India. La \u00fanica y exclusiva jurisdicci\u00f3n y lugar de celebraci\u00f3n de cualquier litigio que surja de este Contrato ser\u00e1 un tribunal de distrito o estatal apropiado ubicado en Hyderabad, Telangana, India, y las Partes acuerdan no plantear, y por la presente renuncian, a cualquier objeci\u00f3n o defensa basada en la no conveniencia del lugar de celebraci\u00f3n o del foro. Antes de iniciar cualquier acci\u00f3n legal que surja en virtud del presente Contrato o en relaci\u00f3n con \u00e9l, una Parte deber\u00e1 notificar por escrito a la otra Parte la existencia de una controversia y las Partes deber\u00e1n negociar activamente y de buena fe con vistas a una r\u00e1pida resoluci\u00f3n de dicha controversia en un plazo de treinta (30) d\u00edas h\u00e1biles a partir de la recepci\u00f3n de dicha notificaci\u00f3n.",
        "aff_str40": "Modificaci\u00f3n del Acuerdo.",
        "aff_str40_para": "PeopleLink puede modificar este Acuerdo de vez en cuando a su razonable discreci\u00f3n publicando un cambio en el Sitio (en www.peoplelinkvc.com\/affiliatestermsofservice) o notificando al Socio por correo electr\u00f3nico. Si el Socio se opone a cualquiera de estos cambios, el Socio puede terminar este Acuerdo por causa justificada. La participaci\u00f3n continua del Socio en el Programa despu\u00e9s de recibir la notificaci\u00f3n sobre los cambios a este Acuerdo constituir\u00e1 la aceptaci\u00f3n vinculante de este Acuerdo seg\u00fan sea enmendado.",
        "qualified_ref": "Referentes calificados",
        "mean_referrals": "promedio de referentes",
        "apply": "APLICAR",
        "company_code_validate": "Por favor, introduzca la sociedad para validar",
        "golive_company_code": "C\u00f3digo de la compa\u00f1\u00eda Golive",
        "accept_TC": "Acepto los t\u00e9rminos y condiciones anteriores",
        "by_sub_referral": "Al enviar la Solicitud de Socio de Referencia, el Socio declara y garantiza que ha le\u00eddo, entendido y aceptado este Acuerdo.",
        "term": "T\u00e9rminos",
        "found_at": "que se encuentra en",
        "our_privacy_policy": "y nuestra Pol\u00edtica de Privacidad",
        "privacy_policy": "Pol\u00edtica de privacidad",
        "as_applicable": "seg\u00fan corresponda.",
        "screen-sharing": "compartir la pantalla",
        "whiteboard_access": "acceso a la pizarra",
        "Document_access": "Acceso a los documentos",
        "share_the_screen": "\u00a1Compartir la pantalla!",
        "to_collaborate": "para colaborar!",
        "share_document": "compartir el documento!",
        "exit_or_end": "\u00bfSalir o finalizar la reuni\u00f3n?",
        "exit_from_meeting": "\u00bfLe gustar\u00eda salir de la reuni\u00f3n?",
        "are_sure_exit_meeting_everyone": "\u00bfEst\u00e1s seguro de que quieres salir de la reuni\u00f3n? Puedes terminar la reuni\u00f3n para todos o puedes salir de la reuni\u00f3n.",
        "are_sure_exit_meeting": "\u00bfEst\u00e1s seguro de que quieres salir de la reuni\u00f3n?",
        "exit_meeting": "Salida de Reuni\u00f3n",
        "end_for_all": "Reuni\u00f3n final para todos",
        "end_meeting": "Fin de la reuni\u00f3n",
        "will_let_alone_exit": "te dejar\u00e1 salir o salir de la reuni\u00f3n.",
        "will_end_for_everyone": "terminar\u00e1 la reuni\u00f3n para todos en la conferencia (Todos los participantes y asistentes).",
        "user_already_exists": "Los usuarios ya existen",
        "already_user_avail": "Ya hay algunos usuarios presentes en la reuni\u00f3n.",
        "if_like_continue": "Si desea continuar, haga clic en",
        "force_start_meetinh": "La fuerza comienza otra reuni\u00f3n",
        "otherwise_click": "de lo contrario haga clic en",
        "to_go_back": "para volver.",

        //New by Keerthi - 17th Sep 2020
        "lang_settings": "Opciones de lenguaje",
        "lang_info": "Elija su idioma preferido para usar la aplicación.",
        "select_language": "Seleccione el idioma",
        "settings_languages": "Idiomas",

        "rec_plugin": "Recording Plugin",
        "rec_heading1": "How to install and manage inVC Screen-Video Recorder with the plugin",
        "rec_step1": "Step 1: Copy & open Link in Chrome",
        "rec_step1_para": "First copy the link ",
        "rec_step1_para_br": "from here and open it in chrome to add the plugin to your browser. Click on ",
        "rec_step1_para_bold": "Add to Chrome",
        "rec_step1_para_ct": "to add this extension.",

        "rec_step2": "Step 2: Pop Up for Permission",
        "rec_step2_para_br": "When you click on Add to chrome, here appears a popup asking for permission to display notification access your content. Click on ",
        "rec_step2_para_bold": "Add Extension",
        "rec_step2_para_ct": "to proceed.",

        "rec_step3": "Step 3: Extension Added to Chrome",
        "rec_step3_para": "When the extension is added to chrome, it shows a window giving you an information with a plugin icon and how to manage this plugin from here.",

        "rec_step4": "Step 4: Pin the plugin on the toolbar",
        "rec_step4_para_br": "To pin the plugin to your toolbar, you need to click on manage extensions, go to ",
        "rec_step4_para_bold": "InstaVC Screen-Recording",
        "rec_step4_para_ct": "and click on the pin icon to add.",

        "rec_step5": "Step 5: Extension/Plugin Pinned to Toolbar",
        "rec_step5_para": "Once the extension is pinned, you can now see an instavc recording icon in your browser toolbar and start using it for easy access.",

        "rec_step6": "Step 6: InstVC Recorder Setup",
        "rec_step6_para_br": "Once you have installed the plugin and the next time when you open it, you can now see the ",
        "rec_step6_para_bold": "InstaVC Recorder Setup",
        "rec_step6_para_ct": "page to enable or disable the recording tools. By default both the options will be enabled. Click on ",
        "rec_step6_para_bold1": "Next",
        "rec_step6_para_ct1": "to continue.",

        "rec_step7": "Step 7: Additional Permissions",
        "rec_step7_para_br": "Once the Next button is clicked, system will then show you a popup asking for additional permissions to read and change your data. Click ",
        "rec_step7_para_bold": "Allow",
        "rec_step7_para_ct": "to continue.",

        "rec_step8": "Step 8: Permissions Allowed",
        "rec_step8_para": "After allowing the permission, this page now gets frozen or in a disabled mode from where you can now start exploring recording options now.",

        "rec_heading2": "Desktop Screen-Recording Plugin",

        "rec_step9": "Step 9: Desktop Screen-Recording",
        "rec_step9_para_br": "After allowing all permissions, you can now access recording menus from the plugin extension in your toolbar. Click on it to see further options. Here we have selected ",
        "rec_step9_para_bold": "Desktop Screen Recording",
        "rec_step9_para_ct": "and click on the",
        "rec_step9_para_bold1": "Record",
        "rec_step9_para_ct1": "button to start.",

        "rec_step9a": "Step 9(a): Share your Screen Popup",
        "rec_step9a_para_br": "Now the system shows up this popup asking to select the contents of your screen, whether its an Entire Screen or only application window. Click on the tab which you want to share and click on ",
        "rec_step9a_para_bold": "Share",
        "rec_step9a_para_ct": "button and go to that screen which you want to start recording.",

        "rec_step9b": "Step 9(b): Desktop Screen Recording Started",
        "rec_step9b_para_br": "The Desktop Screen recording is now started and you can see a blink in the toolbar while its in recording mode. Also, you can see a tab at the bottom showing buttons for ",
        "rec_step9b_para_bold": "Stop Sharing",
        "rec_step9b_para_ct": "or",
        "rec_step9b_para_bold1": "Hide",
        "rec_step9b_para_ct1": ". You can see the bottom message on any screen you are opening until you are in the desktop screen recording mode.",

        "rec_step9c": "Step 9(c): Restart / Pause or Stop Desktop Screen Recording",
        "rec_step9c_para_br": "Once you want to stop Recording. Click on the plugin in the toolbar and here you can see the options to ",
        "rec_step9c_para_bold": "Restart, Pause",
        "rec_step9c_para_ct": "and",
        "rec_step9c_para_bold1": "Stop",
        "rec_step9c_para_ct1": "buttons. You can click any of those accordingly.",

        "rec_step9d": "Step 9(d):Recent Recording View",
        "rec_step9d_para_br": "Once you stop the recording from the toolbar, the recent recording will now automatically open the recording in a new tab along with Title and ",
        "rec_step9d_para_bold": "Download",
        "rec_step9d_para_ct": "option in the top and the recording details in the right column.",

        "rec_heading3": "Browser Tab Screen-Recording Plugin",

        "rec_step10": "Step 10: Browser Tab Screen-Recording",
        "rec_step10_para_br": "When we want to select ",
        "rec_step10_para_bold": "Browser Tab",
        "rec_step10_para_ct": "option for recording your browser screen. First open your screen(url) in chrome whichever you want to record and then open the plugin and click on Browser Tab, adjust microphone options and then click",
        "rec_step10_para_bold1": "Record",
        "rec_step10_para_ct1": "button to start recording.",

        "rec_step10a": "Step 10(a): Browser Tab is being Recorded",
        "rec_step10a_para": "Once the Browser tab is started recording, you can see a red dot blinking in your toolbar which indicates the tab is being recorded and also a blue box blinking in your browser title bar",

        "rec_step10b": "Step 10(b): Record another tab",
        "rec_step10b_para_br": "At the time of current screen recording, if you open another url and open the plugin, it will show you the recording status along with an option",
        "rec_step10b_para_bold": "Record This Tab",
        "rec_step10b_para_ct": "if you want to change the recording scree to the new url. Meanwhile the blue box blinking in the title bar of the browser indicates the tab that is being recorded at the moment.",

        "rec_step10c": "Step 10(C): Stop and View recording",
        "rec_step10c_para_br": "Later, when you stop the recording, the browser itself opens the recording in a new tab along with Title and",
        "rec_step10c_para_bold": "Download",
        "rec_step10c_para_ct": "Option at the top and the recording details in the right column.",

        "rec_heading4": "Webcam Recording",

        "rec_step11": "Step 11: Start Webcam Screen Recording",
        "rec_step11_para_br": "When you start with Webcam screen recording, make sure to select the microphone & camera settings among the devices detected by the plugin. and then click on the",
        "rec_step11_para_bold": "Record",
        "rec_step11_para_ct": "button to start with.",

        "rec_step11a": "Step 11(a): Webcam opens Self Video and starts Recording",
        "rec_step11a_para_br": "The recording opens a web page with yourself video with",
        "rec_step11a_para_bold": "Pause",
        "rec_step11a_para_ct": "and",
        "rec_step11a_para_bold1": "Stop",
        "rec_step11a_para_ct1": "recording options at the bottom.",

        "rec_step11b": "Step 11(b): View Webcam Recorded Video",
        "rec_step11b_para": "When you stop the recording in the previous screen, the browser then opens the recording in a new tab to view and download along with the recording details.",

        "rec_heading5": "Top Menus in Recording Plugin",

        "rec_step12": "Step 12: Navigation Menus",
        "rec_step12_para_br": "The plugin has few navigation menus to fetch your data as well as manage different actions related to the plugin. One of them is a",
        "rec_step12_para_bold": "Hamburger Menu",
        "rec_step12_para_ct": "Click on it to open.",

        "rec_step12a": "Step 12(a): Open Hamburger Menu",
        "rec_step12a_para_br": "On click of",
        "rec_step12a_para_bold": "Hamburger Menu",
        "rec_step12a_para_ct": ", you can find 2 tabs named",
        "rec_step12a_para_bold1": "My Recordings",
        "rec_step12a_para_ct1": "and",
        "rec_step12a_para_bold2": "Options",
        "rec_step12a_para_ct2": "which manages all the data you have been recording and options you have enabled or disabled for that plugin. Click on",
        "rec_step12a_para_bold3": "My Recordings",
        "rec_step12a_para_ct3": "to see the videos you have recorded till date.",

        "rec_step12b": "Step 12(b): Access My Recordings",
        "rec_step12b_para_br": "There is another way of accessing",
        "rec_step12b_para_bold": "My Recordings",
        "rec_step12b_para_ct": ", page from the top menu in the plugin which can be considered as a shortcut to navigate.",

        "rec_step12c": "Step 12(c): My Recordings",
        "rec_step12c_para": "Here will be the detailed list of videos(recordings) you have recorded till date. Each video contains the date, duration, size of the video along with delete icon in the thumbnail view. When you click on any video it opens the details in the view recording page. Note that all the videos will be stored locally here.",

        "rec_step12d": "Step 12(d): Delete All recordings",
        "rec_step12d_para_br": "Looking at My recordings Page, there is a more icon in the top right corner which opens the",
        "rec_step12d_para_bold": "Delete All",
        "rec_step12d_para_ct": "option to easily delete all recordings with a single click.",

        "rec_step12e": "Step 12(e): Side Navigation",
        "rec_step12e_para_br": "Instead of opening the plugin again and change, You can directly navigate to another tab from here using the Hamburger Menu in the top left corner of the page that opens the navigation to navigate to the",
        "rec_step12e_para_bold": "Options",
        "rec_step12e_para_ct": "page",

        "rec_heading6": "Plugin Options",

        "rec_step13": "Step 13: Plugin Options",
        "rec_step13_para_br": "On click on Hamburger Menu, you can find another tab named Options which manages all the options you have enabled or disabled for that plugin. Click on",
        "rec_step13_para_bold": "Options",
        "rec_step13_para_ct": "to see the options that are enabled for you.",

        "rec_step13a": "Step 13(a): Recorder Options",
        "rec_step13a_para": "Recorder Options ask you for various options like video storage, Notifications, Keyboard Shortcuts",

        "rec_step13b": "Step 13(b): Recorder Options",
        "rec_step13b_para": "Recorder Options contain various other options like Recording Settings and advanced.",

        "rec_heading7": "Plugin Notifications",

        "rec_step14": "Step 14: Plugin Notifications",
        "rec_step14_para": "On the top navigation part, you can find a bell icon for notifications if any related to instavc screen recording plugin.",

        "rec_step14a": "Step 14(a): Open Notifications",
        "rec_step14a_para": "On click on the notification icon, you can find the list of notifications if any or it shows an empty slide in.",

        "rec_heading8": "Recording Settings",

        "rec_step15": "Step 15: Settings",
        "rec_step15_para_br": "Then comes the last navigation menu on the top right corner named",
        "rec_step15_para_bold": "Settings",
        "rec_step15_para_ct": "where you can open and manage the recording settings accordingly.",

        "rec_step15a": "Step 15(a): Advanced Settings Options",
        "rec_step15a_para": "Here you can adjust the settings based on the Resize tab, Limit resolution and maximum frame rate which are given by plugin.",

        "crteContent_upload_medialist": "Uploaded Media List",
        "content_upld_desc_txt": "The files you upload here will be available to users who view content.",
        "content_browse_txt": "Browse Files to Upload",
        "content_upld_types": "Files with only .jpg, .jpeg, .png, and .pdf are allowed limiting the max size upto 15MB",

        "video_qlty": "Calidad de video",
        "audio_qlty": "Calidad de audio",
        "cntnt_shrng": "Compartir contenido",
        "fdb_poor": "Pobre",
        "fdb_good": "Bien",
        "fdb_avg": "Promedio",
        "fdb_excellent": "Excelente",
        "share_feedback": "Comparta su FeedBack",
        "ovrl_rating": "Calificación general",
        "fdb_cmnts": "Sus comentarios son muy apreciados",
        "no_thnks": "No, gracias",
        "upload_size_max_15mb": "Puede cargar el límite de tamaño máximo es de 15 MB",

        "pastMeeting_feedback": "Realimentación",
        "pastMeeting_comments": "Comentarios",

        "microsoft_webplugin": "Complemento web de Microsoft Outlook",
        "microsoft_installable": "Complemento instalable de Microsoft Outlook",
        "outlook_installable": "Outlook instalable",
        "customization_installer": "Cómo acceder al instalador de personalización",
        "plugin_step1": "Paso 1: haga clic en el enlace para descargar el archivo",
        "click_link_download": "Haga clic en el enlace para descargar el archivo",
        "first_click_link": "Primero haga clic en el enlace de abajo",
        "to_download_file": "para descargar el archivo en su sistema. Luego abra el archivo descargado para comenzar la instalación y haga clic en",
        "install_txt": "Instalar en pc",
        "plugin_step2": "Paso 2: personalización instalada",
        "plugin_step2_para": "Once you start installing, wait untill the file gets installed and you get a popup showing successfully Installed and then close.",
        "plugin_step3": "Paso 3: Abra Outlook y vaya a Reuniones",
        "plugin_step3_para": "Después de la instalación, abra Microsoft Outlook y vaya a las reuniones.",
        "plugin_step3_para1": "Vaya a Nuevo correo electrónico> Reuniones",
        "plugin_step4": "Paso 4: reuniones abiertas",
        "plugin_step4_para": "Una vez que se abre la ventana de reuniones, muévase a la lista de las pestañas anteriores y luego puede continuar haciendo clic en la pestaña",
        "plugin_step4_para1": "Peoplelink",
        "plugin_step5": "Paso 5: programar una conferencia",
        "plugin_step5_para": "En Peoplelink tienes una subpestaña llamada",
        "plugin_step5_para1": "Programar conferencia.",
        "plugin_step5_para2": "Haga clic en él y podrá ver las opciones de Programación en el lado derecho de la ventana. Aquí puede administrar los horarios de inVC.",
        "plugin_step6": "Paso 6: administrar o personalizar horarios",
        "plugin_step6_para": "Aquí puede administrar o personalizar los horarios mediante la lista de habitaciones, horarios y puede crear un nuevo horario desde aquí que lo redirige al navegador y de regreso a Outlook.",
        "plugin_step6_para1": "Una vez que esté de acuerdo con el horario, puede hacer clic en",
        "plugin_step6_para2": "Obtenga el enlace",
        "plugin_step7": "Enlace generado",
        "plugin_step7_para": "Luego, aquí se genera el enlace y se redacta en el correo que incluso puede usar para compartir en línea o continuar.",
        "instavc_schedulemeeting_namesplchar": "No special characters allowed except @ # $ % ^ & * _ - ?",
        "manage_host": "Mange Host",
        "add_hosts": "agregar hosts",
        "add_hosts_desc": "Agregue anfitriones a esta sala escribiendo su dirección de correo electrónico en el cuadro de entrada a continuación",
        "buy_more": "Comprar más",

        "instavc_thanks_for_Verification": "¡Gracias por su Verificación!",
        "instavc_redirected_msg": "Será redirigido pronto a la siguiente página.",
        "instavc_longer_time": "¿Tomando más tiempo para redirigir?",
        "instavc_click_here": "Haga clic aquí",

        "invc_dashboard": "Tablero de mandos",
        "invc_mymeetings": "Mis reuniones",
        "invc_calendar": "Calendario",
        "invc_schedule": "Calendario",
        "invc_today_meetings": "Reuniones de hoy",
        "invc_meetings": "reuniones",
        "invc_no_meetings": "Sin reuniones...",
        "invc_insta_meeting": "Reunión instantánea",
        "invc_join_meeting": "Unirse a la reunión",
        "invc_schedule_meeting": "Programar reunión",
        "invc_share_screen": "Compartir pantalla",
        "invc_nomeeting_desc": "No tienes reuniones próximas.",
        "invc_clear_selection": "Selección clara",
        "invc_claendar_view": "Vista de calendario",
        "invc_schedule_desc": "Programe una nueva reunión con opciones como habilitar la creación de contraseñas de reunión o habilitar la identificación de reunión dinámica, con funciones de reunión básicas y avanzadas.",
        "invc_meeting_title": "Título de la reunión",
        "invc_meeting_name": "Crear un nombre de reunión",
        "invc_meeting_pwd": "CONTRASEÑA DE LA REUNIÓN",
        "invc_meeting_status": "Permitir",
        "invc_meeting_status_pwd": "Crear contraseña de reunión",
        "invc_sel_time": "Seleccionar hora",
        "invc_sel_date_time": "Seleccione la fecha y la hora de la reunión",
        "invc_sel_slot": "Seleccionar ranura",
        "invc_seat_desc": "Seleccione el número de asientos de los participantes",
        "invc_minutes": "min",
        "invc_hours": "hora",
        "invc_custom": "Personalizado",
        "invc_seats": "Asientos",
        "invc_customslot_txt": "Tienes que completar el tiempo personalizado: horas o minutos.",
        "invc_hours_slot": "horas",
        "invc_minutes_slot": "minutos",
        "invc_part_seats": "Asientos de participantes",
        "invc_adv_options": "Opciones avanzadas",
        "invc_enable_waitngroom": "Habilitar sala de espera",
        "invc_allow_guest": "Permita que los invitados controlen",
        "invc_cust_reg_url": "URL de registro personalizado",
        "invc_cust_feed_url": "URL de comentarios personalizados",
        "invc_buy_activate": "Compra/Activa tu licencia",
        "invc_buy_desc": "No tienes ninguna licencia activa. ¡Compre o renueve una nueva licencia y active su membresía para disfrutar nuevamente de la experiencia inVC!.",
        "invc_buy_license": "Compra licencia",
        "invc_upcoming_meeting": "Próxima reunion",
        "invc_enter_mtng_name": "Ingrese un nombre de reunión",
        "invc_host_name": "Nombre de host",
        "invc_joinmtng_desc": "Proporcione la ID de la reunión en el cuadro de entrada a continuación.",
        "invc_meetng_id": "Identificación de la reunión",
        "invc_joinmtng_desc2": "¿No tiene su ID de reunión? Póngase en contacto con su reunión",
        "invc_joinmtng_desc2_host": "Anfitrión",
        "invc_joinmtng_desc2_check": ", o compruébalo en tu",
        "invc_joinmtng_desc2_email": "Email",
        "invc_join_mtng": "Unirse a la reunión y compartir",
        "invc_join_mtng_desc": "Para unirse a Meeting & Share, proporcione el ID de la reunión en el cuadro de entrada a continuación.",
        "invc_join_n_share": "Únete y comparte",
        "invc_purchased": "Comprado en",
        "invc_expires": "Expira el",
        "invc_products": "Producto",
        "invc_license_type": "Tipo de licencia",
        "invc_addseat_reason": "Debido a la falta de disponibilidad de franjas horarias por encima de los asientos de los participantes seleccionados, ha optado por adquirir más licencias.",
        "invc_available_seats": "Asientos disponibles",
        "invc_part_available_seats": "Asientos de los participantes disponibles para esta franja horaria.",
        "invc_available_seats_desc": "Puede programar con 10 asientos para participantes o puede elegir otros espacios disponibles a continuación para 25 asientos para participantes para esta reunión.",
        "invc_more_license": "Adquirir más licencias",
        "invc_more_license_desc": "Obtenga más licencia para programar esta reunión.",
        "invc_create_meeting": "Crear reunión",

        "invc_home_banner_txt": "Las plataformas de videoconferencia WebRTC con una experiencia de usuario inigualable",
        "invc_pastmeetings_meeting_details": "Detalles de la reunión",
        "invc_pastmeetings_meeting_type": "Tipo de reunión",
        "invc_meeting_duration": "Duración",
        "invc_no_conference_recordings": "No hay grabaciones de conferencias disponibles.",
        "invc_frequency": "Frecuencia",
        // "invc_repeat_every":"Repeat Every",
        "invc_no_chat_history": "No hay historial de chat disponible",
        "invc_occurrences": "Ocurrencias",
        "invc_Occurrences_ends": "Termina la ocurrencia",
        // "invc_after":"After",
        "invc_meeting_url": "URL de solo reunión",
        "invc_join_meeting_invite": "Únase a la reunión con o sin invitar a los participantes",
        "invc_schedule_copy_invitation": "Copiar invitación",
        "invc_users_list": "Lista de usuarios",
        "invc_meeting_configuration": "Configuración de la reunión",
        "invc_pwd": "la contraseña",
        "invc_invite_link": "Enlace de invitación",
        "invc_copy_link": "Copiar link",
        "invc_encryption": "Cifrada",
        "coppied": "copiado",
        "conference_layout": "Diseños",
        "layout_democratic": "Democratic",
        "layout_film_strip": "Tira de película",
        "layout_presentation": "Presentación",
        "id": "ID",
        "seats_allotted_for": "asientos asignados para",
        "meeting": "reunión",
        "audio_muted": "¡Tu audio está silenciado!",
        "audio_video_muted": "¡Tu audio y video están silenciados!",
        "video_muted": "¡Tu video está silenciado!",
        "create_new_password": "Crear nueva contraseña",
        "create_password_error": "Su nueva contraseña debe tener al menos 8 caracteres de longitud y contener al menos un símbolo (ejemplo: $), un número (ejemplo: 9) y una letra mayúscula (ejemplo: U).",
        "send_invite": "Enviar invitación",
        "add_participants_by_email_mobileno": "Agregue participantes agregando su dirección de correo electrónico, presione enter para múltiples entradas.",
        "send_invite_note1": " Asegúrese de que el número de teléfono móvil tenga el código de país (Ej: +91-99XXXXXXXX)",
        "send_invite_note2": "La identificación del correo electrónico debe estar en minúsculas (Ej: nombredeusuario@xxx.com)",
        "place_your_order": "Haga su pedido",
        "your_details": "Tus detalles",
        "order_summary": "Resumen del pedido",
        "place_order_msg": "Cuando realiza un pedido, le enviaremos un mensaje de correo electrónico acusando recibo de su pedido.",
        "place_order_button": "¡Haz tu pedido ahora!",
        "license_cost": "Costo de licencia",
        "taxes": "Impuestos",
        "enter_meeting_title": "Por favor ingrese el título de la reunión",
        "invalid_meeting_code": "Código de reunión no válido",
        "fail_create_schedule": "No se pudo crear el horario",
        "fail_create_meeting": "¡No se pudo crear la reunión! intentar otra vez.",
        "plz_try_again": "Inténtalo de nuevo",
        "fail_update_schedule": "No se pudo actualizar el horario",
        "fail_update_meeting": "¡Error al actualizar la reunión! intentar otra vez.",
        "select_2seats": "Seleccione un mínimo de 2 asientos",
        "no_seats_available": "No hay asientos disponibles en este momento, por favor",
        "meeting_for_another": "reunión para otro momento",
        "check_availalble_slots": "Para comprobar los espacios disponibles, vaya a",
        "Cancel": "CANCELAR",
        "no_parcipant_list": "No hay lista de participantes disponible",
        "no_seats_avl_for_another_mtg": "No hay asientos disponibles en este momento, programe la reunión para otro momento",
        "invc_forgot_password_updated": "¡Felicidades! Su nueva contraseña ha sido actualizada, ahora puede iniciar sesión en inVC",
        "updated_password": "Contraseña actualiza",
        "total_chats": "Chats totales",
        "export_data": "Exportar datos",
        "no_conference_notes_avalble": "No hay notas de conferencias disponibles",
        "total_notes": "Notas Totales",
        "p2p_view": "Vista P2P",
        "update_meeting": "Actualizar reunión",
        "invc_nomeeting_past_desc": "No tienes ninguna reunión pasada.",




    }
}