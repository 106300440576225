import React, {useEffect, useContext} from "react";
import { Route, Redirect } from "react-router-dom";
import MainLayout from '../containers/layout/mainLayout';
import { getMyLicense } from "../containers/actions/license";
import {LicenseContext} from "../libs/context";

export default ({ component: C, props: cProps, ...rest }) => {
  const { license, setLicenses } = useContext(LicenseContext);
  useEffect(() => {
    console.log("cProps -=======", cProps);
    if (cProps.isAuthenticated) {
      let email = cProps.userPayload && cProps.userPayload.email ? cProps.userPayload.email : "";
      getMyLicense(email).then(res => {
        console.log("res in authenticaterouteheader=======", res);
        setLicenses([...res]);
      });
    } else {
      setLicenses([]);
    }
  }, [cProps.isAuthenticated]);

  return <Route
    {...rest}
    render={props =>
      cProps.isAuthenticated
        ? <MainLayout headerOff={true} {...props} {...cProps} component={(props) => <C {...cProps} {...props} />} />
        : <Redirect
          to={`/?redirect=${props.location.pathname}${props.location
            .search}`}
        />}
  />;
}

  //</MainLayout><C {...props} {...cProps} />