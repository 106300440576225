import axios from 'axios';


// const api_server = 'https://apicall.instavc.com';
const api_server = 'http://localhost:3030';

function post(name, path, { body }) {
    
    let url = api_server + '/' + name + path;

    console.log(url);

    const headers = {
        'Content-Type': 'application/json',
        token: 'Basic ' + localStorage.getItem('token'),
        sub: localStorage.getItem('sub')
    }
    // console.log("MCu headers==============>", headers);
    return new Promise(function (resolve, reject) {
        axios.post(url, body, { headers })
            .then(res => {
                resolve(res.data)
            }).catch(err => {
                reject(err);
            })
    })

}

function get(name, path) {
    return new Promise(function (resolve, reject) {
        // let url;
        let url = api_server + '/' + name + path;
        const headers = {
            'Content-Type': 'application/json',
            token: 'Basic ' + localStorage.getItem('token'),
            sub: localStorage.getItem('sub')
        }

        console.log("url=====", url);

        axios.get(url, { headers })
            .then(res => {
                // console.log("res === ", res);
                resolve(res.data)
            }).catch(err => {
                reject(err);
            })
    })
}

function put(name, path, { body }) {
    // let url;
    const headers = {
        'Content-Type': 'application/json',
        token: 'Basic ' + localStorage.getItem('token'),
        sub: localStorage.getItem('sub')
    }
    
    let url = api_server + '/' + name + path;

    console.log("url=====", url);

    return new Promise(function (resolve, reject) {

        axios.put(url, body, { headers })
            .then(res => {
                resolve(res.data)
            }).catch(err => {
                reject(err);
            })
    })
}

function del(name, path) {
    
    const headers = {
        'Content-Type': 'application/json',
        token: 'Basic ' + localStorage.getItem('token'),
        sub: localStorage.getItem('sub')
    }
    
    let url = api_server + '/' + name + path;
    return new Promise(function (resolve, reject) {
        axios.delete(url, { headers })
            .then(res => {
                resolve(res)
            }).catch(err => {
                reject(err);
            })
    })
}
function callApi(path, method, body) {
    let url;
    axios.post(url).then(res => {

    })

}

function upload(name, path, body) {
   
    let url = api_server + '/' + name + path;
    console.log(url);
    const headers = {
        'Content-Type': 'multipart/form-data',
        token: 'Basic ' + localStorage.getItem('token'),
        sub: localStorage.getItem('sub')
    }
    // console.log("MCu headers==============>", headers);
    return new Promise(function (resolve, reject) {
        axios({
            method: 'post',
            url: url,
            data: body,
            headers: headers
        }).then((res) => {
            resolve(res.data);
        }).catch(err => {
            reject(err);
        })
    });
}



export const OffLineAPI = {
    post,
    get,
    put,
    del,
    upload
}