import { enData } from './lang-en';
import { frData } from './lang-fr';
import { ptData } from './lang-pt';
import { gjData } from "./lang-Gj";
import { hiData } from "./lang-hi";
import { kaData } from "./lang-ka";
import { maData } from "./lang-Ma";
import { tmData } from "./lang-Ta";
import { teData } from "./lang-te";
import { urData } from "./lang-ur";
import { esData } from "./lang-es";
import { mlData } from "./lang-ml";
import { itData } from "./lang-it";
import { baData } from "./lang-ba";

export const languageObj = {
    en: enData,
    fr: frData,
    pt: ptData,
    gj: gjData,
    hi: hiData,
    ka: kaData,
    ma: maData,
    ta: tmData,
    te: teData,
    ur: urData,
    es: esData,
    ml: mlData,
    it: itData,
    ba: baData
}