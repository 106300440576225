import React, { useContext } from 'react';
import { LanguageContext } from '../../../libs/context';


function HomeLeftBlock() {
    const language = useContext(LanguageContext);
    return (
        <div className="hmResHalfCol resHide jcCenter">
            <div className="flexCol pdngHRes alCenter">
                {/* <div className="hmBrandingBox">
                    <img src="/images/logos/invc-home-logo.png" alt="invc-logo" />
                </div> */}
                <div className="hmBannerFxdBlock">
                    <div className="hmBannerBlock">
                        <div className="flexRow bubblesAbslutCntnr">
                            <ul className="bubbles">
                                <li className="bubblesItem">
                                    <img src="/images/blue/icon24/chat-filled.png" alt="icon" />
                                </li>
                                <li className="bubblesItem"></li>
                                <li className="bubblesItem">
                                    <img src="/images/blue/icon24/layouts-filled.png" alt="icon" />
                                </li>
                                <li className="bubblesItem"></li>
                                <li className="bubblesItem">
                                    <img src="/images/blue/icon24/security-filled.png" alt="icon" />
                                </li>
                                <li className="bubblesItem"></li>
                                <li className="bubblesItem">
                                    <img src="/images/blue/icon24/adduser-filled.png" alt="icon" />
                                </li>
                                <li className="bubblesItem">
                                    <img src="/images/blue/icon24/screenshare-filled.png" alt="icon" />
                                </li>
                                <li className="bubblesItem">
                                    <img src="/images/blue/icon24/speaker-filled.png" alt="icon" />
                                </li>
                                <li className="bubblesItem"></li>
                                <li className="bubblesItem">
                                    <img src="/images/blue/icon24/whiteboard-filled.png" alt="icon" />
                                </li>
                            </ul>

                        </div>
                        <div className='flexRow'>
                            <img src="/images/banners/home-banner.png" alt="home banner" />
                        </div>
                        <div className='flexCol pdngBtmMD'>
                            <h3 className="hmSHTxt">{language.home_instavc_build}</h3>
                            <span className="hmSMTxt">{language.invc_home_banner_txt}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default HomeLeftBlock;