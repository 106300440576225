import React, { useState, useRef, useContext } from 'react';
import { NavLink } from 'react-router-dom';
import "./styles.css";
import Controls from "../../../containers/ui-styles/controls/controls";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { LanguageContext } from "../../../libs/context";

function ResponsiveSideNav(props) {
    const { mobNav, mobShade, handleSlideOut } = props;
    const pathname = window.location.pathname;
    const refActiveIcon = useRef(pathname);
    const language = useContext(LanguageContext);

    return (
        <>
            <div className={mobNav ? "resNav resNavSlideIn" : "resNav"}>
                <div className="resNavHdr">
                    <div className="resNavLogoBlock">
                        {/* <img src="/images/logos/instavc-logo-small-48.png" alt="logo" className="resNavLogo" /> */}
                        <img src="/images/logos/invc-home-logo.png" alt="invc logo" className="resNavLogo" />
                    </div>
                    <div className="resNavCloseBlock">
                        {/* <IconButton aria-label="close" onClick={handleSlideOut}>
                            <CloseIcon />
                        </IconButton> */}
                        <Controls.CloseButton onClick={handleSlideOut} />
                    </div>
                </div>
                <div className="resNavBdy">
                    <div className="overflowBlockCntnr">
                        <div className="overflowCntnt">
                            <div className="resNavListGroup">
                                <div className="resNavListItem" onClick={handleSlideOut}>
                                    <NavLink to="/meeting" activeClassName="anchorActive" className="anchorTxt">
                                        <img ref={refActiveIcon} src="/images/blue/dashboard-24.png" alt="dashboard icon" className={`${pathname.match('/new-dashboard') ? "navListIcon" : "navListIcon invertGray"}`} />
                                        <span>{language.invc_dashboard}</span>
                                    </NavLink>
                                </div>
                                <div className="resNavListItem" onClick={handleSlideOut}>
                                    <NavLink to="/new-meetings" activeClassName="anchorActive" className="anchorTxt" >
                                        <img ref={refActiveIcon} src="/images/blue/my-meetings-24.png" alt="my meetings icon" className={`${pathname.match('/new-meetings') ? "navListIcon" : "navListIcon invertGray"}`} />
                                        <span>{language.invc_mymeetings}</span>
                                    </NavLink>
                                </div>
                                <div className="resNavListItem" onClick={handleSlideOut}>
                                    <NavLink to="/calendar" activeClassName="anchorActive" className="anchorTxt" >
                                        <img ref={refActiveIcon} src="/images/blue/schedule-24.png" alt="schedule icon" className={`${pathname.match('/new-schedule') ? "navListIcon" : "navListIcon invertGray"}`} />
                                        <span>{language.calender_calender}</span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={mobShade ? "resNavShade fadeInShade" : "resNavShade"} onClick={handleSlideOut} />
        </>
    )
}
export default ResponsiveSideNav;