import React, { Component } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';


export default class Loading extends Component {

    render() {
      return (
        <div className="loadingContainer"> 
            <div className="loadingBlock"> 
                <CircularProgress size={50} status="loading" top={50} left={50}  style={{top:"auto", left:"auto", transform:"none"}}/>
                {/* <RefreshIndicator /> */}
            </div>
        </div>
      );
    }
}