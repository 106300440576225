import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(0),
    },
  },
}));

export default function TextButton(props) {
  const classes = useStyles();
  const { color, text, onClick } = props;

  return (
    <div className={classes.root}>
      <Button
      color={color || 'primary'}
      onClick={onClick}
      >{text}</Button>
      
    </div>
  );
}


{/* <Button color="primary">Primary</Button>
      <Button color="secondary">Secondary</Button>
      <Button disabled>Disabled</Button>
      <Button href="#text-buttons" color="primary">
        Link
      </Button> */}