export const config = {
  s3: {
    REGION: "ap-southeast-1",
    BUCKET: "instavc-uploads",
    PROFILEBUCKET: "instavcprofileimages",
    INSTAVCRECORDINGSBUCKET: "instavcrecordings",
    accessKeyId: 'AKIAI26N5NQUPNFOADCA',
    secretAccessKey: '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',
    folder: "content",
    chatfolder: "chat",
    CONTENTBUCKET: "instavc-content",
    webinarfolder: "webinar",
    vcBucket: "invc-vc"
  },

  apiGateway: {
    REGION: "ap-southeast-1",
    URL: "https://3zwm3v4y07.execute-api.ap-southeast-1.amazonaws.com/dev"
  },
  apiDomainGateway: {
    REGION: "ap-southeast-1",
    URL: "https://9t8j88lg0c.execute-api.ap-southeast-1.amazonaws.com/dev"
  },
  apiMcuGateway: {
    REGION: "ap-southeast-1",
    URL: "https://r50ujqynmk.execute-api.ap-southeast-1.amazonaws.com/dev"
  },
  apiPlanGateway: {
    REGION: "ap-southeast-1",
    URL: "https://cmqlgnv8q9.execute-api.ap-southeast-1.amazonaws.com/dev"
  },
  apiCouponGateway: {
    REGION: "ap-southeast-1",
    URL: "https://az5ppjief3.execute-api.ap-southeast-1.amazonaws.com/dev"
  },
  apiTransactionGateway: {
    REGION: "ap-southeast-1",
    URL: "https://bgqcicypd5.execute-api.ap-southeast-1.amazonaws.com/dev"
  },
  apiReferralGateway: {
    REGION: "ap-southeast-1",
    URL: "https://ffmee5izni.execute-api.ap-southeast-1.amazonaws.com/dev"
  },
  apiRecordingGateway: {
    REGION: "ap-southeast-1",
    URL: "https://7wjcuvbr41.execute-api.ap-southeast-1.amazonaws.com/dev"
  },
  apimcuRecordingGateway: {
    REGION: "ap-southeast-1",
    URL: "https://7z4spf90c1.execute-api.ap-southeast-1.amazonaws.com/dev"
  },
  apiContentGateway: {
    REGION: "ap-southeast-1",
    URL: "https://fesn5tk02l.execute-api.ap-southeast-1.amazonaws.com/dev"
  },
  apiScheduleGateway: {
    REGION: "ap-southeast-1",
    URL: "https://2538zqi1c5.execute-api.ap-southeast-1.amazonaws.com/dev"
  },
  apiAddressGateway: {
    REGION: "ap-southeast-1",
    URL: "https://kn78rmyqri.execute-api.ap-southeast-1.amazonaws.com/dev"
  },
  apiMultipartUploadGateway: {
    REGION: "ap-southeast-1",
    URL: "https://tbl46h4bpc.execute-api.ap-southeast-1.amazonaws.com"
  },
  apiMeetingGateway: {
    REGION: "ap-southeast-1",
    URL: "https://shim9zulik.execute-api.ap-southeast-1.amazonaws.com/dev"
  },
  apiMailGateway: {
    REGION: "ap-southeast-1",
    URL: "https://swafkt6tjb.execute-api.ap-southeast-1.amazonaws.com/dev"
  },
  apiBroadcastGateway: {
    REGION: "ap-southeast-1",
    URL: "https://agealag4cb.execute-api.ap-southeast-1.amazonaws.com/dev"
  },
  apiTestGateway: {
    REGION: "ap-southeast-1",
    URL: "https://4sfzodzzs2.execute-api.ap-southeast-1.amazonaws.com/dev"
  },
  apiDocumentShareGateway: {
    REGION: "ap-southeast-1",
    URL: "https://w972k0ix8b.execute-api.ap-southeast-1.amazonaws.com/dev"
  },
  apiChatGateway: {
    REGION: "ap-southeast-1",
    URL: "https://9vpozjb8yd.execute-api.ap-southeast-1.amazonaws.com/dev"
  },
  apiAdminGateway: {
    REGION: "ap-southeast-1",
    URL: "https://wqd2rl0q8i.execute-api.ap-southeast-1.amazonaws.com/dev"
  },
  invcScheduleAPI: {
    REGION: "ap-south-1",
    URL: "https://9ix11kz6od.execute-api.ap-south-1.amazonaws.com/test" //inVC
  },
  invcEmailAPI: {
    REGION: "ap-southeast-1",
    URL: "https://30k8ftiog3.execute-api.ap-southeast-1.amazonaws.com/test"
  },
  invcUserGateway: {
    REGION: "ap-south-1",
    URL: "https://lezmy6hjq3.execute-api.ap-south-1.amazonaws.com/test"
  },
  invcLicenseAPI: {
    REGION: "ap-south-1",
    URL: "https://1kyk2ecl4c.execute-api.ap-south-1.amazonaws.com/test"
  },
  invcChatAPI: {
    REGION: 'ap-south-1',
    URL: "https://f5ah684yh7.execute-api.ap-south-1.amazonaws.com/dev"
  },
  invcManageUser2021API: {
    REGION: 'ap-south-1',
    URL: "https://dnpiw6akg2.execute-api.ap-south-1.amazonaws.com/dev"
  },
  inaffiliateUserAPI: {
    REGION: "ap-south-1",
    URL: "https://uq5bn4du8a.execute-api.ap-south-1.amazonaws.com/dev"
  },
  //ipindia gateway
  ipIndiaGateway: {
    REGION: "ap-southeast-1",
    URL: "https://aa5kqrsqtf.execute-api.ap-southeast-1.amazonaws.com/dev"
  },


  // apiCurrency: {
  //   URL: "https://api.exchangerate-api.com/v4/latest"
  // },
  cognito: {
    REGION: "ap-southeast-1",
    USER_POOL_ID: "ap-southeast-1_3Yr2Ce5QL",
    APP_CLIENT_ID: "25o370dl7jlg0gdfu60n670u81",
    IDENTITY_POOL_ID: "ap-southeast-1:81011fbd-1fa6-44cf-9444-af6be9aa09dc"
  },
  payment: {
    status: true, /* false -test, true- live */
    key: {
      test: "rzp_test_gTK47R1LywsaaW",
      live: "rzp_live_QPqaEyL4FL5Tgj"
    },
    packages: {
      host_basic: {
        test: 'd2520570-af61-11e9-a85e-3da1de138a21',
        live: 'd2520570-af61-11e9-a85e-3da1de138a21',
        price: 252,
        users: 30,
        rooms: 1
      },
      host_medium: {
        test: 'fff007c0-af61-11e9-a85e-3da1de138a21',
        live: 'fff007c0-af61-11e9-a85e-3da1de138a21',
        price: 336,
        users: 50,
        rooms: 1
      },
      host_advanced: {
        test: '27f3b690-af62-11e9-a85e-3da1de138a21',
        live: '27f3b690-af62-11e9-a85e-3da1de138a21',
        price: 492,
        users: 100,
        rooms: 1
      },
      mcu_silver: {
        test: '63a31a00-af62-11e9-a85e-3da1de138a21',
        live: '63a31a00-af62-11e9-a85e-3da1de138a21',
        price: 3960,
        users: 100,
        rooms: 20
      },
      mcu_gold: {
        test: 'c50ed450-af62-11e9-a85e-3da1de138a21',
        live: 'c50ed450-af62-11e9-a85e-3da1de138a21',
        price: 6648,
        users: 200,
        rooms: 30
      },
      mcu_platinum: {
        test: '01207c00-af63-11e9-a85e-3da1de138a21',
        live: '01207c00-af63-11e9-a85e-3da1de138a21',
        price: 13320,
        users: 500,
        rooms: 50
      },
      host_flexi: {
        test: '6d5ea7b0-6f53-11ea-aad5-6dca2a51289b',
        live: '6d5ea7b0-6f53-11ea-aad5-6dca2a51289b',
        price: 1128,
        users: 30,
        rooms: 5
      },
      soho: {
        test: '4c034f00-b6e6-11ea-9c6b-b9ca6490e62f',
        live: '4c034f00-b6e6-11ea-9c6b-b9ca6490e62f',
        price: 99,
        users: 15,
        rooms: 1
      },
      smb100: {
        test: 'e34a6ba0-b6e6-11ea-9c6b-b9ca6490e62f',
        live: 'e34a6ba0-b6e6-11ea-9c6b-b9ca6490e62f',
        price: 140,
        users: 100,
        rooms: 1
      },
      smb300: {
        test: '00ad4f00-b6e7-11ea-9c6b-b9ca6490e62f',
        live: '00ad4f00-b6e7-11ea-9c6b-b9ca6490e62f',
        price: 190,
        users: 100,
        rooms: 1
      },
      vr20: {
        test: 'fc966a60-b6ea-11ea-a177-536325686627',
        live: 'fc966a60-b6ea-11ea-a177-536325686627',
        price: 660,
        users: 20,
        rooms: 2
      },
      vr30: {
        test: '19d216b0-b6eb-11ea-a177-536325686627',
        live: '19d216b0-b6eb-11ea-a177-536325686627',
        price: 1128,
        users: 30,
        rooms: 5
      },
      vr100: {
        test: '383e7ad0-b6eb-11ea-a177-536325686627',
        live: '383e7ad0-b6eb-11ea-a177-536325686627',
        price: 3960,
        users: 100,
        rooms: 20
      },
      vr200: {
        test: '3b5d78a0-b6ec-11ea-a177-536325686627',
        live: '3b5d78a0-b6ec-11ea-a177-536325686627',
        price: 6648,
        users: 200,
        rooms: 30
      },
      vr500: {
        test: '5c028eb0-b6ec-11ea-a177-536325686627',
        live: '5c028eb0-b6ec-11ea-a177-536325686627',
        price: 13320,
        users: 500,
        rooms: 50
      },
    },

  },
  isOfflineMCU: false,
  isOffline: false,
  AppUse: 2, //1 - InstaVC(Intl), 2-InVC (MSoup), 3 - (Jitsi)

  mcuURLifOffline: 'wss://mcu.peoplelinkvc.com:20072',
  iot: {
    awsIotHost: "a3an2ji8k0hxn4-ats.iot.ap-southeast-1.amazonaws.com",
    // socialFacebookAppId: config.FacebookAppId,
    // socialGoogleClientId: config.GoogleAppId,
    logLevel: 1, //config.LogLevel,
    mqttDebugLevel: 1, //config.MqttDebugLevel,
  },
  lang: "fr",
  captchasitekey: '6LfJoOYUAAAAAE0sw6bZqcSwTqvTOJDyR5FRC_er',
  invccaptchasitekey: '6Ld17rkaAAAAAGMHwTxu8FePEdu-lQMxAnZj0QDX',
  sampleparticipantUrl: 'https://instavc-content.s3-ap-southeast-1.amazonaws.com/sampleparticipants.xlsx',
  isClass: false,
  incomingProIp: "13.234.58.164",
  // socketUrl:"https://rphub.instavc.com/"
  // socketUrl: "https://cpanelhububt.instavc.com/"
  socketUrl:"https://ubtcpanelhub.invc.vc/"


};

export var workspace = {
  peer: {
    url: "https://awspeer.instavc.com"
  }
}