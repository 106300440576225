import React, { Component, Suspense, Fragment } from "react";
import { Route } from "react-router-dom";
import { Link } from 'react-router-dom';
import Loading from '../../components/common/Loading'
//import "./Home.css";
import "../ui-styles-new/home/homeLayout.css";
import "../ui-styles-new/home/bannerBubbles.css";
import HomeFooter from "./components/homeFooter";
import HomeLeftBlock from "./components/homeLeftBlock";
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import MenuItem from '@material-ui/core/MenuItem';
import LanguageIcon from '@material-ui/icons/Language';

export default class Home extends Component {
  componentDidMount() {
    console.log("THis Prop", this.props);
  }

  langselect = (event) => {
    const langValue = event.target.value;
    console.log("event", langValue, this);
    this.props.setLanguage(langValue);
    // this.setState((prevState) => 
    //   ({ setlang: langValue })
    // )
    // console.log(this.props);
    // this.setState({ setlang: event.target.value });

  };

  render() {
    let lang = localStorage.getItem('setLang') || this.props.setLang;
    return (
      <div className="hmBlockCntnr">
        <div className="hmFlexCntnt">
          <div className="flexCol jcSB">
            <div className="flexRow hwHeader boxShadowSM respdngHSM brdrBtmSM">
              <div className="flexAuto respdngHSM">
                <div className="flexInline">
                  <img src="/images/logos/invc-home-logo.png" alt="invc-logo" className="hwLogo" />
                </div>
              </div>
              <div className="flexMinWidth respdngHSM jcFE">
                <div className="flexInline">
                  <TextField className="langSelect"
                    select
                    value={lang}
                    onChange={this.langselect}
                    name="langselect"
                    variant="outlined"
                    inputProps={{ className: "customOutlined" }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <LanguageIcon className="nomrg" />
                        </InputAdornment>
                      ),
                    }}
                  >
                    {/* <MenuItem value="0">English</MenuItem> */}
                    <MenuItem value="ba">{this.props.language.bangla}</MenuItem>
                    <MenuItem value="en">{this.props.language.english}</MenuItem>
                    <MenuItem value="fr">{this.props.language.french}</MenuItem>
                    <MenuItem value="gj">{this.props.language.gujarati}</MenuItem>
                    <MenuItem value="hi">{this.props.language.hindi}</MenuItem>
                    <MenuItem value="it">{this.props.language.italian}</MenuItem>
                    <MenuItem value="ka">{this.props.language.kannada}</MenuItem>
                    <MenuItem value="ml">{this.props.language.malyalam}</MenuItem>
                    <MenuItem value="ma">{this.props.language.marathi}</MenuItem>
                    <MenuItem value="pt">{this.props.language.portuguese}</MenuItem>
                    <MenuItem value="es">{this.props.language.spanish}</MenuItem>
                    <MenuItem value="ta">{this.props.language.tamil}</MenuItem>
                    <MenuItem value="te">{this.props.language.telugu}</MenuItem>
                    <MenuItem value="ur">{this.props.language.urdu}</MenuItem>
                  </TextField>
                </div>
              </div>
            </div>
            <div className="fullflexRow basePrimary">
              {(this.props.isAuthenticated === undefined) ? <Loading /> :
                <Fragment>
                  <HomeLeftBlock />

                  <div className="hmResHalfCol fullWidth">
                    <div className="fullflexCol pstnRelative">
                      <Suspense fallback={<Loading />}>
                        <Route exact path={this.props.path} {...this.props} component={this.props.component} />
                      </Suspense>
                    </div>
                  </div>
                </Fragment>
              }
            </div>
            {/* <HomeFooter langCopy={this.props.language.home_instavc_copyrights} langAgy={this.props.language.home_instavc_terms_agree} /> */}
            <div className="hmFtr flexRow">
              <span className="copyRightTxt">{this.props.language.home_instavc_copyrights}    {this.props.language.home_instavc_terms_agree}
                <Link to={{ pathname: "https://www.instavc.com/video-conferencing-terms-of-service" }}
                  target="_blank" className="ftnAnchorTxt">{this.props.language.home_instavc_terms}
                </Link>
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}