import React from 'react';
import Checkbox from '@material-ui/core/Checkbox'; 

function CheckBox(props) {
    const {checked, onChange, color} = props;
    return (
        <Checkbox
            checked={checked}
            onChange={onChange}
            color={color}
            inputProps={{ 'aria-label': 'primary checkbox' }}
        />
    )
}
export default CheckBox;