import React, {useEffect} from "react";
import { Route, Redirect } from "react-router-dom";
import Home from '../containers/home/Home';
import { API } from "aws-amplify";
import { OffLineAPI } from "../offline-auth/api";
import { config } from '../config';
const _API = config.isOffline ? OffLineAPI : API;


window.Cookies = require('js-cookie');

function querystring(name, url = window.location.href) {
    name = name.replace(/[[]]/g, "\\$&");

    const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)", "i");
    const results = regex.exec(url);

    if (!results) {
        return null;
    }
    if (!results[2]) {
        return "";
    }

    return decodeURIComponent(results[2].replace(/\+/g, " "));
}
function validateSetup(data) {
    // console.log("Decisition", data);
    // if(data){
    return "/new-meetings";
    // }else {
    //     return "/setup";
    // }
}

function validateActions(url, cProps) {

    if (url === 'affiliate') {
        if (cProps.isAffiliate) {
            return 'referral';
        } else {
            return url;
        }
    }

    return url;
}


export default ({ component: C, props: cProps, ...rest }) => {
    console.log("Root Action", cProps);

    const redirect = querystring("redirect");
    const ref = querystring("ref");
    useEffect(() => {
        if (ref) {
            window.Cookies.set('referrer', ref, { expires: 25, path: '' });

            let body = {
                // referral_url : window.location.href,
                userId: ref,
                productCode: "INVC"
            }
            _API.post("inaffiliateUser", "/referrerclicks", { body })
                .then(res => {
                    console.log(res);
                })
                .catch(err => {
                    console.log(err);

                });
            // _API.post("referral", "/addreferralclick", {body})
            //     .then(res => {
            //         console.log(res);
            //     })
            //     .catch(err => {
            //         console.log(err);

            //     });
        }
    }, [ref])

    let mcu_key = config.isOffline ? 'mcuServer' : 'custom:mcuServer';
    return (
        <Route
            {...rest}
            render={props =>
                !cProps.isAuthenticated
                    ? <Home {...props} {...cProps} component={(props) => <C  {...cProps} {...props} />} />
                    : <Redirect
                        to={redirect === "" || redirect === null ? validateSetup(cProps.userPayload[mcu_key]) : validateActions(redirect, cProps)}
                    />}
        />
    );
};

  //<C {...props} {...cProps} />

  //component={C} 