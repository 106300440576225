import React, { Component, Suspense } from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import DehazeIcon from '@material-ui/icons/Dehaze';
import './header.css';
import { Auth, API } from "aws-amplify";
import LogoBlock from './components/LogoBlock';
import * as AuthAPI from "../../../offline-auth/auth";
import { config } from '../../../config';
import { OffLineAPI } from '../../../offline-auth/api';
import { LanguageContext, LicenseContext } from "../../../libs/context";

import ResponsiveSideNav from "../responsiveSideNav/index";


const _Auth = config.isOffline ? AuthAPI.Auth : Auth;
let _API = config.isOffline ? OffLineAPI : API;



class Header extends Component {
	constructor(props) {
		super(props);
		this.state = {
			dropDownMenu: false,
			hideToggle: false,
			data: null,
			mobNav: false,
			mobShade: false,

		}
		this.setWrapperRef = this.setWrapperRef.bind(this);

	}

	setWrapperRef(node) {
		this.wrapperRef = node;
	}

	componentDidMount() {
		document.addEventListener("mousedown", this.handleClickOutside);
		this.getUserByUserId();
	}
	componentWillUnmount() {
		document.removeEventListener("mousedown", this.handleClickOutside);
	}

	handleClickOutside = (event) => {
		event.stopPropagation()
		if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
			this.setState({ dropDownMenu: false });
			if (this.props.adminSideNav) {
				this.props.adminSideNav(false);
			}
			if (this.props.affiliateSideNav) {
				this.props.affiliateSideNav(false);
			}
			if (this.props.contentSideNav) {
				this.props.contentSideNav(false);
			}
			if (this.props.pluginSideNav) {
				this.props.pluginSideNav(false);
			}
		}
	}


	toggleDropDown = () => {
		this.setState({ dropDownMenu: !this.state.dropDownMenu });

	};


	handleLogout = async event => {

		await _Auth.signOut();
		this.props.userHasAuthenticated(false, null);
	}

	handleOpenSideNav = () => {
		console.log('hittted ldfksdflsdf');
		this.props.adminSideNav(true);
	}
	handleOpenAffiliateSideNav = () => {
		console.log('hittted ldfksdflsdf');
		this.props.affiliateSideNav(true);
	}

	handleOpenContentSideNav = () => {
		console.log('hittted ldfksdflsdf');
		this.props.contentSideNav(true);
	}

	handleOpenPluginSideNav = () => {
		console.log('hittted ldfksdflsdf');
		this.props.pluginSideNav(true);
	}

	handleSlideIn = () => {
		this.setState({ mobNav: true })
		this.setState({ mobShade: true })
	}

	handleSlideOut = () => {
		this.setState({ mobNav: false })
		this.setState({ mobShade: false })
	}



	getUserByUserId() {
		_API.get("user", '/listuser').then(res => {
			// console.log("userres======>", res);
			this.setState({ data: res.data })
			// console.log("this.state.data", this.state.data);
		}).catch(err => {
			console.log("err", err);
		})
	}



	render() {
		// console.log("this.props ==== ", this.props);
		let language = this.context;
		let imgsrc = '/images/blue/default-user-new.png';
		let { userPayload } = this.props;
		if (config.isOffline) {
			let { profile, userPayload } = this.props;
			// console.log("profile === ", profile);
			if (userPayload['custom:profilePic']) {
				imgsrc = userPayload['custom:profilePic'];
			}
		} else {
			if (userPayload['custom:profilePic']) {
				imgsrc = userPayload['custom:profilePic'];
			}
		}


		// let {data} = this.state;
		// if(data){

		// 	if (data.profileImage !== '') {
		// 		imgsrc = data.profileImage.url;
		// 	} 
		// }

		// if (props.location.pathname.match(/admin/)){
		// 	<div className="displayOrNot mrgRight" onClick={this.handleOpenSideNav} ref={e=>this.setWrapperRef(e)}>
		// 		<DehazeIcon/>
		// 	</div>
		// }
		let patt = new RegExp("/admin");
		let showToggleIcon = patt.test(window.location.pathname);

		let affiliatepage = new RegExp("/referral");
		let showToggleAffiliateIcon = affiliatepage.test(window.location.pathname);

		let contentpage = new RegExp("/content");
		let showToggleContentIcon = contentpage.test(window.location.pathname);

		let pluginpage = new RegExp("/plugin");
		let showTogglePluginIcon = pluginpage.test(window.location.pathname);

		const pathName = window.location.pathname;
    	const path = pathName.split('/')[1];

		return (
			<div className={path !== "meeting" ? "header" : "header headerBgChange"}>
				{/****** Hamburger Menu for Responsive *****/}
				{showToggleIcon &&
					<div className="displayOrNot mrgRight" onClick={this.handleOpenSideNav} ref={e => this.setWrapperRef(e)}>
						<DehazeIcon />
					</div>
				}
				{showToggleAffiliateIcon &&
					<div className="displayOrNot mrgRight" onClick={this.handleOpenAffiliateSideNav} ref={e => this.setWrapperRef(e)}>
						<DehazeIcon />
					</div>
				}
				{showToggleContentIcon &&
					<div className="displayOrNot mrgRight" onClick={this.handleOpenContentSideNav} ref={e => this.setWrapperRef(e)}>
						<DehazeIcon />
					</div>
				}
				{showTogglePluginIcon &&
					<div className="displayOrNot" onClick={this.handleOpenPluginSideNav} ref={e => this.setWrapperRef(e)}>
						<div className="newHumburger">
							<span className="burgerLine"></span>
							<span className="burgerLine"></span>
							<span className="burgerLine"></span>
						</div>
					</div>
				}
				{/************ */}
				<ResponsiveSideNav
					mobNav={this.state.mobNav}
					mobShade={this.state.mobShade}
					handleSlideOut={this.handleSlideOut}

				/>
				{/* {!showToggleIcon && !showToggleAffiliateIcon && !showToggleContentIcon && !showTogglePluginIcon &&
					<div className="newHumburger" onClick={this.handleSlideIn}>
						<span className="burgerLine"></span>
						<span className="burgerLine"></span>
						<span className="burgerLine"></span>
					</div>} */}
				<LogoBlock logo={path!=="meeting" ? this.props.webLogo : "invc_logo_white.png"} />

				<div className="newWebNav">
					<NavLink to="/new-meetings"  activeClassName="anchorActive" className={path!=="meeting" ? "anchorTxt" : "anchorTxtClr"} >{language.invc_mymeetings}</NavLink>
					{/* <NavLink to="/meeting" activeClassName="anchorActive" className="anchorTxt" >{language.invc_dashboard}</NavLink> */}
					<NavLink to="/calendar" activeClassName="anchorActive" className={path!=="meeting" ? "anchorTxt" : "anchorTxtClr"} >{language.invc_calendar}</NavLink>
					{/* <NavLink to="/new-contacts" activeClassName="anchorActive" className="anchorTxt" >My Contacts</NavLink>
						<NavLink to="/new-settings" activeClassName="anchorActive" className="anchorTxt" >Settings</NavLink>  */}
				</div>

				<div className="headerAvatarBlock" onClick={this.toggleDropDown} ref={e => this.setWrapperRef(e)}>
					<div className="userName">
						<span className={path!=="meeting" ? "" : "invertWhite"}>{userPayload.name}</span>
					</div>
					<div className="userAvatar">
						<img src={imgsrc} alt="Profile" />
					</div>
					<Suspense fallback={<div>{language.header_loading}</div>}>
						<div className={this.state.dropDownMenu ? 'headerAvaDrpDwn show' : 'headerAvaDrpDwn'} >
							<LicenseContext.Consumer>
								{licensectx => (
									<ul>
										{/* 									

										<li className="displayOrNot"><Link to="/meeting">{language.instavc_myprofile_dashboard}</Link></li>
										<li><Link to="/myprofile"> {language.instavc_dashboard_myprofile}</Link></li>
										{licensectx && licensectx.license && licensectx.license.length > 0 && <li><Link to="/manage-licenses">{language.instavc_admin_licenses}</Link></li>}
										{licensectx && licensectx.license && licensectx.license.length > 0 && licensectx.license[0].isMultidisplay && <li>
											<Link to="/device-management">Device Management</Link>
										</li>}
										<li><Link to="/settings">{language.instavc_dashboard_settings}</Link></li>
										
										{config.isClass && <li><Link to="/content">{language.header_content}</Link></li>}
										{false && <li><Link to="/apidashboard">API's</Link></li>}
										{(this.props.isAdmin) && <li><Link to="/admin">{language.instavc_dashboard_myadmin}</Link></li>} */}



										<li><Link onClick={this.handleLogout} to="/">{language.instavc_dashboard_logout}</Link></li>

									</ul>
								)}
							</LicenseContext.Consumer>
						</div>
					</Suspense>
				</div>

			</div>
		)
	}
}

const mapStateToProps = state => ({
	profile: state.profile,
});
Header.contextType = LanguageContext;

export default connect(mapStateToProps)(Header);
