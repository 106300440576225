import React from 'react';
import { Link } from 'react-router-dom';
import '../header.css';

const LogoBlock = (props) => {
	return(
		<div className="leftLogoBlock">
			<div className="logoBlock">
				{ !props.nolink ? 
					<Link to="/new-meetings"><img src={`/images/logos/${props.logo}`} alt="logo" /></Link>
					:
					<img src={`/images/logos/${props.logo}`} alt="logo" />
				}
			</div>
		</div>
	)
}

export default LogoBlock;