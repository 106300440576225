import React from 'react';
import { Button as MuiButton, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    root: {
        margin: theme.spacing(0.5)
    },
    label: {
        textTransform: "none",
    }
}))

function Button(props) {
    const classes = useStyles()
    const { text, size, color, variant, onClick, ...other } = props;
    return (
        <MuiButton disableElevation
            variant={variant || "contained"}
            size={size || "large"}
            color={color || "primary"}
            onClick={onClick}
            {...other}
            classes={{ root: classes.root, label: classes.label }} >
            {text}

        </MuiButton>
    )
}
export default Button;


// variant - contained(default), outlined,
// size - large, small, medium 
// color - primary, secondary 