import React, { Component, Suspense } from "react";
import { Route } from "react-router-dom";
import Loading from '../../components/common/Loading'
import "../home/Home.css";

export default class SimulcastRoute extends Component {
  componentDidMount() {
    console.log('Mounted once');
  }
  
  render() {
    return (
        <>
            <Suspense fallback={<Loading />}>
                <Route key='simulchild' exact path={this.props.path} {...this.props} component={this.props.component} />
            </Suspense>
        </>
    );
  }
}