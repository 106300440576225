import ActionButton from './actionBtn';
import Button from './button';
import TextButton from "./textButton";
import CloseButton from "./closeBtn";
import Checkbox from "./checkbox";
import PopUp from "./popup";
import PopUpNoFooter from "./popupNoFooter";

const Controls = {
    ActionButton,
    Button,
    TextButton,
    CloseButton,
    Checkbox,
    PopUp,
    PopUpNoFooter
}

export default Controls;