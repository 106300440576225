import React, { useEffect, useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import SimulcastRoute from '../containers/confsimulcast/SimulcastRoute';
import { getMyLicense } from "../containers/actions/license";
import {LicenseContext} from "../libs/context";

// function querystring(name, url = window.location.href) {
//     name = name.replace(/[[]]/g, "\\$&");

//     const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)", "i");
//     const results = regex.exec(url);

//     if (!results) {
//         return null;
//     }
//     if (!results[2]) {
//         return "";
//     }

//     return decodeURIComponent(results[2].replace(/\+/g, " "));
// }


export default  ({ component: C, props: cProps, ...rest }) => {

    const {license, setLicenses} = useContext(LicenseContext);

    useEffect(() => {
      if(cProps.isAuthenticated) {
        let email = cProps.userPayload && cProps.userPayload.email ? cProps.userPayload.email : "";
        getMyLicense(email).then(res => {
            setLicenses([...res]);
        });
      }else {
        setLicenses([]);
      }
    }, [cProps.isAuthenticated]);

    // const redirect = querystring("redirect");
    return (
      <Route
        {...rest}
        render={props =>
        (cProps.isAuthenticated !== undefined) && license !== null && 
        <SimulcastRoute {...props} {...cProps} component={(props) => <C key='conference' {...cProps} {...props} BreakoutModeOn={false} isGuest={true} isHost={false} /> } />
        //: (cProps.isAuthenticated === false ) && <Guest {...props} {...cProps} component={(props) => <C key='conference' {...cProps} {...props} isGuest={true} isHost={false} /> } />
            // : <Redirect
            //     to={redirect === "" || redirect === null ? '/dashboard' : redirect}
            //   />
            }
      />
    );
  };

  //<C {...props} {...cProps} />

  //component={C} 