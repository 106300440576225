import React from "react";
import { Route, Redirect } from "react-router-dom";
import AffiliateLayout from '../containers/layout/affiliateLayout';

export default ({ component: C, props: cProps, ...rest }) =>
  <Route
    {...rest}
    render={props =>
      (cProps.isAuthenticated && cProps.isAffiliate && rest.validate)
    ?  <AffiliateLayout {...props} {...cProps} component={(props) => <C {...cProps} {...props} />} />
        : <Redirect
            to={`/?redirect=affiliate`}
          />}
  />;

  //</MainLayout><C {...props} {...cProps} />