import React, { Component, useContext } from 'react';
import { NavLink } from 'react-router-dom';
import './SideNav.css';
import { LanguageContext } from '../../../libs/context';
// import './SideNavRtl.css';

const ApiSidenav = (props) => {
	const language = useContext(LanguageContext)
    
    return (
			<React.Fragment>
				<ul>
					{/* <li>
						<NavLink to="/apidashboard" activeClassName="activeTabNav"><span className="imgLink"><img src="/images/darkblue24icons/db-setting-24.png" alt="dashboard" /></span><span className="txtLink">{language.API_settings}</span></NavLink>
					</li> */}
					<li>
						<NavLink to="/apidashboard/scheduleapi" activeClassName="activeTabNav" ><span className="imgLink"><img src="/images/blue/calendar.png" alt="myprofile" /></span><span className="txtLink">{language.schedule_API}</span></NavLink>
					</li>
                </ul>
			</React.Fragment>
		)
	}

export default ApiSidenav;