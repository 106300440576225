import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import './SideNav.css';
// import './SideNavRtl.css';
class ReportsNav extends Component {
	constructor(props) {
		super(props);
		this.state = {
		}
	}
	componentDidMount() {
		console.log('Admin Pro', this.props);
	}

	render() {
		return (
			<React.Fragment>
				<ul>
					<li>
						<NavLink to="/referral" activeClassName="activeTabNav"><span className="imgLink"><img src="/images/darkblue24icons/referral-account-24.png" alt="dashboard" /></span><span className="txtLink">{this.props.language.instavc_my_referral_account}</span></NavLink>
					</li>
					<li>
						<NavLink to="/reports/myreferral" activeClassName="activeTabNav" ><span className="imgLink"><img src="/images/darkblue24icons/referral-24.png" alt="myprofile" /></span><span className="txtLink">{this.props.language.instavc_myreferral}</span></NavLink>
					</li>
                    <li>
						<NavLink to="/reports/mysales" activeClassName="activeTabNav" ><span className="imgLink"><img src="/images/darkblue24icons/sales-24.png" alt="settings" /></span><span className="txtLink">{this.props.language.instavc_mysales}</span></NavLink>
					</li>
                    <li>
						<NavLink to="/reports/myearnings" activeClassName="activeTabNav" ><span className="imgLink"><img src="/images/darkblue24icons/earnings-24.png" alt="settings" /></span><span className="txtLink">{this.props.language.instavc_myearnings}</span></NavLink>
					</li>
                    <li>
						<NavLink to="/reports/requestwithdraw" activeClassName="activeTabNav" ><span className="imgLink"><img src="/images/darkblue24icons/withdraw-24.png" alt="settings" /></span><span className="txtLink">{this.props.language.instavc_req_withdraw}</span></NavLink>
					</li>
					{/* <li>
						<NavLink to="#" activeClassName="activeTabNav" ><span className="imgLink"><img src="/images/darkblue24icons/db-setting-24.png" alt="settings" /></span><span className="txtLink">Settings</span></NavLink>
					</li>
					<li>
						<NavLink to="/admin/coupons" activeClassName="activeTabNav" ><span className="imgLink"><img src="/images/darkblue24icons/coupon-24.png" alt="settings" /></span><span className="txtLink">Coupons/Offers</span></NavLink>
					</li> */}
				</ul>
			</React.Fragment>
		)
	}
}

export default ReportsNav;