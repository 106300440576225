
import { API } from "aws-amplify";

export const createOrder = (body) => {
    return new Promise((resolve, reject) => {
        API.post("inVCLicense", `/createorder/`, { body })
            .then(res => {
                resolve(res);
            })
            .catch(err => {
                reject(err)
            });
    });
}

export const getMyLicense = (email = "") => {
    return API.get("inVCLicense", `/getmylicense?email=${email}`);
}

export const addHosts = (id, body) => {
    return API.put("inVCLicense", `/addhosts/${id}`, { body });
}

export const applyCoupon = (code, productCode) => {
    return API.get("inVCLicense", `/applycoupon/${code}?product=${productCode}`);
}

export const createOrderwithNoPay = (data) => {
    let body = {
        event: "payment.authorized",
        payload: {
            payment: {
                entity: {
                    notes: {
                        userId: data.userId,
                        productCode: data.productCode,
                        qty: data.qty,
                        licenseid: data.id ? data.id : undefined,
                        validity: data.validity ? data.validity : undefined
                    }
                }
            }
        }
    }
    return API.post("inVCLicense", `/webhook`, { body });
}

export const capturePayment = (body) => {
    return API.post("inVCLicense", `/capturepayment`, { body });
}
