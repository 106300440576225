import { combineReducers } from 'redux';
import room from './room';
import me from './me';
import producers from './producers';
import peers from './peers';
import lobbyPeers from './lobbyPeers';
import breakoutRooms from './breakoutRooms';
import consumers from './consumers';
import peerVolumes from './peerVolumes';
import notifications from './notifications';
import chat from './chat';
import toolarea from './toolarea';
import sharearea from './sharearea';
import files from './files';
import settings from './settings';
import transports from './transports';
import intl from './intl';
// import conference from '../../../../../containers/conference/reducer';
// import dashboard from '../../../../../containers/dashboard/reducer';
import profile from '../../../../../containers/profile/reducer';
// import content from '../../../../../containers/contentShare/reducer';
// import { intlReducer } from 'react-intl-redux';

export default combineReducers({
	// conference,
	// dashboard,
	profile,
	room,
	// content,
	me,
	producers,
	consumers,
	transports,
	peers,
	lobbyPeers,
	breakoutRooms,
	peerVolumes,
	notifications,
	chat,
	toolarea,
	sharearea,
	files,
	settings,
	// intl : intlReducer
	intl
});
