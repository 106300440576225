import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import './SideNav.css';
import Controls from "../../../containers/ui-styles/controls/controls";
// import './SideNavRtl.css';
class PluginNav extends Component {
	constructor(props) {
		super(props);
		this.state = {
		}
	}
	componentDidMount() {
		console.log('teams Pro', this.props);
	}



	render() {
		return (
			<React.Fragment>
				<div className="resSidenavHdr">
					<div className="flexRow">
						<div className="flexMinWidth">
							<div className="sideNavBranding">
								<img src="/images/logos/invc-home-logo.png" alt="branding Image" />
							</div>
						</div>
						<div className="flexAuto">
							<div className="resNavCloseGroup">
							<Controls.CloseButton onClick={this.props.closeSlide} />
							</div>
						</div>
					</div>
				</div>
				<div className="resSidenavBdy">
					<ul className="resULItem">
						<li onClick={this.props.closeSlide}>
							<NavLink to="/plugin/microsoft" activeClassName="activeTabNav">
								<span className="imgLink"><img src="/images/blue/teamsicon.png" alt="dashboard" /></span>
								<span className="txtLink">{this.props.language.microsoft_teams}</span>
							</NavLink>
						</li>
						{/* <li onClick={this.props.closeSlide}>
							<NavLink to="/plugin/outlook-installable" activeClassName="activeTabNav">
								<span className="imgLink"><img src="/images/blue/installable.png" alt="dashboard" /></span>
								<span className="txtLink">{this.props.language.microsoft_installable}</span>
							</NavLink>
						</li> */}
						{/* <li onClick={this.props.closeSlide}>
							<NavLink to="/plugin/outlook" activeClassName="activeTabNav">
								<span className="imgLink"><img src="/images/blue/outlookicon.png" alt="dashboard" /></span>
								<span className="txtLink">{this.props.language.microsoft_webplugin}</span>
							</NavLink>
						</li> */}
						<li onClick={this.props.closeSlide}>
							<NavLink to="/plugin/recording" activeClassName="activeTabNav">
								<span className="imgLink"><img src="/images/blue/instavc-recording.png" alt="dashboard" /></span>
								<span className="txtLink">inVC Screen-Video Recorder</span>
							</NavLink>
						</li>
					</ul>
				</div>
			</React.Fragment>
		)
	}
}

export default PluginNav;