import React from "react";
import { makeStyles } from "@material-ui/core";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(theme => ({
    closeButton: {
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        color: theme.palette.grey[500],

    },
}))

function CloseButton(props) {
    const {onClick, size} = props;
    const classes = useStyles();
    return (
        <IconButton 
            aria-label="close" 
            className={classes.closeButton} 
            onClick={onClick}
            size={size || "small"}
        
        >
            <CloseIcon />
        </IconButton>
    )
}
export default CloseButton;