import React from "react";
import { Route, Redirect } from "react-router-dom";
import MainLayout from '../containers/layout/mainLayout';

export default ({ component: C, props: cProps, ...rest }) =>
  <Route
    {...rest}
    render={props =>
      cProps.isAuthenticated
    ?  <MainLayout {...props} {...cProps} component={(props) => <C isGuest={false} isHost={true} {...cProps} {...props} />} />
        : <Redirect
            to={`/?redirect=${props.location.pathname}${props.location
              .search}`}
          />}
  />;

  //</MainLayout><C {...props} {...cProps} />