import { OffLineAPI } from './api';
const jwt = require("jsonwebtoken");


const STORAGE_KEY = "insoff-amplify-auth.state";
const USER_ID_PREFIX = "insoff-auth-";

let authState;

try {
  authState = JSON.parse(window.localStorage.getItem(STORAGE_KEY)) || {};
} catch (err) {
  authState = {};
}

function signUp(body) {
  return OffLineAPI.post("auth", "/signup", { body })
    .then(res => {
      return res;
    })
    .catch(err => {
      console.log("err in offline signUp ", err);
      return err;
    });

  // updateState({ email, loggedIn: false });
  // return timerPromise(1200);
}

function signIn(username, password) {
  // updateState({ email, session: createSession({ email }), loggedIn: true });
  // return timerPromise(700);
  // console.log("enter signin");
  let body = {
    email: username,
    password: password
  }
  return OffLineAPI.post("auth", "/login", { body })
    .then(res => {
      localStorage.setItem("token", res.token);
      localStorage.setItem("sub", res.sub);
      console.log("res === ", res);
      return res;
    })
    .catch(err => {
      alert("Invalid Credentials")
      return err;
    })
}

function updateUserAttributes(user, body) {
  return OffLineAPI.post("auth", "/updateuser", { body })
    .then(res => {
      // console.log(res);
      return res;
    })
    .catch(err => {
      return err;
    })
}

function currentAuthenticatedUser() {
  return OffLineAPI.get("auth", "/currentuser")
    .then(res => {
      // console.log(res);
      return res;
    })
    .catch(err => {
      return err;
    })

}

function currentUserInfo() {
  return OffLineAPI.get("auth", "/currentuserinfo")
    .then(res => {
      // console.log(res);
      res['id'] = res.sub;
      return res;
    })
    .catch(err => {
      return err;
    })
}

function userAttributes() {
  return OffLineAPI.get("auth", "/userattributes")
    .then(res => {
      // console.log(res);
      return res;
    })
    .catch(err => {
      return err;
    })
}

function currentSession() {
  // return timerPromise(400, !!authState.loggedIn, authState.session);
  return OffLineAPI.get("auth", "/currentsession")
  .then(res => {
    console.log(res);
    return res;
  })
  .catch(err => {
    return err;
  })
}

function confirmSignUp() {
  return timerPromise(400);
}

function signOut() {
  updateState({ email: null, loggedIn: false, session: null });
  localStorage.removeItem('token')
  localStorage.removeItem('sub')

  return timerPromise(100);

}

function resendSignUp() {
  return timerPromise(100);
}

function updateState(newState) {
  Object.assign(authState, newState);
  window.localStorage.setItem(STORAGE_KEY, JSON.stringify(authState));
}

function timerPromise(ms, success = true, arg) {
  if (!success && !arg) {
    arg = new Error();
  }
  return new Promise((resolve, reject) =>
    setTimeout(() => (success ? resolve : reject)(arg), ms)
  );
}

function extractEmail(userId) {
  return Buffer.from(
    userId.substring(USER_ID_PREFIX.length),
    "base64"
  ).toString();
}

function createSession({ email }) {
  const timeS = Math.floor(Date.now() / 1000);
  const cognitoUsername = `${USER_ID_PREFIX}${Buffer.from(email).toString(
    "base64"
  )}`;
  const idTokenPayload = {
    sub: cognitoUsername,
    token_use: "id",
    email_verified: true,
    aud: "mock-auth",
    auth_time: timeS,
    iat: timeS,
    exp: timeS + 100000,
    iss: "https://cognito-idp.local.amazonaws.com/local_mock-auth",
    "cognito:username": cognitoUsername,
    email
  };
  const idToken = {
    jwtToken: jwt.sign(idTokenPayload, "mock-auth-secret"),
    payload: idTokenPayload
  };

  return {
    idToken,
    clockDrift: 0
  };
}

export const Auth = {
  signUp,
  signIn,
  currentAuthenticatedUser,
  updateUserAttributes,
  currentSession,
  confirmSignUp,
  signOut,
  resendSignUp,
  extractEmail,
  currentUserInfo,
  userAttributes,

  // forgotPasswordSubmit,
  // forgotPassword,
  // changePassword
};
// module.exports = Auth;