import React, { Component, Suspense } from "react";
import { Route } from "react-router-dom";
import Loading from '../../components/common/Loading'
import "../home/Home.css";

export default class Guest extends Component {
  componentDidMount() {
    
  }
  UNSAFE_componentWillMount = () => {
    
  }
  render() {
    return (
      <div className="homeWrapper">
        <div className="homeContainer">
            {/* <div className="homeColG brdrWLeft homeCol2responsive"> */}
            <div className="homeColG homeCol2responsive">
                <Suspense fallback={<Loading />}>
                    <Route key='confchild' exact path={this.props.path} {...this.props} component={this.props.component} />
                </Suspense>
            </div>
        </div>
      </div >
    );
  }
}