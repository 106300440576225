// Import Actions
import { SET_PROFILE, SET_PROFILE_IMG } from './actions';

// Initial State
const initialState = {
  userData: {},
  profileImage: ''
};

const ProfileReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PROFILE:
        return Object.assign({}, state, { userData: action.data });
    case SET_PROFILE_IMG: 
        return Object.assign({}, state, {profileImage: action.data});
    default:
      return state;
  }
};

/* Selectors */

// Get showAddPost
// export const getShowAddPost = state => state.app.showAddPost;

// Export Reducer
export default ProfileReducer;
