import React from 'react';
import { Button, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {
        minWidth: 0,
        margin: theme.spacing(0.5)
    },
    secondary: {
        backgroundColor: theme.palette.secondary.light,
        '& .MuiButton-lable': {
            color: theme.palette.secondary.main,
        }

    },
    primary: {
        backgroundColor: theme.palette.primary.light,
        '& .MuiButton-lable': {
            color: theme.palette.primary.main,
        }
    }
}))

function ActionButton(props){

    const { color, childern, onClick} = props;
    const classes = useStyles();
    return(
        <Button onClick={onClick} className={`${classes.root} ${classes[color]}`}>
            {childern}
        </Button>
    )
}

export default ActionButton;