import React, { Fragment } from 'react';
import TextField from '@material-ui/core/TextField';

import "./GuestSignup.css";
import "../ui-styles/ripples/primaryRipple.css";

const GuestSignup = (props) => {
    return(
        <Fragment>
            <div className="gMainWrapper">
                <div className="gmainHeader">
                    <div className="logoBox">
                        <img src="/images/logos/instavc-blue-small.png" />
                    </div>
                </div>
            
                <div className="gmainContainer">
                <div className="landingPage">
                    <div className="landingRow">
                        <div className="landingCol">
                            <div className="landingBanner">
                                <img src="/images/banners/guest-signup.png" />
                            </div>
                        </div>
                        <div className="landingCol extraPdng">
                            <h1 className="headingText">FREE Sign up, it’s super quick!</h1>
                            <h4 className="quoTxt">No commitment or credit card requires.</h4>
                            <div className="formBlock">
                                <TextField label="Email Address" placeholder="Enter your email here..." variant="outlined" />
                                <button className="frmBtn ripple">SIGN UP</button>
                            </div>
                            <div className="infoTxtBlock">
                                <p className="paraText">Join simply by clicking the email invite you receive to attend the meeting or event.</p>
                                <p className="paraText">Your free InstaVC Meeting plan gives you meetings with 1 Host &amp; 10 participants, Whiteboard, Recording, Chat, Presenter Mode, HD video, screen sharing, and a personal room for 30 days.</p>
                                <p className="paraText">By clicking the Sign UP, you agree and accept to the PeopleLink &amp; <a href="https://www.instavc.com/video-conferencing-terms-of-service" className="paraLink">InstaVC terms of services</a></p>
                            </div>
                        </div>
                    </div>
                    <div className="landingRow">
                        <small className="smallText">PeopleLink InstaVC is committed to helping you during these difficult Covid times and offering extended free services to you. InstaVC is the easiest, and best solution to help you stay connected remotely while working from anywhere. We are seeing unprecedented demand for our FREE SIGN UP's, and we will strive to maintain the highest quality of audio and video for all our users over the next few weeks. There may be cases where our free users may see degraded services, in those cases, pls can try conducting your video meeting again after some time again.</small>
                    </div>
                </div>
            </div>
            
            
                <div className="gmainFooter">
                    <small className="ftrSmallTxt">Copyright ©2020 InstaVC. All rights reserved.</small>
                    <div className="footerLinksBox">
                        <ul>
                            <li>
                                <a href="https://www.instavc.com/" target="_blank">InstaVC</a>
                            </li>
                            <li>
                                <a href="https://www.instavc.com/video-conferencing-terms-of-service" target="_blank">Terms of Services</a>
                            </li>
                            <li>
                                <a href="https://www.instavc.com/video-conferencing-acceptable-use-policy" target="_blank">Acceptable use Policy</a>
                            </li>
                            <li>
                                <a href="https://www.instavc.com/video-conferencing-privacy-policy" target="_blank">Privacy Policy</a>
                            </li>
                            <li>
                                <a href="https://www.instavc.com/contact-us" target="_blank">Contact Sales</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default GuestSignup;